// necessary imports
import React, { useState } from "react";
import "../../Styles/BookATrial/bookATrial.scss";
import WhiteLogo from "../../Assets/bookatrail/white-logo.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CardIcon1 from "../../Assets/bookatrail/card1.png";
import CardIcon2 from "../../Assets/bookatrail/card2.png";
import CardIcon3 from "../../Assets/bookatrail/card3.png";
import CardIcon4 from "../../Assets/bookatrail/card4.png";
import CardIcon5 from "../../Assets/bookatrail/card5.png";
import CardIcon6 from "../../Assets/bookatrail/card6.png";
import { Player, ControlBar } from "video-react";
import animationBooking from "../../Assets/bookatrail/book_trial_animation.mp4";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import LoginWithOTP from "./LoginWithOTP";
import Modal from "react-modal";
import { verifyScheduleTrialOTP } from "../../DataAccessObject/DataAccessObject";
import { getRefferalCode, getRefferalName } from "../Home/Helper";

// functional component
export default function BookATrial(props) {
  const {
    parentName,
    setParentName,
    parentEmail,
    setParentEmail,
    phoneNumber,
    setPhoneNumber,
    studentName,
    setStudentName,
    referralCode,
    setReferralCode,
    grade,
    setGrade,
    havePC,
    setHavePC,
    parentNameError,
    setParentNameError,
    parentEmailError,
    setParentEmailError,
    parentContactError,
    setParentContactError,
    studentNameError,
    setStudentNameError,
    parentNameSuccess,
    setParentNameSuccess,
    parentEmailSuccess,
    setParentEmailSuccess,
    parentContactSucess,
    setParentContactSuccess,
    studentNameSuccess,
    setStudentNameSucess,
    onBookFreeClass,
    setIsLoading,
    token,
    setItemIndex,
    modalOpen,
    modalClose,
  } = props;

  // item to be rendered

  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const [digit5, setDigit5] = useState("");
  const [digit6, setDigit6] = useState("");
  const [OTPerror, setOTPerror] = useState(false);
  // ******************************************
  // handler for login-with-otp form
  // ******************************************
  const onValidateOTP = function (event) {
    let digits = [digit1, digit2, digit3, digit4, digit5, digit6];
    let otp = "";
    for (let i = 0; i < digits.length; ++i) {
      let digit = digits[i];
      if (!("0" <= digit && digit <= "9")) return;
      otp += digit;
    }

    // make API request to verify token
    setIsLoading(true);
    verifyScheduleTrialOTP(parentEmail, parseInt(otp), token)
      .then((response) => {
        if (response.status === 200) {
          let localData = { email: parentEmail, token: response.data.token };
          localStorage.setItem("tk_jwt", JSON.stringify(localData));

          setItemIndex(1);
        } else {
          setOTPerror(response.data.message);
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        setOTPerror(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // const onCloseValidation = function (event) {
  //   setItemIndex(0);
  // };

  const renderApply = () => {
    if (getRefferalCode() && getRefferalCode().toString().length >= 14) {
      return "Applied";
    } else {
      return "Apply";
    }
  };
  let history = useHistory();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="form-page-container">
      <div className="form-items-div">
        <div className="brand">
          <img
            className="brand-icon"
            src={WhiteLogo}
            alt="brandIcon"
            width="1080"
            height="60"
            onClick={() => history.push("/")}
          />
        </div>
        <div className="form-container">
          <div className="form-header">
            <h1>Book your FREE Trial Class</h1>
          </div>
          <div className="form-panel">
            <div className="form-left-panel">
              <div className="bookatrail__animation">
                <Player
                  autoPlay={true}
                  muted={true}
                  style={{ backgorundColor: "#fff" }}
                >
                  <ControlBar disableCompletely={true} />
                  <source src={animationBooking} />
                </Player>
              </div>
            </div>
            <div className="form-right-panel">
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Name*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter Parent's Name"
                      value={parentName}
                      onFocus={
                        (/*event*/) => {
                          setParentNameError(false);
                          setParentNameSuccess(false);
                        }
                      }
                      onChange={(event) => setParentName(event.target.value)}
                      name="name"
                      autoComplete="on"
                    />
                    {parentNameError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentNameSuccess && (
                      <span className="success ">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Email Id*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="email"
                      placeholder="Enter Parent's Email Id"
                      value={parentEmail}
                      onFocus={
                        (/*event*/) => {
                          setParentEmailError(false);
                          setParentEmailSuccess(false);
                        }
                      }
                      autoComplete="on"
                      name="email"
                      onChange={(event) => setParentEmail(event.target.value)}
                    />
                    {parentEmailError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentEmailSuccess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Contact*</label>
                  <div className="d-flex align-items-center">
                    <PhoneInput
                      placeholder="Enter Parent's Contact"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      onFocus={
                        (/*event*/) => {
                          setParentContactError(false);
                          setParentContactSuccess(false);
                        }
                      }
                      defaultCountry="IN"
                      name="phone"
                      autoComplete="on"
                    />
                    {parentContactError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentContactSucess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Student Name*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter Student's Name"
                      value={studentName}
                      onFocus={
                        (/*event*/) => {
                          setStudentNameError(false);
                          setStudentNameSucess(false);
                        }
                      }
                      name="sname"
                      onChange={(event) => setStudentName(event.target.value)}
                    />
                    {studentNameError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {studentNameSuccess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">
                    Select grade/class*
                  </label>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 1; i < 7; ++i) {
                        let item = (
                          <div
                            className={grade === i ? "selected-class" : ""}
                            onClick={(event) => setGrade(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 7; i < 13; ++i) {
                        let item = (
                          <div
                            className={grade === i ? "selected-class" : ""}
                            onClick={(event) => setGrade(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">
                    Do you have a referral code
                  </label>
                  <div className="form-referral-input-container">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter referral code"
                      value={referralCode}
                      onChange={(event) => setReferralCode(event.target.value)}
                    />
                    <button className="referral-code-button">
                      {renderApply()}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">
                    Do you have laptop/PC at home for class?
                  </label>
                  <div className="d-flex form-radio-button-container no-border">
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        checked={havePC}
                        onChange={(event) => setHavePC(true)}
                      />
                      <div className="ml-2">Yes</div>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        checked={!havePC}
                        onChange={(event) => setHavePC(false)}
                      />
                      <div className="ml-2">No</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-footer-message">
                Hurry, last few slots left!
              </div>
              <div className="book-free-class-container">
                <button className="book-free-class" onClick={onBookFreeClass}>
                  Book a free class
                </button>
              </div>
              <div className="form-footer-message">
                By scheduling a free class, you agree to our{" "}
                <Link to="/terms-conditions">Terms of Use</Link> and our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards container */}
      <div className="cards-container">
        <div className="card-header">Improve Concentration by 300%</div>
        <div className="cards-panel">
          <div className="cards-left-panel">
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon1} alt="cardIcon1" />
              </div>
              <div>
                <h1>100% Kid-Safety. Learn from Home</h1>
                Live 1:1 & 1:4 classes will be taught with personal attention
                creating your own schedule
              </div>
            </div>
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon2} alt="cardIcon2" />
              </div>
              <div>
                <h1>World class Teachers</h1>
                Highly selective with strong technical & industry background,
                experience to teach kids
              </div>
            </div>
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon3} alt="cardIcon3" />
              </div>
              <div>
                <h1>Hackathons & Competitions</h1>
                Access to world class events having partnership with top
                universities, schools & companies
              </div>
            </div>
          </div>
          <div className="cards-right-panel">
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon4} alt="cardIcon4" />
              </div>
              <div>
                <h1>Project Completion Certificate</h1>
                Build your own Games, Chatbot, Website, Apps & working projects
                during the trial like a productive learning not a rat-race
              </div>
            </div>
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon5} alt="cardIcon5" />
              </div>
              <div>
                <h1>Curriculum</h1>
                Customized, gamified & engaging with fun, interactive based
                learning (STEAM & Bloom Taxanomy)
              </div>
            </div>
            <div className="card">
              <div>
                <img width="350" height="80" src={CardIcon6} alt="cardIcon6" />
              </div>
              <div>
                <h1>Contact us Anytime</h1>
                We are available 24x7at support@techokids.com
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <LoginWithOTP
          digit1={digit1}
          setDigit1={setDigit1}
          digit2={digit2}
          setDigit2={setDigit2}
          digit3={digit3}
          setDigit3={setDigit3}
          digit4={digit4}
          setDigit4={setDigit4}
          digit5={digit5}
          setDigit5={setDigit5}
          digit6={digit6}
          setDigit6={setDigit6}
          onValidateOTP={onValidateOTP}
          onCloseValidation={modalClose}
          OTPerror={OTPerror}
        />
      </Modal>
    </div>
  );
}
