import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import react1 from "./../../Assets/Blogs/react1.jpg";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Footer from "../../Components/Footer";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import reactjsfunc from "./../../Assets/Blogs/reactjsfunc.jpeg";
import reactm from "./../../Assets/Blogs/reactm.png";

import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
export const Reactjs = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Why React Js Important For Kids | Functions Of React Js | Techokids
      </title>
      <meta 
      name ="description" 
      content = " ReactJs refers to an open-source library of Javascript materials which can help develop user interfaces. to learning the same is knowing coding and programming. "/>
  <link rel = "canonical" href = "/blog/React"/>
  <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" />
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
        <h1 className="heading-content">Learn React js - online
          </h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
          
            <div style = {{marginLeft:20}} className="d-flex flex-column inner-blogs-name">
              <div>
              <b>Deepali Meena</b>
            </div>
            <div>2 June 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>        </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img style = {{maxwidth:850}} alt = "React Js importance" className="main-content-image" src={react1}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
<b>          Introduction
   </b>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      
        <p>
        ReactJs refers to an open-source library of Javascript materials which can help develop user interfaces. It is one of the programming languages to create single-page applications, and the Facebook community handles these.
For beginners, it can get complicated but works great in the long run as it allows the development of huge websites and applications.
If taking up application development is ever on the list, knowing React Js is a must, and the first step to learning the same is knowing coding and programming. 
To learn coding from scratch, you can always apply for an online coding class such as Techokids, where students of age 6-18 can learn from the basics and that too from expert and qualified mentors. 
React Js is a fun and engaging way to learn new subjects as the tools and features are commendable. 


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is React Js Important </b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
      Over the years, many open-end sources have been developed, and therefore the most frequently asked question is why one should use React Js. 
One of the main reasons to use React Js is that it allows the consumers to work with new tools and use the unique features of this programming language. Also, those who are new to this can use it to develop a framework for the website. 
Here are the features one should look for while working on React Js:
Easy to grasp
Basic knowledge of programming is a massive plus while learning React Js. It is understandable for beginners and those who have worked on HTML and CSS. Unlike others, it is not a domain-specific language and can be learned simultaneously. 
Simplicity
This one is quite similar to python. Like python can be grasped by anyone, react Js can be chosen by anyone with the basic knowledge of programming languages and coding. The platform used in creating through this one is JSX. It allows you to work with HTML and Javascript at the same time. It is not a compulsion, but working with JSX is much easier.
Binds the data together
Using and binding one-way data together, React Js makes the work easy for you. It uses Flux, an application to glue all the data and run the program accordingly. 


</p>

<br></br>
<h3>
        <b>
        Coding Plays a Vital Role in javaScript
</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        
      
        <p>
        JavaScript's function is to manage the state and update the user interface. However, we've learned that the appearance of a modern website is determined by its condition, which can lead to an almost infinite number of variants. So the question remains: Who or what is in charge of the state's management?
Behind the scenes, a program monitors all of your actions on the website and updates the state accordingly. This software is developed in JavaScript, the web's global language.
Adding interactivity to websites—
yep, you'll need to do some JavaScripting if you want a website to be more than a static page of text.
App development for mobile devices—
Websites aren't the only place where you may find JavaScript... It's also used to make the apps that you have on your phone and tablet.
Developing games for the web browser—
Have you ever played a game straight from your browser? That was most likely made possible by JavaScript.
JavaScript is most commonly employed on the front end of things, but it's a versatile enough scripting language to be utilized on back-end infrastructure as well.


</p>         
<br>
         </br> 
         <h3>
        <b>How to Get Started with JavaScript</b>
      </h3>    
         
        
        <p>
        What's the takeaway from this? While HTML and CSS allow you to create a basic web page, JavaScript is the programming language that will enable you to bring that page to life. Even though HTML and CSS alone will qualify you for entry-level developer work, investing the effort to master JavaScript can vastly improve your job prospects.
        <ul>
            <li>Write stuff! Create a Google App Engine account and begin developing real web apps with proper CRUD functionality.</li>
          <li> STAY AWAY FROM JQUERY. Try to write javascript with Jquery as much as possible. Jquery is an overly complex API, and you'll waste a lot of time understanding it rather than javascript. document.querySelector() would suffice.</li>
        <li>Put all of your code on GitHub and attempt to get people/friends who are wiser than you to read it and advise.</li>
        <li>Seek failure and continue to grow!
        </li>
        </ul>
        </p>
      
      
      <br></br>
    

<h3><b>Functions Of React Js

    </b></h3>
    <br></br>
<div className = "text-center">
  <img alt = "functions of react js " title = "functions of reacct js" src = {reactjsfunc} className = "other-image" >
  </img>
</div>
<br></br>
<p>
React Js is a huge helping hand in creating applications and websites, especially those involved in huge numbers and single-page websites.
Most of the functions here are handled by Javascript itself. One should consider using React Js because:

    <ul>
    <li>React.js is declarative</li>
    <li>React.js is simple</li>

<li>React.js is component-based</li>
<li>React.js supports server-side</li>
<li>React.js is extensive</li>
<li>React.js is fast </li>
<li>React.js is easy to learn 
</li>
</ul>
Also, JSX plays a vital role in developing websites for people as it lets you use HTML and Javascript together by mixing the same. Similarly, Facebook introduced React Naive in 2015, which allows the audience to develop an application for android, IOS, etc. 

</p>
<h3>
    <p>

    {/* <html>  
    <head>  
        <title>React's React</title>          
        <!-- Load React. -->  
        <!-- Note: when deploying, replace "development.js" with "production.min.js". -->  
        <script src="https://unpkg.com/react@17/umd/react.development.js" crossorigin></script>  
        <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js" crossorigin></script>  
        </head>  
        <body>  
        <div id="root"></div>      
       <!-- This is embedded JavaScript. You can even place this in separate .js file -->  
       <script>              
            window.onload = function()  
            {        
                class HelloClass extends React.Component   
                {  
                    render()   
                    {  
                        return React.createElement('div', null, 'React without npm..');  
                    }  
                }  
                ReactDOM.render(  
                    React.createElement(HelloClass, null, null),  
                    document.getElementById('root')  
                );  
            };           */}

          
        </p>
</h3>

<br></br>
<h3>

        <b>React Js And Techokids
</b>
      </h3> 
      <br></br>
      <div className = "text-center">
  <img alt = "reactjs" title = "reactjs" src = {reactm} className = "other-image" >
  </img>
</div>   
<br></br> 
        
        <p>Techokids is one of the leading online learning platforms for students to learn coding and apply it in real life. It conducts 1:1 interactive sessions with students for better understanding and fun. 
Coding is the first step to learning any programming language. Be it an easy or a complex one. It allows the students to explore the new dimensions of a subject. For example, react Js can be looked at differently if one knows the basics of coding. Therefore, rooting for a coding class at a young age can help your children see their future. 
{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
       <br></br> 
        <h3>
            <b>
         
Conclusion

            </b>
        
        </h3>


<p>Website development and designing are considered to be one of the best fields worldwide. React Js helps develop a website and lets you interact with the audience and let them know about the pros of learning coding and other programming languages. 
Coding is the basis of technology and innovations. You need to know its basics so that you are ready to face whatever comes your way. Web development is trendy amongst students and helps them navigate pertinent information from the website and apply it in their projects.
</p>
{/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>

  </>
  );
};

