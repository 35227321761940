import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SelectLogin.css";
import student from "../../Assets/student.png";
import teacher from "../../Assets/teacher.png";
import logo from "../../Assets/navbar/logo.png";
import arrow from "../../Assets/navbar/Vector.png";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
const SelectLogin = () => {
  let history = useHistory();
  return (
   
    
   <div className="select_login ">
     <Helmet>
      <title>Online Coding Classes For Kids |login |techokids
      </title>
      <meta 
      name ="description" 
      content = "TechoKids offers Best Live Online Coding Classes For Kids. Start learn Coding through Self-Paced Learning, Multiple Live Projects Training by Technical Experts.. "/>
  <link rel = "canonical" href = "https://www.techokids.com/login"/>
   <meta 
  name = "keywords" 
  content = " coding for kids, learn coding online for kids, kids, and coding, coding and programming for kids, coding for kids, online ed-tech platform for kids, live online coding classes for kids, programming and technology tips" /> 

  </Helmet>

      <div className="header">
        <Link to="/" className="header_back  ">
          <img src={arrow} alt="arrow"></img>
          <span className="ml-2">Back To Home</span>
        </Link>
        <Link to="/">
          <img
            className="brand"
            src={logo}
            onClick={() => history.push("/")}
            alt="logo-loginpage"
          ></img>
        </Link>
      </div>
      <h1 className="select_login_welcome ">Welcome to Techokids</h1>
      <div className="select_login_content ">
        <div className="dialogbox ">
          <div className="imageCol">
            <img className="loginImage" src={student} alt="student"></img>
          </div>
          <div className="loginDialogTextCol">
            <div className="loginLabel">Student's</div>
            <button
              className="loginButton"
              onClick={() =>
                (window.location.href =
                  "https://student.techokids.com/dashboard")
              }
            >
              Login Here
            </button>
          </div>
        </div>
        <div class="vl"></div>
        <div class="hl"></div>
        <div className="dialogbox ">
          <div className="imageCol">
            <img height = "167" width = "123" className="loginImage" src={teacher} alt="teacher"></img>
          </div>
          <div className="loginDialogTextCol">
            <div className="loginLabel">Teacher's</div>

            <button
              className="loginButton"
              onClick={() =>
                (window.location.href =
                  "https://mentor.techokids.com/dashboard")
              }
            >
              Login Here
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLogin;
