import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import purpose from "./../../Assets/Blogs/purpose.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Purpose = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center">
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        Why Is It Important To Have A Purpose In Life?
</h1>
        
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={purpose}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        

        There are many ways to hit the top, which includes good nourishment from an early age. As a parent, everyone knows that already. One of the best ways your child can be better at communication and understanding are reading. Inculcating the habit of reading is a must thing to do for your child’s betterment. It will help them in multiple ways, not only in school but also in their personal lives. Communication is the key to any successful business or relationship. Reading books will boost their vocabulary, and they will be able to grasp knowledge faster than other kids of their age. The skill is fundamental, and a child needs to keep a reading habit. It will help them in their lifelong success and increase their attention span and analytical skills. 

 {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        
        <p>
       
What do you mean by having a sense of purpose in life? Well, it has got a different definition for every individual. Not everyone would think the same. However, some common goals might include being a good human, a good friend, a good partner, helping others, being healthy, looking after the family, doing something for society, traveling the world, and so much more. There are endless purposes. To help your kids discover theirs, open every opportunistic door for them and let them explore the world. 
</p>


        <h3>
        Is it important to have a purpose in life?
</h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        {/* <p> */}
            
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    {/* A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out.  */}
{/* </p> */}
<p>
The obvious answer is yes. You do need to have a purpose in life. Think about a situation where you don’t have any meaning in life. Would you be working for something or someone? You would sit at a place and wonder about having no dreams and goals, completely lost. It acts as a motivation that pushes you forward to achieve success in your life. Here success doesn’t mean money. It could mean gaining happiness by helping others or maybe even running an NGO or accomplishing a corporate position in the office. There are several options, and it differs from individual to individual. It could help one achieve small or big dreams, so why not think about it?</p>

<h3>
        
How does having a purpose in life help you?

</h3>
<p>
You are bound to get baffled by the pros of having a purpose in life. Well, it is one of the several aspects which doesn’t have a con. So let’s get started. 
</p>

<ul>
    <li>You will be able to prioritize your life in a meaningful way. You don’t need to worry about what you need to do first or last as it is already going to be planned. This would ensure you achieve your goals, small or big.</li>
<li>Even when the situations get tough, you will push yourself and strive for your dreams. You would be working smart and hard to accomplish the checklist you have created in your mind. Never forget, “every cloud has a silver lining,”; and hence, you will be getting your day too. It is important to know that things get worse before it gets better. You must have heard of this phrase before. However, it does apply to real life, and hence, you need to trust yourself. </li>
    <li>If you have a purpose in life, you will become more resilient as you grow. 
</li>
        <li>Both your short and long-term goals are accomplished, and people tend to avoid procrastination. 
</li>
</ul>
<h3>
What does it reflect?
</h3>
<p>
If you have a purpose in your life, you would be able to gain happiness. Think about it. We all crave satisfaction in our life. There is no point in getting a promotion if your family relations aren’t good. You would be sad somewhere. So, the major goal of life is to be content with whatever you do. You don’t need to become a CEO to achieve happiness, as it comes automatically when you do good. It will make you honest, generous, realistic, and respectful. 

</p>

<h3>
    
Let’s take examples 
</h3>
<p>
You might want to become a coder! Doesn’t it sound fun? You would be able to develop games, applications to meet some needs. It would give you joy and make you financially stable. Undoubtedly, coding is used in our everyday life. There are ample opportunities in the coding world to work for. Once you become a coder, you wouldn’t need to work for many hours to become financially stable. Work less but earn more! It would make you independent, and you would be able to live life on your terms. 
</p>
<p>
    
Moreover, if you aim to become a coder, you would get time for your family and work on your favorite hobbies. Did you know it would empower you in ways you never imagined? Turn dreams into reality by creating software, applications, and witnessing the world applaud you. You would work remotely or from a physical location, and individuals will run up to you for your works! 

</p>
       <p> 
       Everything would help you realize your worth, make you stable financially, and even keep you happy. 



</p>
<p>
In a nutshell, it is important to have a purpose in life. With all the points mentioned above, one must understand the benefits and boons of having a meaningful life. Like it was told before, having a purpose is not about money but inner happiness. Whether it comes through coding or helping people, having a dream is important. Let us know about your story at TechnoKids. Follow us on Twitter, Facebook, Linked In, and Instagram to stay updated! 



</p>
    






<p>
    Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
      
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

