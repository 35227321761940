import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import robotics1 from "./../../Assets/Blogs/robotics1.png";
import "./content.css";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
import stepslearnrobotics from "./../../Assets/Blogs/stepslearnrobotics.png";
import functionsrobotics from "./../../Assets/Blogs/functionsrobotics.png";

export const Robotics = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Robotics Courses For Kids | Online Coding for Kids | Techokids  </title>
      <meta 
      name ="description" 
      content = "Robotics helps children in problem-solving and increases their consciousness along with empowering their observations. While applying to code. "/>
  <link rel = "canonical" href = "/blog/Robotics"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}} >
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
         {/* <div className="heading-content "> */} 
          <h1 className = "heading-content" >
        Robotics Courses For Kids 
  </h1>
         {/* </div>  */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
        
            <div  className="d-flex flex-column inner-blogs-name">
                <div>
              <b>Deepali Meena</b>
            </div>
            <div>5 June 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img style = {{maxWidth:850}} alt = "Robotics for kids" className="main-content-image" src={robotics1}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
          Introduction
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
    
        <p>
      
Coding and Robotics are two sides of one coin. Coding is the set of instructions a robot or a computer understands and works. Robots are fascinating. The 21st century is getting to newer heights every day, and most of its credit is given to technology. 
For every work you do, you need the help of a machine to make things easier for you. What if your kids made one? Amusing right. Robotics is all the play of trial and error, but the best part is that you create a masterpiece once you are done with mistakes. 
To learn something new, it's always good to have a teacher or a mentor beside you to guide you through the process. 
Techokids provides you with all the possible facilities and expert mentors to help you with the basics of a robotic class, explain the process and create wonders. 
Robotics creates a special place in people's minds as it sharpens the skills, helps them use tools, and designs one for themselves. 
 Robotics helps children in problem-solving and increases their consciousness along with empowering their observations. While applying to code, it is essential to create something that is not already there. For that, writing a proper regulation and entering it is required. If not, the movements of a robot won'twon't appear.


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is Robotics Important? </b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
   <br></br>
    <div className = "text-center">
    <iframe style = {{maxWidth:800}} className = "main-content-image" width="500" height="420" src="https://www.youtube.com/embed/ctrGdRA7CO4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <br></br>
    
     India is moving from agriculture-based to industrial-based, which means more and more machines are required every day. It simplifies the work by multiple percentages and is done way faster than manual. All this could be possible because of robotics and coding.
Not only in industries, but even in our day-to-day lives, we are dependent on technology to do our jobs. So as an outcome, we take those robotics has positively affected the lives of many. 
Having so many uses and more Robotics is still not a part of the curriculum of the school. However, thanks to online learning, kids can have fun with robotics and create one for the future. 
With Alexa, Siri, Cortana, and Google Assistant coming into the picture, the existence of Robotics has been in the spotlight, and the reasons to learn the same have increased. Some of them are:
<ul>
    <li>Encourages creative thinking</li>
    <li>Engagement</li>
    <li>Improves programming skills</li>
    <li>Improves observational skills</li>
    <li>Encourages group work</li>
    <li>It is fun to learn.</li>
</ul>
In the covid situation that the world is facing, technology is given the utmost priority. Coding is applied in almost all fields, for the medicines, vaccines, the machines in hospitals. Even for students submitting their assignments online, having classes on different platforms and more was all possible through robotics. Therefore learning such an exciting topic can never go in vain. 
</p>

<br></br>
<h3>
        <b>
        How Does Robotics Help In Coding? </b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        

        <p>
        Robots work through programming languages. The languages that are used in coding and therefore robotics and coding go hand in hand. The two main languages used in robotics are C++ and python. Python being one of the easiest of all, could be used in designing and without error functioning of robots. 
It works the best for beginners as it is easy to catch up. 
What can also use Java language, wherein who can run Java virtual machines on the robots? 
The users of the C/C++ language are also exceptionally high. One of the reasons for the same is that you can quickly learn the basics of this subject through these programs. 
Coding, therefore, is a massive part of robotics. Other languages less used but effective are:
<ul>
    <li>
    Raspberry Pi
    </li>
    <li>Arduino</li>
    <li>MATLAB</li>
    <li>Fortran</li>

</ul>

</p>         
<br>
         </br> 
         <h3>
        <b>Steps To Learn Robotics</b>
      </h3>    
         <br></br>
      <div className = "text-center">
  <img className = "other-image" src = {stepslearnrobotics} alt = "steps to learn robotics" />

</div>
<br></br>
        <p>
        Steps To Learn Robotics
Robotics brings fun along. When a kid creates a robot, it is considered to be his/her lifetime creation. Technology will overpower all other sources, and robotics is crucial to start learning the same from a young age. Here are the steps required to learn robotics:
<p>
Step 1: Start with the basics.
</p>
<p>
To start with, you should know basic coding to learn robotics. It is the base to let your robots work. As a beginner, you should learn through python as it is a million times tested and easy to learn the language. 
Through python, you can create good machinery that you can use in your daily life. It encourages students to learn something new and sharpen their problem-solving, reasoning, and computational skills. 

</p>
<p>
Step 2: Learn introductory algebra.
</p>
       <p>Those who are good with maths and know algebra well are a plus point for them to learn robotics. Algebra works the best. Usually, as a coder, not being good at maths is okay, but when it comes to making machinery, algebra comes into business to help in handling tools. Students can take up online classes as well, where mentors can help them with mathematics and robotics.</p>
        
   <p>Step 3: Kit it up and work on projects.</p>     
        <p>To work on the concepts of any subject, you need experience. When you start working on your project in robotics, you can classify the tools, know how they work, and create a masterpiece later. 
With basic robotics concepts, you can at least initiate a project, and as you learn more about the subject, you can go on and build a robot. Always remember to take help wherever you get stuck. Techokids is one platform where mentors can clear your doubts and explain to you where you went wrong. Also, collect all the tools and everything you need for making a robot and kit it up by yourself. This way, you can gain confidence and understand the concepts well. 
</p>
        
        </p>
      
      
      
  <br></br>  

<h3><b>Functions Of Robotics

    </b></h3>
<br></br>
    <div className = "text-center">
      <img className = "other-image" src = {functionsrobotics}  alt = "function of robotics"  />
    </div>
    <br></br>
<p>
Robots usually function according to the code. Once the code is typed and executed rightly, robots can have the ability to 
    <ul>
    <li>Sense</li>
    <li>Decode</li>
    <li>Act</li>


</ul>Through these three key points, a robot or any machine for that matter can work on your tunes. In regular times robotics is used by the industries to carry out heavy workloads. It is the priority of industrialists as it can detect errors quite faster than humans. Once you know the problem, you can work on it and correct it through machines. Knowing robotics and coding can get you jobs in huge companies like Google and Microsoft in the science field. 
It is a massive plus for manufacturing, designing, and delivering. Robots are human's friends, and being able to create one is a blessing.

</p>
    {/* <html>  
    <head>  
        <title>React's React</title>          
        <!-- Load React. -->  
        <!-- Note: when deploying, replace "development.js" with "production.min.js". -->  
        <script src="https://unpkg.com/react@17/umd/react.development.js" crossorigin></script>  
        <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js" crossorigin></script>  
        </head>  
        <body>  
        <div id="root"></div>      
       <!-- This is embedded JavaScript. You can even place this in separate .js file -->  
       <script>              
            window.onload = function()  
            {        
                class HelloClass extends React.Component   
                {  
                    render()   
                    {  
                        return React.createElement('div', null, 'React without npm..');  
                    }  
                }  
                ReactDOM.render(  
                    React.createElement(HelloClass, null, null),  
                    document.getElementById('root')  
                );  
            };           */}

            
<br></br>
<h3>

        <b>Robotics At Techokids
</b>
      </h3> 
        
        
        <p>New technology is being introduced every day, and with the same, there is still a need for some more. Today'sToday's generation has the challenge to face, and that is to compete in a techno world. Knowing Robotics and coding can make it much easier for them to create their wonders. 
Techokids have taken up this challenge of preparing students for the future by providing them a course on Robotics. They are an online learning platform where students can have live 1:1 interactive sessions and live projects to make it all fun. 
With expert teachers being beside them, Robotics can turn out a lot easier than it is, and kids can build one for themselves in a short period.
        </p>

        <br></br>
        
        <h3>
            <b>
         
Conclusion

            </b>
        
        </h3>


<p>Robots are machines that can work on your commands, and when you create one, you are overpowered. It is always exciting to work on something new, and when you have robots with you, work seems more fun and interesting. It'sIt's never too late to start from the basics and get help from the mentors, and you have Techokids. Book your slot today and learn Robotics from very qualified teachers and make one for yourself. 
</p>
{/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

