import React from "react";
import { lazy } from "react";
import { Suspense } from "react";
import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import ReferEarn from "./Core/ReferEarn/ReferEarn";
import BookTrialContainer from "./Core/BookATrial/BookTrialContainer";
import AboutUs from "./Core/AboutUs/AboutUs";
import Home from "./Core/Home/Home";
import { getTitleAndMetaDescription } from "./Components/MetaDetails";
import SelectLogin from "./Core/Login/SelectLogin";
import PaymentForm from "./Core/Payment/payment";
import { Helmet } from "react-helmet";
import structuredJSON from "./structuredJSON.json";
import ReactPixel from "react-facebook-pixel";
import Overlay from "./Components/Overlay";
import { Blog } from "./Core/Blog/Blog";
import Error1 from "./Core/Error404/Error404";

import { Content } from "./Core/Blog/Content";
import { Content1 } from "./Core/Blog/Content1";
import { Content2 } from "./Core/Blog/Content2";
import { Content3 } from "./Core/Blog/Content3";
import { Content4 } from "./Core/Blog/Content4";
import { Appdev } from "./Core/Blog/Appdev";
import { ArVr } from "./Core/Blog/ArVr";
import { Cpp } from "./Core/Blog/Cpp";
import { DataScience } from "./Core/Blog/DataScience";
import { ArtificialIntelligence } from "./Core/Blog/ArtificialIntelligence";
import { Robotics } from "./Core/Blog/Robotics";
import { Javascript } from "./Core/Blog/Javascript";
import { Python } from "./Core/Blog/Python";
import { GameProgramming } from "./Core/Blog/GameProgramming";
import { WebDevelopment } from "./Core/Blog/WebDevelopment";
import { Reactjs } from "./Core/Blog/Reactjs";
import { Onlinelearning21 } from "./Core/Blog/Onlinelearning21";
import { Onlineeducation } from "./Core/Blog/Onlineeducation";
import { Codingwebsites } from "./Core/Blog/Codingwebsites";
import { Healthyrelationship } from "./Core/Blog/Healthyrelationship";
import { Codingmyth } from "./Core/Blog/Codingmyth";
import { Stressmanagement } from "./Core/Blog/Stressmanagement";
import { Timemanagement } from "./Core/Blog/Timemanagement";
import { Parenttips } from "./Core/Blog/Parenttips";
import { Readinghabit } from "./Core/Blog/Readinghabit";
import { Purpose } from "./Core/Blog/Purpose";
import { Bestcodingapp } from "./Core/Blog/Bestcodingapp";
import { Bestmovies } from "./Core/Blog/Bestmovies";
import { Minecraft } from "./Core/Blog/Minecraft";
import ScrollTop from "./Core/Blog/ScrollTop";
import CounsellingContainer from "./Core/counselling/CounsellingContainer";
import TechSupport from "./Core/system check/TechSupport.js";
const TermsCondition = lazy(() =>
	import("./Core/TermsCondition/TermsCondition") 
);
const Privacypolicy = lazy(() => import("./Core/PrivacyPolicy/Privacypolicy"));
const HelpFaq = lazy(() => import("./Core/Help and FAQ/HelpFaq"));

export const CountryContext = createContext();

const Routes = () => {
	const [countryCode, setCountryCode] = useState("");
	let pathname = window.location.pathname;
	let details = getTitleAndMetaDescription();
	let item = details[pathname];

	//Google analytics
	useEffect(() => {
		ReactGA.initialize("UA-139284959-2");
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		getLocation().then((data) => setCountryCode(data.country_code));

		ReactPixel.init("3229001867228397");
		ReactPixel.pageView("3229001867228397");
	}, []);

	const getLocation = async () => {
		return await fetch("https://ipapi.co/json/")
			.then(function (response) {
				return response.json();
			})
			.then(function (data) {
				return data;
			});
	};

	return (
		<CountryContext.Provider value={countryCode}>
			<Router>
				<ScrollTop />
				{item && (
					<Helmet>
						<title>{item.title}</title>
						<meta name="description" content={item.description} />
						<meta name="keywords" content={item.keywords} />
						<meta property="og:type" content="website" />
						<meta property="og:title" content={item.og.title} />
						<meta property="og:description" content={item.og.description} />
						<meta property="og:url" content={item.og.url} />
						<meta property="og:image" content={item.og.img} />
						<meta name="twitter:card" content={item.twitterCard} />
						<script className="structured-data-list" type="application/ld+json">
							{JSON.stringify(structuredJSON)}
						</script>
					</Helmet>
				)}
				<Suspense fallback={<Overlay />}>
					<Switch>
						<Route
							path="/blog/Best-movies-for-kids-to-become-curious-about-coding"
							exact
							component={Bestmovies}
						/>
						<Route
							path="/blog/Minecraft-Beginners-guide"
							axact
							component={Minecraft}
						/>
						{/* <Route Path = "/blog/5-best-coding-app-for-kids" exact component = {Bestcodingapp}/>  */}
						<Route
							path="/blog/Why-is-it-important-to-have-purpose-in-life"
							exact
							component={Purpose}
						/>
						<Route
							path="/blog/Ways-to-encourage-reading-habits-in-your-child"
							exact
							component={Readinghabit}
						/>
						<Route
							path="/blog/Tips-for-parents-who-want-to-teach-their-kids-code"
							exact
							component={Parenttips}
						/>
						<Route
							path="/blog/Ways-to-teach-kids-about-Time-Management"
							exact
							component={Timemanagement}
						/>
						<Route
							path="/blog/Ways-to-teach-kids-about-Stress-Management"
							exact
							component={Stressmanagement}
						/>
						<Route
							path="/blog/Top5-Coding-Myths"
							exact
							component={Codingmyth}
						/>
						<Route
							path="/blog/how-to-teach-kids-about-building-healthy-relationships"
							exact
							component={Healthyrelationship}
						/>
						<Route
							path="/blog/websites-for-coding"
							exact
							component={Codingwebsites}
						/>
						<Route
							path="/blog/How-is-Online-education-helpful-in-covid-19"
							exact
							component={Onlineeducation}
						/>
						<Route
							path="/blog/Online-Learning-For-KIds-in-21century"
							exact
							component={Onlinelearning21}
						/>
						<Route path="/blog/data-science" exact component={DataScience} />
						<Route
							path="/blog/C++-programming-language"
							exact
							component={Cpp}
						/>
						<Route path="/" exact component={Home} />
						<Route path="/refer-earn" exact component={ReferEarn} />
						<Route path="/login" exact component={SelectLogin} />
						<Route path="/blog" exact component={Blog} />
						<Route path="/blog/content/:id" exact component={Content} />

						{/* <Route path="/blog1" exact component={Blog1} /> */}

						<Route path="/blog/Robotics-for-kids" exact component={Robotics} />
						<Route path="/blog/javascript" exact component={Javascript} />
						<Route path="/blog/Python-Programming" exact component={Python} />
						<Route
							path="/blog/game-programming"
							exact
							component={GameProgramming}
						/>
						<Route
							path="/blog/web-development"
							exact
							component={WebDevelopment}
						/>
						<Route path="/blog/react-js" exact component={Reactjs} />
						<Route
							path="/blog/artificial-intelligence"
							exact
							component={ArtificialIntelligence}
						/>
						<Route
							path="/blog/Top-5-programming-language-every-kid-should-learn"
							exact
							component={Content1}
						/>
						<Route
							path="/blog/Coding-for-kids-10-Things-Parent-Need-To-Know"
							exact
							component={Content2}
						/>
						<Route
							path="/blog/10-best-ways-to-learn-coding"
							exact
							component={Content4}
						/>
						<Route
							path="/blog/Mind-Maps-ideas-for-kids"
							exact
							component={Content3}
						/>
						<Route
							path="/blog/5-reasons-why-coding-is-important-for-kids"
							exact
							component={Content}
						/>
						<Route path="/blog/app-development" exact component={Appdev} />
						<Route path="/blog/ar-vr" exact component={ArVr} />
						<Route path="/payment" exact component={PaymentForm} />
						<Route path="/help-faq" exact component={HelpFaq} />
						<Route path="/terms-conditions" exact component={TermsCondition} />
						<Route path="/privacy-policy" exact component={Privacypolicy} />
						<Route path="/about-us" exact component={AboutUs} />
						<Route path="/book-trial" exact component={BookTrialContainer} />
						<Route path="/counselling-session" exact component={CounsellingContainer} />
            <Route path="/system-check" exact component={TechSupport} />
						<Route path="" exact component={Error1} />
					</Switch>
				</Suspense>
			</Router>
		</CountryContext.Provider>
	);
};

export default Routes;
