import Image2 from "./../../Assets/Blogs/Image2.png"; 
import Image1 from "./../../Assets/Blogs/Image1.png";
// import Image2 from "./../../Assets/Blogs/Image2.png";
import Image3 from "./../../Assets/Blogs/Image3.jpg";
import Image4 from "./../../Assets/Blogs/Image4.jpg";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import AppDevThumbnail from "./../../Assets/Blogs/AppDevThumbnail.jpg"
import arvrthumbnail from "./../../Assets/Blogs/arvrthumbnail.jpg";
import ai1 from "./../../Assets/Blogs/ai1.jpg";
import cpp1 from "./../../Assets/Blogs/cpp1.jpg";
import ds1 from "./../../Assets/Blogs/ds1.jpg";
import gameprog1 from "./../../Assets/Blogs/gameprog1.jpg";
import js1 from "./../../Assets/Blogs/js1.jpg";
import python1 from "./../../Assets/Blogs/python1.jpg";
import react1 from "./../../Assets/Blogs/react1.jpg";
import robotics1 from "./../../Assets/Blogs/robotics1.png"
import webdev1 from "./../../Assets/Blogs/webdev1.jpg";
import minecraft1 from "./../../Assets/Blogs/minecraft1.jpg";
import purpose from "./../../Assets/Blogs/purpose.jpg";
import readinghabits from "./../../Assets/Blogs/readinghabits.jpg";
import parenttips from "./../../Assets/Blogs/parenttips.jpg";
import timemanagement from "./../../Assets/Blogs/timemanagement.jpg";
import stressmanagement from "./../../Assets/Blogs/stressmanagement.jpg";
import healthyrelationships from "./../../Assets/Blogs/healthyrelationships.jpg";
import onlinecovid19 from "./../../Assets/Blogs/onlinecovid19.jpg";
import onlinelearning from "./../../Assets/Blogs/onlinelearning.jpg";


import codingmyth from "./../../Assets/Blogs/codingmyth.jpg";


import deepali from "./../../Assets/Blogs/deepali.jpeg";


import Robotics1 from "./../../Assets/Blogs/Robotics1.jpg";
import bestmovies from "./../../Assets/Blogs/bestmovies.jpg";


  const bloginfo  = [

    {
        "title":"Top 5 Programming Languages Every Kid Should Learn At Its Early Age",
        "body":"People often get confused in deciding which programming language they should learn. Which programming languages should they learn? Which programming",
        "name":"Kunal Basantani",
        "date":"28 October 2020",
        "blogUrl":"/blog/Top-5-programming-language-every-kid-should-learn",
        "image":Image1,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
        
    } ,
    {
        "title":"Coding For Kids – 10 Things Parents Need To Know Right Now",
        "body":"10 Things Parents Need To Know About Coding For Kids With the advent of the digitalized 21st century, it might be hard to cope with regular innovations and trends.  ",
        "blogUrl":"/blog/Coding-for-kids-10-Things-Parent-Need-To-Know",
        "name":"Kunal Basantani",
        "date":"12 November 2020",
        "image":Image2,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    } ,
    {
        "title":"Mind Maps Ideas For Kids – The Effects On Early Education",
        "body":" Are you looking for a guide to help your children learn better? Well, the portal would help you understand how you can aid their learning process. Is it all about mathematics ",
        "name":"Kunal Basantani",
        "date":"28 November 2020",
        "blogUrl":"/blog/Mind-Maps-ideas-for-kids",
        "image":Image3,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    },
    {
        "title":"10 Best Ways To Learn Coding For The 21st Century Kids",
        "body":"Coding is also known as computer programming. It is done by writing computer programs, and those who do it are known as programmers. Coding can help ",
        "name":"Kunal Basantani",
        "date":"19 December 2020",
        "blogUrl":"/blog/10-best-ways-to-learn-coding",
        "image":Image4,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
        
    
    },
    {
        "title":"Application Development For Kids",
        "body":"Technology has been innovating new things every single day. This brings the necessity of having an application for all things around us. There are apps available for the  things  ",
        "name":"Deepali Meena",
        "date":"15 May 2021",
        "blogUrl":"/blog/app-development",

        "image":AppDevThumbnail,
        "imageau":deepali,
        "time":"3 min"
   
    },
    
    {
        "title":"Virtual & Augmented Reality for 3D",
        "body":" Over the years, the definition of technology has been completely changed by new inventions and creativity. Augmented Reality and Virtual Reality have an enormous",
        "name":"Deepali Meena",
        "date":"15 May 2021",
        "image":arvrthumbnail,
        "blogUrl":"/blog/ar-vr",
        "imageau":deepali,
        "time":"4 min"
    },
    {
        "title":"Artificial Intelligence for kids",
        "body":" One of the features that distinguish humans from other animals is the use of labor-saving devices that they use today. Only recently have we had the technology to begin to   ",
        "name":"Deepali Meena",
        "date":"20 May 2021",
        "blogUrl":"/blog/artificial-intelligence",
        "image":ai1,
        "imageau":deepali,
        "time":"4 min"
    },
    {
        "title":"C++Programming Language",
        "body":"Programming languages have got a lot of competitors over the past few years. Python being one of the easiest, is chosen by many programmers to develop ",
        "name":"Deepali Meena",
        "date":"20 May 2021",
        "blogUrl":"/blog/C++-programming-language",
        "image":cpp1,
        "imageau":deepali,
        "time":"3 min"
    },
    {
        "title":"Data Science For Kids",
        "body":"Every minute of every day, data is created without our knowledge. The advent of digital and mobile communication has connected and complicated the world",
        "name":"Deepali Meena",
        "date":"25 May 2021",
        "blogUrl":"/blog/data-science",
        "image":ds1,
        "imageau":deepali,
        "time":"5 min"
    },
    {
        "title":"Game Programming",
        "body":" Game programming refers to decoding a code to bring the characters of the game to life. Coding is the first step to learn game programming. ",
        "name":"Deepali Meena",
        "date":"25 May 2021",
        "blogUrl":"/blog/game-programming",
        "image":gameprog1,
        "imageau":deepali,
        "time":"3 min"
    },
    {
        "title":"JavaScript For Kids",
        "body":"JavaScript is a widely used interpreted scripting language that, as of early 2019, had overtaken Python as the most commonly learned programming language",
        "name":"Deepali Meena",
        "date":"30 May 2021",
        "blogUrl":"/blog/javascript",
        "image":js1,
        "imageau":deepali,
        "time":"4 min"
    },
    {
        "title":"Python Programming.",
        "body":"  Python is one of the famous programming languages to be learned by coders. It is applied in data sciences, web designing, and creating prototype documents.",
        "name":"Deepali Meena",
        "date":"2 June 2021",
        "blogUrl":"/blog/Python-Programming",
        "image":python1,
        "imageau":deepali,
        "time":"3 min"
    },
    
    {
        "title":"Learn React js - online",
        "body":"ReactJs refers to an open-source library of Javascript materials which can help develop user interfaces. It is one of the programming languages to create single-page",
        "name":"Deepali Meena",
        "date":"1 June 2021",
        "blogUrl":"/blog/react-js",
        "image":react1,
        "imageau":deepali,
        "time":"3 min"
    },
    {
        "title":"Robotics For Kids",
        "body":"  Coding and Robotics are two sides of one coin. Coding is the set of instructions a robot or a computer understands and works. Robots are fascinating.",
        "name":"Deepali Meena",
        "date":"5 June 2021",
        "blogUrl":"/blog/Robotics-for-kids",
        "image":Robotics1,
        "imageau":deepali,
        "time":"4 min"
    },
    {
        "title":"Web Development",
        "body":"  Websites are a part of every profession you go in. It is not only for those computer freaks but is also required to keep a record of items, employees, and other  ",
        "name":"Deepali Meena",
        "date":"10 June 2021",
        "blogUrl":"/blog/web-development",
        "image":webdev1,
        "imageau":deepali,
        "time":"4 min"
    },
    {
        "title":"Minecraft Coding for Kids",
        "body":"Lately it's going to seem as if coding for teenagers has become synonymous with Minecraft. within the past few years, numerous coding activities started featuring",
        "name":"Deepali Meena",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Minecraft-Beginners-guide",
        "image":minecraft1,
        "imageau":deepali,
        "time":"3 min"
    },
    {
        "title":"Best Movies & Documentaries for Kids and Teens To become curious about coding",
        "body":"One of the best ways to help children get curious about coding is by letting them watch movies related to such subjects. You might wonder what's the benefit of watching movies for coding.",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Best-movies-for-kids-to-become-curious-about-coding",
        "image":bestmovies,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },

{
        "title":" Why Is It Important To Have A Purpose In Life?",
        "body":"There are many ways to hit the top, which includes good nourishment from an early age. As a parent, everyone knows that already. One of the best ways your child ",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Why-is-it-important-to-have-purpose-in-life",
        "image":purpose,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },
    {
        "title":"Ways to encourage reading habits in children and Why is it essential for them?",
        "body":"There are many ways to hit the top, which includes good nourishment from an early age. As a parent, everyone knows that already. One of the best ways your child can be better at communication",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Ways-to-encourage-reading-habits-in-your-child",
        "image":readinghabits,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },

    {
        "title":"Tips-for-parents-who-want-to-teach-their-kids-code",
        "body":"Regardless of what your profession is, teaching codes to kids might be a difficult problem. Even if you are a computer scientist or a software developer, teaching the basics could be a hectic task.",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Tips-for-parents-who-want-to-teach-their-kids-code",
        "image":parenttips,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    },

    {
        "title":"What are the ways-to-teach-kids-about-Time-Management",
        "body":"Guess what’s one of the essential skills that one must have? If you thought about time management, then you are right. Individuals grow up learning several skills from elders",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Ways-to-teach-kids-about-Time-Management",
        "image":timemanagement,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    },


    {
        "title":"How/What are the ways to teach kids about Stress Management?",
        "body":"Stress may not be a significant factor for young kids. A six-year-old child may not understand the meaning or effect as well. However, it is essential to note that they go through stress",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Ways-to-teach-kids-about-Stress-Management",
        "image":stressmanagement,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },

    {
        "title":"Top 5 Coding Myths Vs. Facts For Early School-Age Children",
        "body":"If you are enthusiastic about coding and want to learn more about it, you might have done a google search before. Undoubtedly, you happened to witness many facts and figures. ",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Top5-Coding-Myths",
        "image":codingmyth,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },

    {
        "title":"  How to teach kids about building healthy relationships? ",
        "body":"Maintaining healthy relationships is crucial for mental and emotional well-being. Not only that, but also for your survival Having good relationships with teachers and parents.",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/how-to-teach-kids-about-building-healthy-relationships",
        "image":healthyrelationships,
        "imageau":KunalBasantaniImg,
        "time":"4 min"
    },
    {
        "title":"How has Online Education/E-learning been immensely helpful during COVID-19",
        "body":"We all know the impact of the deadly COVID-19 it has on our lives. It has lead to a drop in the speed of the global economy.Every country is suffering from the sudden and abrupt changes it had to make. Think about it. ",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/How-is-Online-education-helpful-in-covid-19",
        "image":onlinecovid19,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    },

    {
        "title":"Why online learning for kids is required in 21st Century?",
        "body":"The trend of Online learning for kids has become a buzzword amongst the education industry through the intervention of various digital technologies. In a classroom environment with another 40 students, all of them having different grasping power ",
        "name":"Kunal Basantani",
        "date":"12 June 2021",
        
        "blogUrl":"/blog/Online-Learning-For-KIds-in-21century",
        "image":onlinelearning,
        "imageau":KunalBasantaniImg,
        "time":"5 min"
    },
];

export default bloginfo;