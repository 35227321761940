import React, { useState } from "react";
import Counselling from "./Counselling";
import Overlay from "../../Components/Overlay";
import {
  CounsellingFn,
} from "../../DataAccessObject/DataAccessObject";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import CountDown from "../countdown/CountDown";
// gets the value from url

const getRefferalCode = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let refferalCode = urlParams.get("referral");
  if (refferalCode) return refferalCode;
  return null;
};
const getDefaultPhone = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let phoneNumber = urlParams.get("phoneNumber");
  if (phoneNumber) return "+" + phoneNumber;
  return null;
};

// checks if a given string represents a valid email
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// get dates for next 6 subsequent days
const getDates = () => {
  const dates = [];
  const DATE_OPTIONS = { 
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const today = new Date();
  for (let i = 1; i <= 6; ++i) {
    let date = new Date();
    date.setDate(today.getDate() + i);
    dates.push(date.toLocaleDateString("en-US", DATE_OPTIONS));
  }
  return dates;
};

// components to be rendered
export default function CounsellingContainer(props) {
  // sattes maintained for this component
  let defaultPhoneNumber = getDefaultPhone();
  let defaultRefferalCode = getRefferalCode();
  const [isLoading, setIsLoading] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [token, setToken] = useState(null);

  // ******************************************
  // states maintained for book-trial-form page
  // ******************************************
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    defaultPhoneNumber ? defaultPhoneNumber : ""
  );
  const [studentName, setStudentName] = useState("");
  const [counsellorName, setCounsellorName] = useState("");
  const [referralCode, setReferralCode] = useState(
    defaultRefferalCode ? defaultRefferalCode : ""
  );
  const [grade, setGrade] = useState(1);
  const [counsellingDateAndTime, setCounsellingDateAndTime] = useState(null);
  const [havePC, setHavePC] = useState(true);
  const [parentNameError, setParentNameError] = useState(false);
  const [parentEmailError, setParentEmailError] = useState(false);
  const [parentContactError, setParentContactError] = useState(false);
  const [studentNameError, setStudentNameError] = useState(false);
  const [parentNameSuccess, setParentNameSuccess] = useState(false);
  const [parentEmailSuccess, setParentEmailSuccess] = useState(false);
  const [parentContactSucess, setParentContactSuccess] = useState(false);
  const [studentNameSuccess, setStudentNameSucess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [counsellorNameSuccess, setCounsellorNameSuccess] = useState(false)
  const [counsellorNameError, setCounsellorNameError] = useState(false)

  // ******************************************
  // states maintained for login-with-otp
  // ******************************************
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const [digit5, setDigit5] = useState("");
  const [digit6, setDigit6] = useState("");
  const [otpError, setotpError] = useState(false);
  
  // ******************************************
  // states maintained for book-date-time page
  // ******************************************
  const [dateIndex, setDateIndex] = useState(0);
  const [timeIndex, setTimeIndex] = useState(0);
  const dates = getDates();
  const times = [
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
    "07:00PM",
    "08:00PM",
    "09:00PM",
  ];

  // ******************************************
  // handler for book-trial-form
  // ******************************************
  const modalClose = () => {
    setModalOpen(false);
  };

  const onRegisterCounselling = function (event) {
    // validate all fields here
    let canProcess = true;

    if (parentName.trim() === "") {
      canProcess = false;
      setParentNameError(true);
    } else {
      setParentNameSuccess(true);
    }
    if (!validateEmail(parentEmail)) {
      canProcess = false;
      setParentEmailError(true);
    } else {
      setParentEmailSuccess(true);
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      canProcess = false;
      setParentContactError(true);
    } else {
      setParentContactSuccess(true);
    }
    if (studentName.trim() === "") {
      canProcess = false;
      setStudentNameError(true);
    } else {
      setStudentNameSucess(true);
    }
    if (counsellorName.trim() === "") {
            canProcess = false;
            setCounsellorNameError(true);
          } else {
            setCounsellorNameSuccess(true);
          }
    // handle the API request here!
    if (!canProcess) return window.scrollTo(0, 0);
    let parsedPhone = parsePhoneNumber(phoneNumber);
    let parentPhone = {
      number: parsedPhone.nationalNumber,
      countryCode: parsedPhone.countryCallingCode,
    };

    // make actual API request
    setIsLoading(true);
    CounsellingFn(
      parentName,
      parentEmail,
      parentPhone,
      studentName,
      counsellorName,
      grade,
      counsellingDateAndTime,
      "student",
      // referralCode
    )
      .then((response) => {
        if (response.status === 200) {
          setToken(response.data.token);
          let setters = [
            setDigit1,
            setDigit2,
            setDigit3,
            setDigit4,
            setDigit5,
            setDigit6,
          ];
          for (let i = 0; i < setters.length; ++i) setters[i]("");
          setModalOpen(true);
          // setItemIndex(1);
          window.scrollTo(0, 0);
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        alert(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  

  let items = [
    <Counselling
      parentName={parentName}
      setParentName={setParentName}
      parentEmail={parentEmail}
      setParentEmail={setParentEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      studentName={studentName}
      setStudentName={setStudentName}
      referralCode={referralCode}
      setReferralCode={setReferralCode}
      grade={grade}
      setGrade={setGrade}
      counsellingDateAndTime={counsellingDateAndTime}
      setCounsellingDateAndTime={setCounsellingDateAndTime}
      havePC={havePC}
      setHavePC={setHavePC}
      parentNameError={parentNameError}
      setParentNameError={setParentNameError}
      parentEmailError={parentEmailError}
      setParentEmailError={setParentEmailError}
      parentContactError={parentContactError}
      setParentContactError={setParentContactError}
      studentNameError={studentNameError}
      setStudentNameError={setStudentNameError}
      parentNameSuccess={parentNameSuccess}
      setParentNameSuccess={setParentNameSuccess}
      parentEmailSuccess={parentEmailSuccess}
      setParentEmailSuccess={setParentEmailSuccess}
      parentContactSucess={parentContactSucess}
      setParentContactSuccess={setParentContactSuccess}
      studentNameSuccess={studentNameSuccess}
      setStudentNameSucess={setStudentNameSucess}
      onRegisterCounselling={onRegisterCounselling}
      setIsLoading={setIsLoading}
      setItemIndex={setItemIndex}
      token={token}
      modalOpen={modalOpen}
      modalClose={modalClose}
      counsellorName={counsellorName} 
      setCounsellorName={setCounsellorName}
      counsellorNameError={counsellorNameError}
      setCounsellorNameError={setCounsellorNameError}
      counsellorNameSuccess={counsellorNameSuccess}
      setCounsellorNameSuccess={setCounsellorNameSuccess}
    />,
    <CountDown/>,
  ];

  // component to be rendered!
  if (itemIndex === -1) return <Redirect to="/" />;
  return (
    <div>
      <Helmet>
      <title> Online Robotics Classes For Kids|Book a Free Trial Class|Techokids</title>
      <meta 
      name ="description" 
      content = "Book A Free 1:1 Online Trial Session. TechoKids is an online learning platform for kids of 6 to 18 years.21st-century new-age tech skills like Coding. "/>
  <link rel = "canonical" href = "https://www.techokids.com/book-trial"/>
   <meta 
  name = "keywords" 
  content = " KEYWORD: free online trial class, free online coding classes for kids in India, learn from techokids, e-learning for kids, virtual learning from home, virtual learning platform, multiple live projects, online training certification, games, website, apps, animations, strong technical experienced teachers, gamified curriculum, world-class events, online coding competitions, online coding challenges" /> 
  </Helmet>

      {isLoading && <Overlay />}
      {items[itemIndex]}
    </div>
  );
}

