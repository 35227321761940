import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import ds1 from "./../../Assets/Blogs/ds1.jpg";
import ds2 from "./../../Assets/Blogs/ds2.jpg";
import DS3 from "./../../Assets/Blogs/DS3.jpg";
import Lifecyclebanner from "./../../Assets/Blogs/Lifecyclebanner.jpg";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import Footer from "./../../Components/Footer";
import ScrollTop from "./ScrollTop";
export const DataScience = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Data Science for Kids | Steps of learning Data Science | Techokids </title>
      <meta 
      name ="description" 
      content = "Data Science is becoming increasingly important in organizations. According to one report, the global Data Science market is predicted to reach $115 billion by 2023. "/>
  <link rel = "canonical" href = "/blog/DataScience"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
              <h1 className="heading-content ">Data Science for Kids</h1>

       
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex">
         
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
          
            <div  className="d-flex flex-column inner-blogs-name" >
              <div>
              <b>Deepali Meena</b>
            </div>
            <div>25 May 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>
           
       </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img alt = "data science for kids" className="main-content-image" src={ds1} style = {{maxWidth:850}}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
          Introduction
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        Every minute of every day, data is created without our knowledge. The advent of digital and mobile communication has connected and complicated the world simultaneously, making it more networked and traceable, which has often led to the availability of enormous scale data sets that are difficult to comprehend. Every minute of every day, data is created without our knowledge. The advent of digital and mobile communication has connected and complicated the world simultaneously, making it more networked and traceable, which has often led to the availability of enormous scale data sets that are difficult to comprehend.
 So, what exactly is this "Big Data" craze? Big data can be characterized as data sets that are too large for traditional database software tools to acquire, manage, and handle. The definition varies by industry, depending on the types of software tools available and the volumes of popular data sets in that area.
 Data Science is the study of data retrieval and analysis to discover information and correspondences buried in unprocessed data, often known as raw data. To put it another way, data science is the study of extracting useful information from data by combining programming abilities with mathematical and statistical expertise.
Data Science consists of applying machine learning techniques to numerical, textual, image, video, and audio content known as data science. As a result, the algorithms carry out specific duties related to data extraction, cleansing, and processing, resulting in data translated into real value for each company.
 

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Importance of Data Science</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
         <br></br>
    <div className = "text-center"> 
    <img className = "other-image" src = {DS3} alt = "Data science image" style = {{maxWidth:780}}/>

    </div>
        <br></br>
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
         The generation and application of information is a vital economic activity in today's world. It is made more accessible by Data Science's ability to extract information from massive amounts of data. Information technology makes our lives easier by allowing us to collect and process more data more quickly and efficiently, allowing us to get results in hours rather than days or weeks.
To stay ahead of the curve, data science can assist predict and track forthcoming trends by promptly allocating resources to the offered proposition, which may realize long-term rewards in competitive advantage and profitability.
· Managerial Empowerment
Data science improves senior-level staff's analytical skills by measuring, tracking, recording performance indicators and other important information. Data scientists transform raw data into processed information, analyze it, and offer it to executives.
· Concentrating on the Most Crucial Matters
Having a data analytics solution in place also necessitates that your employees are familiar with it. A basic demonstration of the analytical product's capabilities and applications can assist everyone in understanding their role in tackling critical business concerns.
· Making Decisions Based on Evidence
Data science may help a company reduce its risk to a large amount. Data can be collected from various sources and evaluated to develop models that simulate different scenarios—this activity aids in determining the best potential conclusion and allows the company to make evidence-based decisions.

</p>
<br></br>
<h3>
        <b>
 Role of Coding in Data Science 
</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <div className="text-center">
        <img className = "other-image" alt = "role of coding in data science" style = {{ maxWidth:700} }  src={ds2}></img>
      </div>
      
        <p>
        Coding plays a very significant role in data science. Which can find it in practically every step of the procedure. However, how is coding used in each step of a data science problem solution? This is something that one must grasp. A typical data science experiment is broken down into stages, with a complete description of how coding is integrated into the process. It's crucial to remember that this process isn't necessarily linear; depending on the nature of the problem, data scientists will ping-pong back and forth
between different processes.
 First and first, data scientists must comprehend the problem at hand. They must next compile the necessary information. The
cleaning and analysis stage follows. In this procedure, data scientists use a variety of coding languages. Python is one of the most widely used programming languages in data science. It is often considered the programming language that serves as the foundation of data science since it can gather, clean,
analyze, and visualize data.


</p>         
<br>
         </br> 
         <h3>
        <b>Steps of learning Data Science </b>
      </h3>    
         
        <p>
        Data scientists are frequently required to present their findings to others. This can mean the difference between being a good data scientist and becoming a great one. In a corporate context, data analysis is usually only practical if you can persuade others in your firm to act on what you've discovered, which requires how to convey data.
Understanding the topic and theory is an integral part of expressing insights; you'll never be able to explain something to others if you don't comprehend it yourself. Another aspect is knowing how to organize your results logically. The ability to adequately describe your analysis is the final component. It's challenging to master the art of enough expressing
complex topics, but here are some strategies to try:

        </p>

      <ul>
          <li>Create a blog.</li>
          <li>Please try to share the findings of your data analysis. </li>
          <li>Alternatively, submit a pitch and contribute to Dataquest's blog!</li> 
          <li>Try to educate data science principles to your less tech-savvy relatives and family. It's remarkable how much teaching can aid comprehension.</li>
           <li>Make an effort to speak out during meetups.</li>
            <li>All of your analyses should be stored and shared on
GitHub.
</li>
      
      <li>Participate in online communities such as Quora,
Dataquest and the machine learning subreddit.
</li>
      
      </ul>
      <br></br>

<h3><b>Functions of Data Science
    </b></h3>
  
<p>Data Science is becoming increasingly important in organizations. According to one report, the global Data Science market is predicted to reach $115 billion by 2023. The following are only a few of the numerous Data Science's functions:
· Physicians utilize Data Science to analyze data from wearable trackers to secure their patients' well-being and make critical decisions in the healthcare industry. Hospital administrators can also use data science to minimize wait times and improve treatment.
· Data Science is used by retailers to improve customer experience and retention.
· For fraud detection, data science is frequently employed in the banking and financial industries.
Transportation providers use · Data Science to improve their customers' journeys. Transport for London, for example, maps passenger itineraries and provides customized transit specifics and manages unexpected scenarios with statistical data.
Construction organizations use · Data Science to make better decisions by measuring activities, such as average time to complete tasks.
· In the energy and utility arena, data science aids in the analysis of utility use. This research provides for better utility usage control and improved user feedback.
· Health-related research, financial market analysis, fraud detection, energy exploration, environmental protection, and other data science applications in the public sector are just a few examples.
 

</p>

<br></br>
<h3>

        <b>Learn Data Science with us, Techokids
</b>
      </h3> 
        
      
        
        <p>Parents are constantly looking for new ways to get their children interested in science, technology, math, or engineering (STEM). Whether it's legos for early engineering instalment, raspberry pi or Arduino for programming, or books and toys that excite interest in math and technology, there appears to be an abundance of things available for parents to convert their children into STEM superstars overnight.
 We provide personal support and analysis to your children in the same way that data scientists do. Yes, Data Scientist, your child could one day work as a data scientist.

 {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>

        <div className="text-center">
        <img className = "other-image" alt = "role of coding in data science" style = {{ maxWidth:700} }  src={Lifecyclebanner}></img>
      </div>
        <br></br>
        <h3>
            <b>
         
Conclusion

            </b>
        
        </h3>


<p>Many industries are being transformed by data science. It's all about getting to know your client and evaluating his behaviour by establishing data linkages that can lead to predictions about market trends and orientations. We are currently at an early level, which </p>
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

