// necessary imports
import React, { useState } from "react";
import "../../Styles/BookATrial/bookATrial.scss";
import WhiteLogo from "../../Assets/bookatrail/white-logo.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CardIcon1 from "../../Assets/bookatrail/card1.png";
import CardIcon2 from "../../Assets/bookatrail/card2.png";
import CardIcon3 from "../../Assets/bookatrail/card3.png";
import CardIcon4 from "../../Assets/bookatrail/card4.png";
import CardIcon5 from "../../Assets/bookatrail/card5.png";
import CardIcon6 from "../../Assets/bookatrail/card6.png";
import { Player, ControlBar } from "video-react";
import animationBooking from "../../Assets/bookatrail/book_trial_animation.mp4";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import LoginWithOTP from "./LoginWithOTP";
import Modal from "react-modal";
import { verifyCounsellingOTP } from "../../DataAccessObject/DataAccessObject";
import { useEffect } from "react";
import { getRefferalCode, getRefferalName } from "../Home/Helper";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// functional component
export default function Counselling(props) {
  const {
    parentName,
    setParentName,
    parentEmail,
    setParentEmail,
    phoneNumber,
    setPhoneNumber,
    studentName,
    setStudentName,
    grade,
    setGrade,
    parentNameError,
    setParentNameError,
    parentEmailError,
    setParentEmailError,
    parentContactError,
    setParentContactError,
    studentNameError,
    setStudentNameError,
    parentNameSuccess,
    setParentNameSuccess,
    parentEmailSuccess,
    setParentEmailSuccess,
    parentContactSucess,
    setParentContactSuccess,
    studentNameSuccess,
    setStudentNameSucess,
    onRegisterCounselling,
    setIsLoading,
    token,
    setItemIndex,
    modalOpen,
    modalClose,

    counsellorName,
    setCounsellorName,
    counsellorNameError,
    setCounsellorNameError,
    counsellorNameSuccess,
    setCounsellorNameSuccess,

    counsellingDateAndTime,
    setCounsellingDateAndTime,
  } = props;

  // item to be rendered

  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const [digit5, setDigit5] = useState("");
  const [digit6, setDigit6] = useState("");
  const [OTPerror, setOTPerror] = useState(false);

  // ******************************************
  // handler for login-with-otp form
  // ******************************************
  const onValidateOTP = function (event) {
    let digits = [digit1, digit2, digit3, digit4, digit5, digit6];
    let otp = "";
    for (let i = 0; i < digits.length; ++i) {
      let digit = digits[i];
      if (!("0" <= digit && digit <= "9")) return;
      otp += digit;
    }

    // make API request to verify token
    setIsLoading(true);
    verifyCounsellingOTP(parentEmail, parseInt(otp), token)
      .then((response) => {
        if (response.status === 200) {
          let localData = { email: parentEmail, token: response.data.token };
          localStorage.setItem("tk_jwt", JSON.stringify(localData));

          setItemIndex(1);
        } else {
          setOTPerror(response.data.message);
        }
      })
      .catch((err) => {
        let message = err.response.data.message;
        setOTPerror(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onCloseValidation = function (event) {
    setItemIndex(0);
  };

  const renderApply = () => {
    if (getRefferalCode() && getRefferalCode().toString().length >= 14) {
      return "Applied";
    } else {
      return "Apply";
    }
  };
  let history = useHistory();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="form-page-container">
      <div className="form-items-div">
        <div className="brand">
          <img
            className="brand-icon"
            src={WhiteLogo}
            alt="brandIcon"
            onClick={() => history.push("/")}
          />
        </div>
        <div className="form-container">
          <div className="form-header">Counselling session</div>
          <div className="form-panel">
            <div className="form-right-panel">
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Name*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter Parent's Name"
                      value={parentName}
                      onFocus={(event) => {
                        setParentNameError(false);
                        setParentNameSuccess(false);
                      }}
                      onChange={(event) => setParentName(event.target.value)}
                      name="name"
                      autoComplete="on"
                    />
                    {parentNameError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentNameSuccess && (
                      <span className="success ">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Email Id*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="email"
                      placeholder="Enter Parent's Email Id"
                      value={parentEmail}
                      onFocus={(event) => {
                        setParentEmailError(false);
                        setParentEmailSuccess(false);
                      }}
                      autoComplete="on"
                      name="email"
                      onChange={(event) => setParentEmail(event.target.value)}
                    />
                    {parentEmailError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentEmailSuccess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Parent's Contact*</label>
                  <div className="d-flex align-items-center">
                    <PhoneInput
                      placeholder="Enter Parent's Contact"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      onFocus={(event) => {
                        setParentContactError(false);
                        setParentContactSuccess(false);
                      }}
                      defaultCountry="IN"
                      name="phone"
                      autoComplete="on"
                    />
                    {parentContactError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {parentContactSucess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Student Name*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter Student's Name"
                      value={studentName}
                      onFocus={(event) => {
                        setStudentNameError(false);
                        setStudentNameSucess(false);
                      }}
                      name="sname"
                      onChange={(event) => setStudentName(event.target.value)}
                    />
                    {studentNameError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {studentNameSuccess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">Counsellor name*</label>
                  <div className="d-flex align-items-center">
                    <input
                      className="form-control inputFields"
                      type="text"
                      placeholder="Enter counsellor's Name"
                      value={counsellorName}
                      onFocus={(event) => {
                        setCounsellorNameError(false);
                        setCounsellorNameSuccess(false);
                      }}
                      name="cname"
                      onChange={(event) =>
                        setCounsellorName(event.target.value)
                      }
                    />
                    {counsellorNameError && (
                      <span className="error">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    )}
                    {counsellorNameSuccess && (
                      <span className="success">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">
                    Counselling date & time*
                  </label>
                  <div className="d-flex align-items-center">
                    <DatePicker
                      selected={counsellingDateAndTime}
                      onChange={(date) => setCounsellingDateAndTime(date)}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      isClearable
                      showYearDropdown
                      scrollableMonthYearDropdown
                      showTimeSelect
                      placeholderText="Select time and date"
                      closeOnScroll={true}
                      className="form-control inputFields"

                      // maxDate={new Date()}
                      // filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                    />
                  </div>
                </div>
              </div>

              <div className="form-inputs">
                <div className="form-group">
                  <label className="inputFieldLabels">
                    Select grade/class*
                  </label>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 1; i < 7; ++i) {
                        let item = (
                          <div
                            className={grade === i ? "selected-class" : ""}
                            onClick={(event) => setGrade(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 7; i < 13; ++i) {
                        let item = (
                          <div
                            className={grade === i ? "selected-class" : ""}
                            onClick={(event) => setGrade(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                </div>
              </div>

              <div className="form-footer-message">
                By submitting a request for a counseling session, you agree to
                our <Link to="/terms-conditions">Terms of Use</Link> and our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>

              <div className="book-free-class-container">
                <button
                  className="book-free-class"
                  onClick={onRegisterCounselling}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* cards container */}
      <div className="cards-container"></div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={modalClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <LoginWithOTP
          digit1={digit1}
          setDigit1={setDigit1}
          digit2={digit2}
          setDigit2={setDigit2}
          digit3={digit3}
          setDigit3={setDigit3}
          digit4={digit4}
          setDigit4={setDigit4}
          digit5={digit5}
          setDigit5={setDigit5}
          digit6={digit6}
          setDigit6={setDigit6}
          onValidateOTP={onValidateOTP}
          onCloseValidation={modalClose}
          OTPerror={OTPerror}
        />
      </Modal>
    </div>
  );
}
