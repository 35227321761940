import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import missionImg from "../../Assets/about_us/mission.png";
import vissionImg from "../../Assets/about_us/vision.png";
import LeftHalfCircleImage from "../../Assets/about_us/left-circle.png";
import CircularBarsImage from "../../Assets/about_us/circular-bars.png";
import robotImg from "../../Assets/about_us/robot.png";
import batteryImg from "../../Assets/about_us/battery-wire.png";
import "../../Styles/aboutus.scss";
import LSImg1 from "../../Assets/about_us/ls1.png";
import LSImg3 from "../../Assets/about_us/ls3.png";
import LSImg4 from "../../Assets/about_us/ls4.png";
import LSImg5 from "../../Assets/about_us/ls5.png";
import LSImg6 from "../../Assets/about_us/ls6.png";
import LSImg7 from "../../Assets/about_us/ls7.png";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Helmet } from "react-helmet";
import {
  lsSource,
  setDesktopViewLeft,
  setDesktopViewRight,
  setMobileViewLeft,
  setMobileViewRight,
} from "./data";
import AboutTeamMembers from "./AboutTeamMembers";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUs = () => {
  const [teamMembersLeft, setteamMembersLeft] = useState([]);
  const [teamMembersRight, setteamMembersRight] = useState([]);

  useEffect(() => {
    window.innerWidth <= "950" ? mobileOrder() : webOrder();
    handleResize();
  }, []);

  let body = document.getElementsByTagName("BODY")[0];
  let width = body.offsetWidth;
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleResize = () => {
    let bodyElement = document.getElementsByTagName("BODY")[0];
    let newWidth = bodyElement.offsetWidth;
    if (newWidth != width) {
      window.innerWidth <= "950" ? mobileOrder() : webOrder();
    }
  };
  const webOrder = () => {
    setteamMembersLeft(setDesktopViewLeft());
    setteamMembersRight(setDesktopViewRight());
  };
  const mobileOrder = () => {
    setteamMembersLeft(setMobileViewLeft());
    setteamMembersRight(setMobileViewRight());
  };

  return (
    <main className="aboutus__page">
      <Helmet>
        <title>Live Online Coding Classes For Kids |About Us |TechoKids </title>
        <meta
          name="description"
          content="TechoKids’ About Us will show you an overview of our vision, mission & beliefs of the successful leaders through Best Live Online Coding Classes For Kids. "
        />
        <link rel="canonical" href="https://www.techokids.com/about-us" />
        <meta
          name="keywords"
          content=" best online learning platform for kids, free 1:1 live coding classes for kids, best self-paced online coding sessions for kids in India, Online learning platform for kids in India, talented trained teachers, trained tutors, technical experts"
        />
      </Helmet>

      <Navbar />
      <div className="aboutus__background__img" />
      <section className="techokids__description">
        <img src={CircularBarsImage} className="circular-bars" alt="" />
        <img src={LeftHalfCircleImage} className="left-half-circle" alt="" />
        <div className="welcome">
          <h1>Welcome to TechoKids!</h1>
          <p className="about__separator">
            TechoKids is an
            <h4> online learning platform for kids of 6 to 18 years. </h4>
            21st-century new-age tech skills like Coding,{" "}
            <h5>
              <a href="/blog/artificial-intelligence">
                {" "}
                Artificial Intelligence{" "}
              </a>
            </h5>{" "}
            ,{" "}
            <h5>
              <a href="/blog/Robotics-for-kids"> Robotics </a>{" "}
            </h5>
            ,{" "}
            <h5>
              <a href="/blog/web-development"> Web Development</a>{" "}
            </h5>
            are in great demand. Kids need to learn these practical skills early
            on to prepare for future jobs.
          </p>
          <p className="about__separator">
            <h6>We offer live classes via 1:1 online sessions</h6> connecting
            leading expert teachers to kids across the globe. We want students
            to become future programmers, tech entrepreneurs who can build
            <h6>amazing games</h6>,<h6>apps</h6>, <h6>websites</h6>, and more.
          </p>
          <p className="about__separator">
            TechoKids offers an engaging, fun, and enjoyable tech curriculum,
            with <h3>self-paced online courses</h3> from the comfort of the
            home. The company is founded by alumni from Google, Facebook, IIT,
            IIM, MSU.
          </p>
          <p className="about__separator">
            Our courses are designed in such a way that the kid not only learns
            <h4>how to design</h4> or <h4>code</h4> but thoroughly understands
            the concepts to develop practical projects which enhances their{" "}
            <h4>critical thinking</h4>,<h4>problem solving</h4>,{" "}
            <h4>creativity</h4> and <h4>computational thinking</h4>.
          </p>
        </div>
        <div
          className="our__belief container"
          style={{ overflow: "hidden", height: "fit-content" }}
        >
          <h5 className="title">
            <h2 style={{ all: "unset" }}>Our Beliefs</h2>
          </h5>
          <p>
            Our focus is to provide world-class learning to kids and nurture
            their hidden potential talent for the future. Our product,
            curriculum, and teaching experience is crafted to complement school
            learning and fuel students' growth, and expand their natural
            abilities.
          </p>
          <p>
            In this 21st century, we want kids to get trained on the desired
            skills of the next generation, seek an extra edge in this
            competitive world. We envisage kids to be the next generation
            technology leaders, innovators, artists, entrepreneurs, and
            scientists.
          </p>
          <div className="misson__vision row">
            <div className="mission__section">
              <img src={missionImg} alt="" />
              <div className="mission__description">
                <h3>Our Mission</h3>
                <p className="desc">
                  Make students learn 21st century tech skills in their native
                  language with ease.
                </p>
              </div>
            </div>
            <div className="vision__section">
              <img src={vissionImg} alt="" />
              <div className="vision__description">
                <h3>Our Vision</h3>
                <p className="desc">
                  To provide affordable e-learning platform for every student.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our__team">
        <div className="slogan">
          <h2 className="our__team-battery">Is your learning battery low?</h2>
          <h2 className="our__team-recharge">Recharge with Our Team</h2>
        </div>
        <LazyLoadImage />
        <LazyLoadImage
          alt={"robotImg"}
          src={robotImg}
          //  width = "388" height = "465"
          effect="opacity"
          className="robot"
        />
        <LazyLoadImage
          alt={"battery"}
          src={batteryImg}
          effect="opacity"
          className="battery"
          // width = "5299" height = "7809"
        />
      </section>
      <section className="team_members">
        <div className="left__section">
          {teamMembersLeft.map((item) => {
            return <AboutTeamMembers item={item} />;
          })}
        </div>
        <div className="right__section">
          {teamMembersRight.map((item) => {
            return <AboutTeamMembers item={item} />;
          })}
        </div>
      </section>

      <section className="lightening__source">
        <img
          className="ls ls1"
          src={LSImg1}
          alt="design1"
          width="93"
          height="128"
        />
        <img
          className="ls ls3"
          src={LSImg3}
          alt="design3"
          width="188"
          height="196"
        />
        <img
          className="ls ls4"
          src={LSImg4}
          alt="design4"
          width="164"
          height="68"
        />
        <img
          className="ls ls5"
          src={LSImg5}
          alt="design5"
          width="153"
          height="81"
        />
        <img
          className="ls ls6"
          src={LSImg6}
          alt="design6"
          width="131"
          height="53"
        />
        <img
          className="ls ls7"
          src={LSImg7}
          alt="design7"
          width="59"
          height="70"
        />
        <h2>Lightning Source</h2>
        <p>
          We take pride in our highly talented and trained teachers who are also
          part of our curriculum design team. Our teachers are hand-picked and
          hired after intense scrutiny. They are incentivized to teach with our
          revolutionary methodology making it unique, hands-on, and
          comprehensive for the kids.
          <br />
          <br />
          Our teaching sparks curiosity, critical reasoning, computational
          thinking, and creativity. They continuously strive to bring out the
          genius in every kid.
        </p>
        <div className="lightening__source__grid">
          {lsSource.map((source) => (
            <div key={source.text} className="lightening__source__card">
              <img
                src={source.img}
                alt={source.text}
                width={source.width}
                height={source.height}
              />
              <br />
              {source.text}
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default AboutUs;
