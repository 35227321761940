import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import codingmyth from "./../../Assets/Blogs/codingmyth.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Codingmyth = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        Top 5 Coding Myths Vs. Facts For Early School-Age Children
        </h1>
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={codingmyth}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
If you are enthusiastic about coding and want to learn more about it, you might have done a google search before. Undoubtedly, you happened to witness many facts and figures. But how do you know whether the points are right or wrong? Maybe you have even done a fact-check from other sites, but what's the guarantee of those? Hence, to obtain a better understanding, one needs the right platform to get the correct coding facts. The portal has listed some myths and debunked them for kids to get rid of the wrong ideas. To know what those are, keep reading!


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Top 5 Coding Myths Vs. Facts </b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    Fasten your seat belts and get ready for an extraordinary journey! Here are the top 5 myths accompanied by real facts. Have a look. 
</p>
<ol>
    <li><p><b>
How can anyone write that much code! I can't. 
</b></p>
You don't need to write pages of code. What you need to write is just a clean piece of code. It might look like a tedious task, but the reality is different. The moment you understand how the functions, boolean expressions, or algorithms work, it would be easy for you to write them down. It's not a havoc task. Keep the code clean and understandable for everyone. These are only a combination of figures and some coding rules which one needs to understand. The rest would be done automatically. 


</li>
<li><p><b>
I must be an adult to start learning programming languages!  
</b></p>
No, not! It would be best if you were least bothered about the age. The earlier you start, the better. One could start from the age of six as the brain would help create different thinking and aptitude aspects. It would help the kids learn better and understand better. By the time they reach 18, they would already be in hold of the advanced languages. Coding facts are necessary to debunk as you have seen that misconceptions about the programming world might make you lose some great opportunities.

</li>
<li><p><b>
I need to go to University to learn to code.</b></p>

This is again a significant misconception about coding. In this fast-paced world, where you have access to thousands of resources, why limit yourself? Grab a book, play some coding games, read online, start online classes! There are plenty of options to check out. It would be best if you have the patience to learn and explore the mammoth world of coding. 
Moreover, coding is not limited to computers. You could code mechanisms and robotics too. With online aids like <a href = "https://techokids.com/">Techo Kids</a> you would learn things slowly and steadily without any hassles. You need to understand the fundamental concepts and then start trying them out on your own. 

</li>

<li><p><b>

We must be a genius of IQ 160
</b></p>

You must have heard from elders that coding needs a high IQ level. The real coding facts state that it is false. Why? Let's take an assumption. A 7-year-old kid, venturing into the coding world. What would he probably know about coding? Nothing right? He would need to garnish himself and clear out basics. Eventually, he would be introduced to text-based codes and more complex problems. So, it is a step-by-step process. Anyone who can operate a computer to do a task can learn how to code. Taking small steps is essential. You can't become a programmer in one night. It comes with several trials and errors. You need patience and consistency to become what you want to be and learn what you need to. 



</li> 
   
<li><p><b>

I have to master Mathematics to learn to code.
</b></p>

Undoubtedly, your elders might have told you about the importance of mathematics in this subject. Well, you need to know about introductory algebra, and that's it. You don't need to master the subject to learn to code. The libraries and plug-ins would automatically guide you with mathematical and algorithmic problems. This is one of the most crucial coding facts that one must clear out. Many individuals often find themselves inside a soup, thinking coding is not for them as they are not so good at mathematics. No worries, you can always opt for the subject without worrying about math. Just know the primary operators and algebra; the rest would be taken care of by the software or compilers themselves. 


</li>

    
    </ol>

<p>In a nutshell, one needs to know that coding is not as complicated as it seems. Like it has been told since the classical times - Never judge a book by its cover! Coding is an exciting subject, and hence debunking trivial myths and getting the right coding facts would help clear out misconceptions. Now that you know what is right and wrong, what are you waiting for? Grab a chance today with Techo Kids for a free trial and queries. To keep up with the subject's updates and snippets, follow us on Twitter, Facebook, Linked In, and Instagram! </p>

<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

