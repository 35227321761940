import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import stressmanagement from "./../../Assets/Blogs/stressmanagement.jpg";

import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Stressmanagement = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        How/What are the ways to teach kids about Stress Management?</h1>
              </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
 
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={stressmanagement}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        Stress may not be a significant factor for young kids. A six-year-old child may not understand the meaning or effect as well. However, it is essential to note that they go through minute stress, which often gets ignored. The question you might ask is, what pressure would a mere seven-year-old go through? They haven’t seen the world yet.

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <p>Well, here is where you are mistaken. The cause of stress might not be similar to yours, but they feel pressure when the child has to adapt to something or change. The causes of stress could be many, including a family member’s death, illness, learning something new, and much more. So, one needs to teach their child how to deal with it healthily. The portal is here to guide you on how to teach kids about stress management. To learn more, keep reading. 
</p>
        <h3>
        <b>
Note These Before Getting Started
 </b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out. 
</p>
<h3>
    
What Are The Different Types of Stressors?

</h3>
<h3>
<p>How Can Parents Help Their Children Deal With Stress?</p></h3>

<ul>
    <li>
    Firstly, make sure the atmosphere at home is safe and sound. Avoid fighting in front of your kids as it might affect them negatively. Teach them more about love and affection. 


</li>
<li>
Don’t let your little buddy sit in one place throughout the day. Ask them to play or do some physical activities. It will help keep them active and boost their strength as well. Don’t let them hang on with mobile phone games as using such devices for too long would make them addicted to such items, which could be detrimental to their mental health. 

</li>
<li>
Don’t forget to spend time with your children. They need you as you are the only ones they can rely on in this cruel world. Never let them feel they are alone. 

</li>

<li>
Why not watch an animated movie together? It could be a movie for children as well. This could act as a great stress reliever and one of the best ways on how to teach kids to deal with stress. 
</li> 
   
<li>
Keep a note of the shows and things your child watches. If they are watching the news or crime series or genres like this, it could increase your child’s stress level. 


</li>
<li>
If you have a job where you need to move frequently, let your child know about it. Sudden news about changing houses or schools might make them sad and build pressure within. 


</li>
    
<li>
Don’t criticize your child all the time and listen to their pleas. Solve their problems in the right way and in a way that would make them feel guilty or wrong. There is always a positive way to deal with things. 

</li>    
<li>
Please encourage your child and appreciate them. If they are drawing something, then show your interest in it. Ask them about the process. For example, ask them, “how did you do this” “This looks so beautiful! Would you teach me too?”. These are simple sentences that could affect a child’s mind positively. 
 
</li> 
<li>
Sometimes, let them make their own decisions. If it’s wrong, the child will come to know by themselves.  
</li> 

<li>
Lastly, your child might be a little shy. Try talking to them and recognize the unresolved issues or factors of stress. 
 </li> 

    </ul>

<h3>
Symptoms Of Stress In Your Child
</h3>
<p>
The symptoms can be both physical and emotional. So look out for all the signs. These might include frequent nightmares, headaches, decreased appetite, stomach upset, anxiety, fear, clinging onto parents, not letting go, anger, crying, aggression, and even not participating in the family or school activities. 
</p>

<p>In a nutshell, parents need to look after the stress factor in their child. Ignoring mental health can affect the mental growth of your child. Due to this reason, such children grow up to be depressed or aggressive teenagers. Above were the points about how to teach kids to deal with stress management. Age doesn’t matter when it comes to someone’s mental well-being. Check out the symptoms. Is your child suffering from any? Follow us on Twitter, Facebook, Linked In, and Instagram to stay updated! </p>
<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

