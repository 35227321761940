import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import onlinecovid19 from "./../../Assets/Blogs/onlinecovid19.jpg";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Onlineeducation = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content">
        How has Online Education/E-learning been immensely helpful during COVID-19? 
      </h1>
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          {/* <div>
            <AccountCircleIcon
              style={{
                fontSize: "350%",
              }}
            />
          </div> */}
           <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
  
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={onlinecovid19}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
We all know the impact of the deadly COVID-19 it has on our lives. It has lead to a drop in the speed of the global economy. Every country is suffering from the sudden and abrupt changes it had to make. Think about it. No one knew this would happen, and no nation was ready for this. Millions got affected, and thousands died. Individuals are vastly affected due to such situations and have lead to widespread bustle. It affected not only humans but also industries, businesses, and economies.

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        <p>
        Nowadays, the availability of various online courses has increased, which will help children to expand their educational scope. For instance, if they want to explore something that hasn't been taught to them in school, like coding, they will have to look out for other alternative means. Virtual learning for kids is a worthy and remarkable possibility that will allow students to explore more than a regular course. 
        </p>
        <p>
        Let’s have a more comprehensive look at it. The healthcare sector was suddenly affected by it. There was an overload of work on doctors and the infrastructure as well. Moreover, the pandemic slowed down the manufacturing of essential goods and resulted in losses for businesses. Social events got delayed and disrupted, and people got quarantined. Schools, colleges, offices got closed to slow down or stop the spread of the corona-virus. People suffered mentally and emotionally, as well. With such adverse effects, the government soon took out solutions for conducting education as it shouldn’t be compromised. 
</p>
<p>So, the solution that they came up with was E-learning. Classes resumed through a digital medium. Now let’s have a more in-depth look inside e-classes. Even though it was not something new, but the action became widespread. Previously, you must have attended international webinars or video calls with your relatives. The same technique was used in a broader sense. But with it came troubles like time management, internet connection problems, and others. However, today we will focus only on how online education helped students during the pandemic situation. 
</p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>How E-classes helped students during a pandemic?
</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        
        We all know about the traditional chalk and board system of studying at schools or colleges. With the rise in technology, digital boards and lite pens became common. But what are the advantages of e-classes conducted online? Undoubtedly, e-learning is beneficial as students do not have to follow strict protocols and study at their comfort. However, it comes with an excellent self-discipline need. So, why wait? Let’s get started. 
</p>

<h3>
        <b>1. Reduces harmful impact on the environment</b>  </h3>
      <p>Think about it. Isn’t it true? Students do not need to travel or avail of any transport services. Moreover, with e-learning, one barely needs a notebook. They do not need to keep a notebook for each subject. All their notes are stored in their laptops or pcs. So, there is less emission of CO2 in the environment and saves paper as well. Thus, it is an eco-friendly method of studying. So, in the time of pandemic situations, it did help the environment in varied ways. The sky was more transparent, and the air was fresher with lesser pollutants. 
</p>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        
      <br></br>
      <h3>
          <b>
          2. It is highly cost-effective.</b>
      </h3>
        <p>
     
        Students do not need any travel expenses. They do not need to spend on traveling or even on their extra notebooks. They might need to buy some books online, but it would still come at a lower cost than on the campus. E-learning does not involve extra materials, trainers, or maintenance, as almost everything is available online. Schools do not need to pay bus fees or carpools, and they do not need to spend extra money on uniforms or bags or badges. They do not have to pay hostel or canteen fees as well. Moreover, it is highly profitable for schools and colleges as well as they have to invest less. In such situations as a pandemic, the e-classes did save costs for students and businesses as well. 

</p>

         <br>
         </br> 
         <h3>
        <b>3.
Access to real-time courses
</b>
      </h3>    
         
         <p>
       
         One does not need to rush to classes to attend lectures. For instance, a pupil might have an emergency but fail to do the needful as they need to physically present in the study. However, from the perspective of an e-class, a student can attend the lecture anywhere and anytime. The same applies to the pandemic situation as well.

</p>
        
  <h3>  <b>4. Online education and lockdown
</b></h3>
<p>
Undoubtedly, students got more than enough time to pull up their socks and head on a beautiful journey. There are hundreds of online platforms to help students aid their skills and knowledge. The fact is that they pursued other online courses along with their regular school or college e-classes. This wouldn’t have been done so quickly if it were physical classes. They would have to travel, sit in the study, follow protocols, work, get tired, and come home. However, back at home, they do not need to travel much. Everything is sorted out in front of their screen. Hence, they get enough time to start a side course and help themselves to grow their career. 

</p>
<h3>
<p>5. Consistency of classes</p>
</h3>

<p>
Individuals often used to miss classes. However, this stopped with e-classes as the students did not need to rush to the colleges. No matter what, they could sit anywhere and attend the class. This ensured that they didn’t miss any type, nor did they rush for notes much.
</p>


        <p>
        What do you think about e-classes? How did online learning help you? Reach us out at TechoKids and let us know about your story. E-learning has undoubtedly been a hit in this pandemic situation. Not only did it help students, but it did help the analytical aspects of schools and colleges too. Follow us on Twitter, Facebook, Linked In, and Instagram to stay updated! 
</p>

        
            
        


<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
"
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

