import React from "react";
// import "../../Styles/BookATrial/loginWithOTP.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// functional component
export default function PricingModal(props) {
  const { modalClose, onDownloadCurriculum } = props;

  return (
    <div className="login__with__otp__page">
      <div className="login__with__otp__form-container">
        <div className="close-button-container">
          <button onClick={modalClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <form onSubmit={(event) => onDownloadCurriculum(event)}>
          <div className="form-group">
            <label for="exampleInputEmail1">Full Name</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your full name"
            />
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <input
              type="email"
              className="form-control"
              id=""
              aria-describedby="emailHelp"
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label for="exampleInputEmail1">Phone Number</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter your phone number"
            />
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Download Brochure
          </button>
        </form>
      </div>
    </div>
  );
}
