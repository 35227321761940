import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import { useHistory } from "react-router-dom";
import js1 from "./../../Assets/Blogs/js1.jpg";
import codingjavascript  from "./../../Assets/Blogs/codingjavascript.png";
import jsgetstarted  from "./../../Assets/Blogs/jsgetstarted.png";
import jsextra  from "./../../Assets/Blogs/jsextra.png";

import { Helmet } from "react-helmet";
import Footer from "./../../Components/Footer";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
export const Javascript = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title>   JavaScript for Kids |How to Get Started with JavaScript | Techokids </title>
      <meta 
      name ="description" 
      content = "JavaScript is mainly used to create attractive web pages and applications. All you have to do to explore a given map is click and drag with your mouse. "/>
  <link rel = "canonical" href = "/blog/Javascript"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
        <h1 className="heading-content ">
        JavaScript for Kids
        </h1>

        
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
          
            <div  className="d-flex flex-column inner-blogs-name">
                <div>
              <b>Deepali Meena</b>
            </div>
            <div>30 May 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img   className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>
                 </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img  style = {{maxWidth:850}} alt  = "Java Script for kids" className="main-content-image" src={js1}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
        <b>
        Introduction
     </b>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      
        <p>
        JavaScript is a widely used interpreted scripting language that, as of early 2019, had overtaken Python as the most commonly learned programming language. JavaScript is an open standard that isn't controlled by a single vendor, with a wide range of implementations and an easy-to-learn syntax that appeals to both novice and experienced programmers.
JavaScript is a scripting language, as its name suggests. Traditional languages, such as C++, are compiled before being run into executable binary form, with the compiler evaluating the entire program for faults before completing the process. On the other hand, scripting languages are performed one line at a time by an interpreter, which is a separate program. Scripting languages began as a set of shell commands used to run other programs. Still, their flexibility and simplicity of use made them a popular form of programming language in their own right, and they became particularly essential with the emergence of the Internet.


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Reasons for Learning JavaScript </b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
     
      <ul>
      <li>  
      The most widely used programming language is JavaScript.
The most important reason is at the top of our list! JavaScript is the most popular programming language used by professional developers today, according to Stackoverflow.com. JavaScript is frequently used by back-end developers as well.
</li>
<li>
It's beginner-friendly.
Beginning programmers that want to broaden their skillset into other languages should use JavaScript. Object-oriented, functional, and imperative programming techniques are all supported by JavaScript. Beginner programmers can then use these abilities to learn any new language they wish, such as Python, Java, or C++.
</li>
<li>
 JavaScript is a versatile scripting language.
JavaScript is far from a one-trick pony; it allows a coder to handle any aspect of app creation. For example, do you wish to code on the user's side (front-end)? That's possible to do it with Angular. Do you prefer the server-side (back-end) to the client-side (front-end)? If you add Node.js to the mix, you'll be able to do it as well.
</li>
<li>
 It's an Excellent Resource for Game Designers.
Today's games are big business, and developers who know JavaScript have a leg up. The language's flexibility, strength, and ease of creating visual effects make it ideal for game creators.
</li>
</ul>
</p>
<br></br>
<h3>
        <b>
        Coding plays a Vital Role in JavaScript
</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <br></br>
      <div className="text-center"> 
       <img width="601" height="380" className = "other-image" src={codingjavascript} >
         </img> 
      </div>
        <br></br>
        <p>
        JavaScript's function is to manage the state and update the user interface. However, we've learned that the appearance of a modern website is determined by its condition, which can lead to an almost infinite number of variants. So the question remains: Who or what is in charge of the state's management?
Behind the scenes, a program monitors all of your actions on the website and updates the state accordingly. This software is developed in JavaScript, the web's global language.
Adding interactivity to websites—
yep, you'll need to do some JavaScripting if you want a website to be more than a static page of text.
App development for mobile devices—
Websites aren't the only place where you may find JavaScript... It's also used to make the apps that you have on your phone and tablet.
Developing games for the web browser—
Have you ever played a game straight from your browser? That was most likely made possible by JavaScript.
JavaScript is most commonly employed on the front end of things, but it's a versatile enough scripting language to be utilized on back-end infrastructure as well.


</p>         
<br>
         </br> 
         <h3>
        <b>How to Get Started with JavaScript</b>
      </h3>    
       <br></br>
        <div className = "text-center">
          <img  style = {{maxWidth:800}} src = {jsgetstarted} className  = "other-image" alt = "how to get started with javascript" ></img>
          </div> 
        <br></br>
        <p>
        What's the Takeaway from this? While HTML and CSS allow you to create a basic web page, JavaScript is the programming language that will enable you to bring that page to life. Even though HTML and CSS alone will qualify you for entry-level developer work, investing the effort to master JavaScript can vastly improve your job prospects.
        <ul>
            <li>Write stuff! Create a Google App Engine account and begin developing real web apps with proper CRUD functionality.</li>
          <li> STAY AWAY FROM JQUERY. Try to write javascript with Jquery as much as possible. Jquery is an overly complex API, and you'll waste a lot of time understanding it rather than javascript. document.querySelector() would suffice.</li>
        <li>Put all of your code on GitHub and attempt to get people/friends who are wiser than you to read it and advise.</li>
        <li>Seek failure and continue to grow!
        </li>
        </ul>
        </p>
      
      <br></br>
      
    

<h3><b>What does JavaScript have to offer?
    </b></h3>
    <br></br>
<div className = "text-center">
<img className = "other-image" src = {jsextra} />

</div>
<br></br>
<p>
    <ul>
    <li>JavaScript is mainly used to create attractive web pages and applications. Google Maps is an example of this. All you have to do to explore a given map is click and drag with your mouse.And what kind of language could accomplish this? You guessed correctly! It's a JavaScript script. </li>
<li>Smartwatches may use JavaScript as well. Pebble, a famous smartwatch manufacturer, is one example of this. Pebble has produced Pebble.js, a tiny JavaScript Framework that lets developers create a JavaScript application for the Pebble watch line.
</li>
<li>JavaScript is used to develop the most prominent websites, like Google, Facebook, Netflix, Amazon, etc. </li>
<li>JavaScript is used to create games and mobile applications, digital art, web servers, and server applications. Many developers use JavaScript to develop small-scale games and apps.
</li>
</ul>
</p>

<br></br>
<h3>

        <b> Learn JavaScript with us Techokids
</b>
      </h3> 
        
        
        <p>Parents are constantly looking for new ways to get their children interested in science, technology, math, or engineering. JavaScript is an excellent choice for beginners. JavaScript is a perfect choice for beginners. JavaScript saves novices the trouble of setting up a development environment because it is already installed on every web browser. Newcomers that are your children can jump right in and begin coding. It's one of the most user-friendly ways to get started with coding, and it's an excellent resource for novices. In addition, there is a sizable online community, i.e., Techokids, that provides support and assistance. We provide personal support and analysis to your children.  
 {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
        <br></br>
        <h3>
            <b>
         
Conclusion

            </b>
        
        </h3>


<p>JavaScript is a fantastic web tool to employ. It is not difficult to learn and is quite versatile. It's compatible with other web technologies like HTML and CSS, and it can even work with plugins like Flash. We can leverage JavaScript to create highly responsive user interfaces, avoid annoying page reloads, and even resolve CSS support difficulties. You may even utilize JavaScript to make online systems available offline and sync automatically once the machine is online with the correct browser add-ons (such as Google Gears or Yahoo Browser Plus). JavaScript isn't just for browsers, either. Compared to other languages, JavaScript's speed and tiny memory footprint open up a slew of new possibilities, ranging from automating repetitive chores in applications like Illustrator to employing it as a server-side language with its parser. The future is a blank slate.
</p>
{/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

