import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import imagenew from "./../../Assets/Blogs/imagenew.png";
import "./content.css";
import { useHistory } from "react-router-dom";
import whykidslearn from "./../../Assets/Blogs/whykidslearn.png"; 
import Footer from "./../../Components/Footer";
import { Helmet } from "react-helmet";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Content2 = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title>  Coding For Kids – 10 Things Parents Need To Know Right Now
        </title>
       <meta 
      name ="description" 
      content = "Wondering what you need to know to help your child learn more about coding? Here are the top 10 things that you should take a look at the benefits of learning to code."/>
   
  <link rel = "canonical" href = "/blog/Coding-for-kids-10-Things-Parent-Need-To-Know"/>
   <meta 
  name = "keywords" 
  content = " Coding For Kids, School-Level Coding, Fun Starts With Coding, Coding is for all ages"/> 
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img style = {{cursor:"pointer"}}
        onClick = {()=>history.push("/blog")} 
        className="content-img" src={Logo} alt="logo"></img>
       <div className="heading-content " > 
       <h1 className="heading-content " >
        Coding For Kids – 10 Things Parents Need To Know Right Now
        </h1>
         </div> 
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          {/* <div>
            <AccountCircleIcon
              style={{
                fontSize: "350%",
              }}
            />
          </div> */}
          <img  alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "50" height = "50" src = {KunalBasantaniImg}></img>      
        
          <div  className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>12 November 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>        </div>
      </div>
      

	{/* <img width="750" height="509" src="http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To.jpg" class="attachment-full size-full wp-post-image" alt="Coding For Kids – 10 Things Parents Need To Know Right Now" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To.jpg 2500w, http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To-300x175.jpg 300w, http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To-1024x598.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To-768x448.jpg 768w, http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To-1536x896.jpg 1536w, http://blog.techokids.com/wp-content/uploads/2021/01/1-Coding-For-Kids-10-Things-Parents-Need-To-2048x1195.jpg 2048w" sizes="(max-width: 2500px) 100vw, 2500px"/> */}
    
  <div className="text-center">
        <img alt = "coding for kids - things parents should know" className = "main-content-image" style = {{maxWidth:850}} src={imagenew}></img>
      </div>
      {/* <div className="text-center">
      <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px">
        </img>
        {/* <img className="main-content-image" src={MainContentImage}></img> */}
      {/* </div> */} 
      <div className="para-div ">
      {/* <h2>
      <b>Coding For Kids – 10 Things Parents Need To Know Right Now</b>
      </h2> */}
      <br></br>
      {/* <h3>
        <b>Why Coding For Kids Is Important?</b>
      </h3> */}
        <p>
        <div class="entry-content clr" itemprop="text">
	
<h3><b>10 Things Parents Need To Know About Coding For Kids</b></h3>



<p style = {{}}>With the advent of the digitalized 21st century, it might be hard to cope with regular innovations and trends. To aid your child’s advancement personally and in their academic field, you might want to reconsider what the appropriate thing to do in such times is? Guess what? Introducing your child to the coding world would be a boon in this fast-paced technological world. Even if their age is two, you could provide them with standard technical toys and help them gain interest in this field. However, keep this quick tip in mind, don’t force your child to learn it. Let it grow slowly and steadily, and eventually, they will opt for the subject they want to dig in. There are a plethora of things that parents need to know about coding for kids. The portal is curated especially for all the parents to obtain better knowledge about coding with kids.</p>


<br></br>

<h3><b>The Top 10 Things About Coding For Kids</b></h3>



<p style = {{}}>Wondering what you need to know to help your child learn more about coding? Here are the top 10 things that you should take a look at.</p>


<br></br>
<h4>1.&nbsp;<strong>Innumerable Benefits</strong></h4>
<p style = {{}}>Yes, you read that right. There are tons of benefits of learning to code if one starts at an early age. Most of the adults know there had been a time where one was skeptical about starting. Allow them to start coding at an early age. It will help them increase their computational abilities and logical thinking.</p>
<br></br>
<h4>2. <strong>The Right Age To Start </strong></h4>
<p style = {{}}>You would be happy to hear that there is no right age to start. The earlier they start, the better. Coding for kids is necessary as it would help nourish their creativity and confidence. Introduce your child to coding puzzles and toys, to begin with. Once one reaches the age of seven, they could also try their hands on block programming. Try out <a href="https://scratch.mit.edu/" target="_blank" rel="noreferrer noopener"><u>Scratch</u></a>&nbsp;and let them savor the taste of the coding world.</p>
<br></br>
<h4>3. <strong>Availability Of Countless Resources</strong></h4>
<p style = {{}}>One shouldn’t worry about the availability of resources. You could easily avail the books and even applications available on android and iOS devices. Applications like <a href="https://spritebox.com/" target="_blank" rel="noreferrer noopener"><u>Spritebox</u></a>, Codemancer, and even websites like <a href="https://code.org/" target="_blank" rel="noreferrer noopener"><u>Code.org</u></a>&nbsp;and few others would aid in coding for kids<strong>. </strong></p>
<br></br>
<h4 style  = {{cursor:"pointer", color:"indigo"}} 
onClick = {()=>history.push("/blog/5-reasons-why-coding-is-important-for-kids")}>4. <strong> Why Does Your Kid Need To Learn To Code?</strong></h4>
<br></br>
{/* <figure class="wp-block-image size-large is-style-default"><a href="https://teachyourkidscode.com/why-coding-is-important-to-learn/" target="_blank" rel="noopener" class="no-lightbox"><img src="https://teachyourkidscode.com/wp-content/uploads/2018/08/why-kids-should-learn-to-code-.png" alt="Why Does Your Kid Need To Learn To Code?" title="Why Does Your Kid Need To Learn To Code?" style = {{width: 700,height:400}}/></a></figure> */}
 <div className="text-center"> 
    <img  alt = "Why does your kid need to learn to code " style = {{maxWidth:700}} width = "800"className="main-content-image" src={whykidslearn}></img>
      </div> 
      <br></br>
        
<p style = {{}}>If you want your child to open up more scope in their future and help cut out the rat race, coding is a possible way out. With every rising day, the need for software developers, web developers, engineers for robotics is increasing. There are plenty of opportunities and potential opportunities to come. All one needs to do is explore them and start with the endeavor. Moreover, there are numerous <a href="https://teachyourkidscode.com/best-coding-apps-for-kids/" target="_blank" rel="noreferrer noopener">coding for kids apps</a><strong> </strong>to help children create their journey.</p>

<br></br>

<h4>5.&nbsp;<strong>What Should You Know Before Letting Them Into The World&nbsp;Of Coding?</strong></h4>



<p style = {{}}>Halt before you let them venture into the world and read these quick tips that you need to know. Firstly, coding should be fun, and hence, try to make it entertaining to them as much as you can. Age 7 would be the right age to opt for online or local classes. With <a href="https://techokids.com/" target="_blank" rel="noreferrer noopener"><u>TechoKids</u></a>, your child would be able to grasp the basics and advanced level coding quickly.</p>

<br></br>

<h4>6. <strong>Type of Computer That They Might Need</strong></h4>



<p style = {{}}>You might be wondering about what are the investments that you need to make for such a subject. The good news is that coding for kids&nbsp;comes for much less than you can imagine. Why? There are plenty of free courses and games available on the internet. Simple coding board games and toys come at a mere price too. Classes could be done on the phone again. A laptop with 4GB RAM&nbsp;and a good internet connection would help learn professional codes and languages. You could start with computer games and help them with the journey.</p>

<br></br>

<h4>7. <strong>Type Of Language One Needs To Focus On</strong></h4>



<figure class="text-center"><img className = "other-image" 
alt = "type of language one needs to focus on"
src="https://www.typesnuses.com/wp-content/uploads/different-programming-languages.png" alt="Programming Languages Of Coding For Kids" title="Programming Languages Of Coding For Kids"/></figure>
<p style = {{}}>There are several languages to focus on. JavaScript still holds the first position, followed by python and Java. There are more languages that they would be able to focus on. Let them start it slowly with simple games and eventually turning it into a passion that would help them in the future.</p>

<br></br>

<h4>8. <strong>What About The School-Level Coding?</strong></h4>
<p style = {{}}>Undoubtedly, school-level coding does help them and helps fill the gaps and join the dots. However, school courses don’t provide the right language to help them in their career. They miss out on the tools and resources as well. If your kid gets a chance to learn coding on a one-to-one basis, it will help them in varied ways. Coding for kids is fun and shouldn’t be boring.</p>
<br></br>
<h4>9. <strong>Is It All About Computers?</strong></h4>
<p style = {{}}>Here comes the common misconception about coding. Individuals believe coding is all about computers. The fact is coding is about your daily appliances, too, or any device you take a look at. Don’t forget robotics!</p>

<br></br>

<h4>10. <strong>The Fun Starts With Coding</strong></h4>



<p style = {{}}>Coding is in no way tedious. These are entirely based on logic and brains. It does require practice, but the ultimate result is rewarding. Think about it! It all starts with a simple board game and then advances.</p>

<br></br>

<h2><b>Final Words</b></h2>



<figure class="text-center">
  <a href="https://www.techokids.com/#/aboutus" target="_blank" rel="noopener"  ><img className ="other-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRle7gi0JMvI0I0bBNJ2yfKHdOaf11uBYVNFg&amp;usqp=CAU" alt="TechoKids - Coding For Kids" title="TechoKids - Coding For Kids"/></a></figure>



<p style = {{}}>With all that being said, what do you think about&nbsp;coding for kids?<strong>&nbsp;</strong><a href="https://techokids.com/" target="_blank" rel="noreferrer noopener"><u>TechoKids</u></a>&nbsp;welcomes everyone on the platform for robust learning for all ages. Fasten your seat belts and witness your child fly up in this fast-paced world with the right guidance and opulent opportunities. Coding is for all ages, and the earlier one starts, the better. So, why wait? Grab a chance today!</p>



<p style = {{}}><a href="https://techokids.com/book-trial" target="_blank" rel="noreferrer noopener"><u>Book A Free Trial Now!</u></a>&nbsp;Follow us on&nbsp;<a href="https://twitter.com/techokids" target="_blank" rel="noreferrer noopener"><u>Twitter</u></a>,&nbsp;<a href="https://www.facebook.com/TechoKids" target="_blank" rel="noreferrer noopener"><u>Facebook</u></a>,&nbsp;<a href="https://www.linkedin.com/company/53192460/admin/" target="_blank" rel="noreferrer noopener">Linked In</a>,&nbsp;and&nbsp;<a href="https://www.instagram.com/techokids/" target="_blank" rel="noreferrer noopener"><u>Instagram</u></a>&nbsp;to stay updated! Find us on <a href="https://m.dailyhunt.in/news/nepal/english/connect+gujarat+english-epaper-cgujeng/vadodara+startup+techokids+all+set+to+give+children+a+unique+learning+platform-newsid-n232625268" target="_blank" rel="noreferrer noopener">Dailyhunt</a>.</p>

</div>

        {/* Being in the 21st century and amidst an unexpected lockdown, 
        parents are finding it difficult to engage their kids in fun activities. 
        If you are a parent who falls under this category, I am sure you can relate
         to the struggle of keeping your kids actively involved daily. Not to worry! 
         You have reached the perfect place because we have the ideal solutions for you. 
         Yes, it’s CODING! Wait, what? I know you must be thinking Coding for kids sounds strange
          but teaching Coding to them is even more bizarre.
          {/* Coding is also known as computer programming. It is done by writing */}
          {/* computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p> 
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:430}}>          – Sachin Bansal */}
          
        {/* </h4>
        <br>
        </br>
        <p> */}
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
          {/* As adults, when we hear the word ‘coding’, our mind automatically imagines endless lines of code running on a huge screen, and people lamenting that coding is tough. It is not always like that. Coding for kids is an amusement and fun-loving. We can prove all these myths like ‘I have to go to University to learn Coding’ and ‘We must be genius with an IQ of 160’ wrong for all the right reasons. */}
          {/* </p>
        <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg">
        <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px">
        </img>
        <p>
        Coding is just a set of instructions given to a computer to do what we want it to do. It is used to create games, websites, apps, etc. In today’s digital world, every single digital application or website we use runs on coding functionalities. Coding for kids has become very necessary in the 21st Century. 
         </p>
         <br> */}
         {/* </br> 
         <p>
A simple game you play has a set of commands running in the background, providing you a seamless experience. Besides, Coding is today’s creative language and every child deserves a chance to become a creator. It also offers your kids an advantage when it comes to college admissions, employment, etc.

Okay, so why Coding for kids is so important to learn at a younger age? 
        </p>
        <h4>
           */}
          {/* “I thought I could make my own games. I wanted to see how games work, that’s what led me to learn how to program computers.”
         
          <br></br>
         
          – Elon Musk
         
        </h4>

        <p>
        Learning to code is like learning a language, the earlier the better. It helps to acquire logical thinking and problem-solving ability from a young age, which will undoubtedly help in improving their academic performance additionally.


        </p>
        <p>It doesn’t mean that your kids should learn to code just because they feel bored. There are so many other reasons why it’s a profitable idea for your kids to learn to code.</p>
        <p>First of all, learning to code is fun!</p>
        <p>Coding for kids is like solving puzzles… It gives excitement and ultimate confidence to children when they see how their code is functioning that makes something work. The sheer joy that a child gets when they see their creation, is purely amusing and refreshing. Let’s say, your child creates a game through coding. When they see the game running, it gives them happiness and enthusiasm to create more games through coding. They will start to enjoy coding, exploring, and creating different applications.</p>
        <img style = {{marginLeft:70}}loading="lazy" src="https://images.squarespace-cdn.com/content/v1/56e460d7b09f956469fa7154/1565111323207-YZ1AMHQFSGY4M1T2EY8Q/ke17ZwdGBToddI8pDm48kHldqyjDwaeS7kYSmaCmglZ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmusP150YD-QtWqScwdS_85tP8joIlojSVzXUcummRsz2cF0ZJEme1wcEnRHcGyqT5/illustration_andre_levy_zhion_vector_outline_minimal_playegg_startup_spain_educational_toys_drawing_imagination_creativity_kid_creating_animation.gif" alt="Creativity" width="500" height="500" title="Creativity"></img>
        <p>
        Mostly, our children are taught to memorize and learn things in a certain way. Coding for kids helps to learn self-control regarding their thinking processes, allowing them to unleash their creativity and experimentation with different theories & concepts. Even though your child doesn’t aspire to be a coder in the future, problem-solving skills are gonna needful everywhere. It helps them face problems in the real world through their different perspectives. */}
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        {/* </p>
        <h4>Problem-solving</h4>
     <br></br>   
<h4>
        “Everyone in this world should learn how to program because it teaches you how to think.”
<br></br>

– Steve Jobs

</h4>
<p>Problem-solving skills are one of the most valued and essential skills that everyone must possess. Coding for kids drills them down to stop looking for the problem and finding a solution instead. It enhances computational thinking which promotes the ability to solve a problem in a systematic approach.</p>
 <h4>Critical Thinking</h4>   
 <p>This ability helps to make rational decisions under critical situations. Coding requires these skills to solve the existing problems analytically. A problem may have multiple solutions that enable every child to think logically and choose the perfectly-fitted solution to the problems. Yes, Coding for kids is damn important for their IQ and sharpness level.</p>  
   <h4>Confidence</h4>   
  <p>
   <img src="https://cdn.shortpixel.ai/client/to_webp,q_glossy,ret_img/https://www.wedevelopyou.com/wp-content/uploads/2019/05/cant-700x467.png" alt="Confidence" title="Confidence">
     </img>
</p> */}

     {/* <p>Confidence is a key trait for every success. Coding involves learning interests and curiosity which manifestly make kids more captured into their daily activities. How the kids gain confidence? </p>
      <p>When they see the results of their creation’s implementation. It boosts their confidence and self-esteem, fostering the idea that they can achieve anything.</p>
      <h4>Teamwork</h4>
      <p><a href="http://blog.techokids.com/index.php/2020/12/20/5-reasons-why-coding-is-necessary-for-kids-in-21st-century/" target="_blank" rel="noreferrer noopener">Coding for kids</a> can make a community and connect themselves to solving others’ problems and finding solutions by building a team through cooperation and collaboration. It imparts the idea of working together from an early stage which encourages them to interact with new people and make new worthy contacts – an influential skill required everywhere.</p>
      <p>Now that you have read about why your child should learn to code/why Coding for kids is important, how will you make that happen?</p>
      <p>We both know that you are looking at a flexible solution for you!</p>
      <p>We at TechoKids offer 1:1 interactive coding classes where your child can easily and effectively learn coding, from anywhere in the world.</p>
      <p>Read our trained kids’ feedbacks and what their parents think about us, on <a href="https://techokids.com/" target="_blank" rel="noreferrer noopener">our website</a>.&nbsp;</p>
      <p>Great. Now that you have visited our platform so we are assuming you have also gone through our online coding courses. If not, give a look at them and see what&nbsp;contents are covered in our curriculum through our 1:1 Live Online Coding Courses.</p>
      <p>Now, what are you waiting for?</p>
      <a href="https://www.techokids.com/#/book-trial" target="_blank" rel="noreferrer noopener">Book your first&nbsp;Free Online Coding Trial Demo Now!</a>
      <img loading="lazy" src="http://blog.techokids.com/wp-content/uploads/2020/12/TechoKids-Final-Logo-2-1024x285.png" alt="TechoKids Logo" class="wp-image-66" width="512" height="143" title="TechoKids "></img>
    
      <h4 style = {{marginTop:40}}>“Instead of buying your children all the things you never had, you should teach them all the things you were never taught.”<br></br>– Bruce Lee&nbsp;</h4>
      
      <h3 style = {{marginTop:20, marginBottom:10}}>Final Words</h3>
      <p>We really hope you loved our first blog which focused on Why Coding for kids is so damn important! Kindly express your views and suggestions in the below-mentioned comment box and if you want us to write on a specific title, feel free to write it below. </p>
      <p>We are really looking forward to connecting with you on <a href="https://www.facebook.com/TechoKids" target="_blank" rel="noreferrer noopener">Facebook</a>, <a href="https://instagram.com/techokids" target="_blank" rel="noreferrer noopener">Instagram</a>, <a href="https://www.linkedin.com/company/techokids/" target="_blank" rel="noreferrer noopener">Linked In</a>, and <a href="https://twitter.com/TechoKids" target="_blank" rel="noreferrer noopener">Twitter</a>. Also, you can find us at <a href="https://m.dailyhunt.in/news/nepal/english/connect+gujarat+english-epaper-cgujeng/vadodara+startup+techokids+all+set+to+give+children+a+unique+learning+platform-newsid-n232625268" target="_blank" rel="noreferrer noopener">Dailyhunt.</a></p>
      </div>
      {/* <div className="text-center"> */}
        {/* <img className="animation" src={AnimationBlog} alt="animation"></img> */}
      {/* </div>  */}
    </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
    </>
  );
};
