import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import { useHistory } from "react-router-dom";
import cpp1 from "./../../Assets/Blogs/cpp1.jpg";
import Footer from "../../Components/Footer";
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
import cppfeatures from "./../../Assets/Blogs/cppfeatures.png";

export const Cpp = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> C++ Programming Language For Kids | Complete Guide | Techokids
      </title>
      <meta 
      name ="description" 
      content = "To learn the C++ language, one has to be thorough with the concepts of coding. To start from scratch, you can always take coding classes with more than one course. "/>
  <link rel = "canonical" href = "/blog/Cpp"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}

  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}} >
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
         <h1 className="heading-content ">
          C++ Programming Language 
          </h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img alt = "deepali meena"  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
            
          <div  className="d-flex flex-column inner-blogs-name">
             <div>
              <b>Deepali Meena</b>
            </div>
            <div>20 May 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
               <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>  
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>
           
       </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <div className = "text-center">
        <img alt = "C ++ programming language" className="main-content-image" src={cpp1} style = {{maxWidth:850}}></img>
        </div>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
     
      
        <p>
        Programming languages have got a lot of competitors over the past few years. Python being one of the easiest, is chosen by many programmers to develop and create websites and games. But, we often forget that C + + has been the most successful language that can compile codes together. 
It is a three-step process that goes behind developing a wholesome idea through language. The process is commenced by:


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        <ul>
            <li>Source Code </li>
            <li>Compilation </li>
            <li>Machine Code </li>
        </ul>
        To learn the C++ language, one has to be thorough with the concepts of coding. To start from scratch, you can always take coding classes with more than one course. Techokids is an online platform that lets you experiment with many classes right from the starting point. However, knowing how to code is the first step to initiating any course or a programming language.
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is C++ Important?</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
         C++ brings you a bag of features and tools that can make your work easy. With new technology being introduced every day, it is crucial to classify the needs of programming languages according to the stuff you are working on. 
Here are a few features that will help you decide why you should choose C++.

</p>
<div className = "text-center">
<img  style = {{maxWidth:700, marginLeft:-10}} className = "main-content-image" src = {cppfeatures} alt = "features of c++" />
</div>
<br></br>
<p><b>Easy to work with-</b></p>
<p>Beginners can quickly take up C++ as a course if they are familiar with coding. The language is easy to catch up on and provides a variety of data types. The language is loaded with features, and let's experiment with different tools and more. You can apply your programmer's knowledge while creating games, apps, etc. You can code on a simple syntax structure as that of the language C.</p>
<p><b>Works rapidly-</b></p>
<p>C++ is one of the speedy languages as the programs created through the same work rapidly. It is based on a procedural matter and follows a three-step process. As C + + has been running in the market for a long time now, it does not have in-built materials as other languages do. This is one of the reasons that it works faster. 

</p>
<p><b>Object-oriented-</b></p>
<p>C++ is highly object-oriented and lets you execute large file programs. This helps to maintain the files in proper conditions and use less space on the device. The only disadvantage you face is maintaining the procedural code as the code grows according to the programs.</p>
<br></br>
<h3>
        <b>How Coding Helps In C++</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        {/* <div className="text-center">
        <img style = {{marginRight:40, borderRadius:20} } width = "600" src={einstientk}></img>
      </div> */}
      
        <p>
        As mentioned earlier, coding is the first step to learning a new programming language. Therefore it is clear that if you want to learn C++ from scratch, try learning basic codes. This will not only boost your confidence but also will let you experiment with other parts of coding. 
With the increase in technology, the reasons to learn C++ have also paced up. It allows you to experience various features such as:

<ul>
    <li>
    Efficient memory management and pointers
    </li>
    <li>
        Java 
    </li>
    <li>
    Meta-programming

    </li>
    <li>
    Compile-time and load time 

    </li>
    <li>
    Optimization  
    </li>
    <li>
        
    Dynamic libraries   

    </li>
    <li>
        
       Generic programming 
    
        </li>
</ul>
</p>        
 <br>
         
         </br> 
         
<h3>
        <b>C++ And Techokids</b>
      </h3> 
        
        
        <p>
        Python and C++ are known to be the language leaders in the percentage of people who have learned them. However, when it comes to the sheets, more and more people are interested in learning the languages for future use. Techokids, being an online learning platform, brings the same to those interested. 
Designing a website is as exciting as it sounds. Therefore, the platform brings excitement to your doorstep by offering you the courses through which you can learn new coding concepts, understand their use and work, and qualified mentors who are experts in this field.
 {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
        <br></br>
        <h3>
            <b>
            Conclusion
            </b>
        
        </h3>


<p>Programming languages vary according to the applications or websites one needs to create. For huge ones, you need to apply multiple or complicated wording, and if you want to make some short websites, even the basics of language work. Techokids have plans from basics to advanced for all those who want to start from scratch. 
This opens the gates to hundreds of opportunities, and at the end of the day, you can have the satisfaction of creating something new. It also sharpens your mind and lets you think in a completely different manner. 
</p>
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

