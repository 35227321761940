import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import python1 from "./../../Assets/Blogs/python1.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop"; 
import python12 from "./../../Assets/Blogs/python12.png";
import pythonhelp from "./../../Assets/Blogs/pythonhelp.png";

export const Python = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Python Programming for Kids  | Python For Beginner | Techokids
      </title>
      <meta 
      name ="description" 
      content = "Coding is fun when you know Python. Coding refers to the instruction given to the computer and Python is a language, adds words to the same for users to interpret it quickly. "/>
  <link rel = "canonical" href = "/blog/5-reasons-why-coding-is-important-for-kids"/>
  <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" />
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
       <h1 className="heading-content "> PYTHON - PROGRAMMING
       </h1>

        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  style = {{borderRadius:"50%"}} width = "60" height = "60" src = {deepali}></img>      
          
            <div  className="d-flex flex-column inner-blogs-name">
              <div>
              <b>Deepali Meena</b>
            </div>
            <div>2 June 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>     </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img style = {{maxWidth:800}} alt = "Python Programming for kids" className="main-content-image" src={python1}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
        <b>
          Introduction
    </b>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      
        <p>
        Python is one of the famous programming languages to be learned by coders. It is applied in data sciences, web designing, and creating prototype documents. For those new to coding and programming, Python is the first step to learning it, but those who know even the slightest thing about coding can easily catch up on Python. 
Python works on multiple platforms, including Linux, Raspberry, Pi, Mac, and Windows, and coding through the same turns out more manageable than other languages. Its syntax is similar to English. Here a lot of python programming questions pop up as to what can Python do? 
The answer to the same is that it helps in creating websites. It can join database systems together and perform complex functions like calculations and solving math problems. 


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is Python Important?</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <div className = "text-center">
          <img src = {python12} className ="other-image" alt = "Python">
          </img>
        </div>
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    Over the years, Python has become one of the easiest languages to learn, and with massive popularity, it is the most learned amongst the coders. It is easily accessible and has a simple syntax which demands more people to learn it. 
Python is the one language you can experiment with while coding. The codes typed through Python are easy to read and simplify, making it the first choice of the coders.
The language has been in the market for more than 25 years now and has grown a community for itself, making it more engaging. 
Thousands of documents are written through Python, which makes it tried and tested a million times. It is also one of the main reasons it is taught in schools and colleges and used in research projects. 
Python has a vast community,, whichh makes it easier for those who are new to this language to get help whenever they are stuck somewhere. As compared to other languages, Python is highly appreciated for:
<ul>
    <li>Efficiency</li>
    <li>Readability</li>
    <li>Speed</li>
    <li>Versatility</li>
    <li>Flexibility</li>
    
</ul>
When we say flexibility, here, it means that Python does not restrict creating any applications. You can create games, educational platforms, websites for medical use, etc. You can make tons of wonders. 
</p>
<br></br>
<h3>
        <b>
        How does Python help In coding?
</b>
      </h3>
      <br></br>
      <div className = "text-center">
      <img className = "other-image" src= {pythonhelp} alt = "how does python help in coding" />
      </div>
      <br></br>
        <p>
        Coding is easy and fun when you know Python. Coding refers to the instruction given to the computer, and Python is a language, adds words to the same for users to interpret it quickly. 
With new technologies introduced every day, it is a plus point for python beginners to interpret the code and learn. Having a mentor here makes the task less complicated, and therefore many platforms have started online coding classes to prepare students for a challenging future.
Coding, being divided into LINE BASED and BLOCK BASED, requires proper knowledge of languages to bifurcate and learn. 
For those who are interested in learning Line-based Coding, Python works best. 
Just like learning french or german, Python requires dedication. You should invest proper time in learning, and with an increase in demand for coders, Python is a blessing one can have. 

</p>         
<br>
         </br> 
         <h3>
        <b>Python For Beginners In 3 Easy Steps</b>
      </h3>    
        <p>STEP 1: Understanding Data Types</p>

         <p>The first step to learning python is by gripping data types. Having a solid grip on data types makes the work a lot easier than imagined. Python holds on to line-based coding and therefore deals with numbers, variables, and boolean codes. 
It sharpens your ability to calculate faster and improves logical thinking and reasoning. 
</p>  
<p>Step 2: Knowing the basic syntax</p> 

        <p>
        The next step is to understand the syntax of the code, and it can do this only through Python. 
To go through the basics of the same two python books are must-reads.
 <ul>
            <li>Learn Python the hard way- In here, the authors have made sure that students understand the basic concepts of Python easily and no one leaves unlearned about the syntax and the formulas of it. </li>
          <li>The Python tutorial- This is another book that is very helpful for beginners. Here students can start from the start line, learn the concepts, understand the syntax and reach the endpoint.</li>
      
        </ul>
        what can learn more syntax by working on web development. The more you work on projects, the more you get the idea of decoding the codes.
        </p>

          <p>Step 3: Start working on projects.</p>
          <p>To get experience on how Python works, you need to work on it yourself. Creating apps is not difficult when you have the gist of Coding and Python. Start by taking baby steps before jumping into massive projects. Initiate your journey by creating games, short stories, and more. 
This will also help you in understanding how specific platforms work. For python beginners, this is the best way of having targets for creating huge apps. 
</p>
      
      
      <br></br>
    

<h3>


        <b>Techokids And Python
</b>
      </h3> 
        
        
        <p>With the world coming online, students need to be prepared for what’s coming. In the next 10 to 20 years, artificial intelligence will dominate, causing a lot of machine work. Therefore various companies have come up with online learning platforms where students can learn coding and be ready for the tech world. 
Techokids is also one wherein students train, learn to code and interact in 1:1 live sessions. 
Python is one crucial part of the online platform. Students having even the slightest knowledge of coding can learn Python and create their websites. Having a mentor can help them go the right path and learn Python faster than usual. Students can take up free and certified demo classes to learn the basics of Python, and once they are comfortable, they can book regular slots. 
Techokids, founded by Google, IIT, and IIM alumni, and learning from such a privileged platform add feathers to the cap of achievements. 
{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
        <br></br>
        <h3>
            <b>
         
Conclusion

            </b>
        
        </h3>


<p>Python is a skill that students should learn at a young age. To conquer the techno world, coding is the key. It is a subject understood by not many people, but geniuses and Techokids make sure you are one of them. Their 1:1 live session and hackathons encourage children to learn more about it, create apps, design websites, and ace fields like data sciences. If you are one of those brilliant in the making, then book a slot with them now!</p>
{/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

