import React from "react";
import "../Styles/homepage-feedback.scss";
import Smiley from "../Assets/homepage-feedback/smiley.png";
import Star from "../Assets/homepage-feedback/star.png";
import GoogleIcon from "../Assets/homepage-feedback/google-icon.png";
import AmericaIcon from "../Assets/homepage-feedback/america.png";
import TeacherIcon from "../Assets/homepage-feedback/teacher.png";
import DollarIcon from "../Assets/homepage-feedback/dollar.png";
import CountryIcon from "../Assets/homepage-feedback/country.png";
import PCIcon from "../Assets/homepage-feedback/pc.png";
import IndiaIcon from "../Assets/homepage-feedback/india.png";

export default function HomepageFeedback(/*props*/) {
  return (
    <div className="homepage__feedback">
      <h2 className="homepage__feedback__header md_h2">
        Kids Love Us & See How We Are The Most Trusted Platform For
        <span> Parents & Experts!</span>
      </h2>
      <div className="homepage__feedback__cards__wrapper">
        <div className="homepage__feedback__cards__container">
          <div className="slot1">
            <div className="row1">
              <div className="card1">
                Parent & Student Happiness Index
                <img src={Smiley} alt="smiley" width = "350" height = "350" />
              </div>
              <div className="card2">
                Transparent Tracking of Child's Progress
              </div>
            </div>
            <div className="row2">
              <div className="card1 google__card">
                <p>
                  My 2 boys have been on the programming course for the past few
                  months and i can say they have learnt a lot of new stuff like
                  HTML and CSS to create a basic website which is very
                  impressive. The boys love Priya who is their Tutor as she very
                  friendly and knows how to get them interested in the course. I
                  will definately recomend Techokids for anyone who wants to
                  expose their Kids to programming.
                </p>
                <div className="google__card__fotter">
                  <div>
                    <img src={Star} alt="starImg" width = "350" height = "350" />
                    <img src={Star} alt="starImg" width = "350" height = "350" />
                    <img src={Star} alt="starImg" width = "350" height = "350" />
                    <img src={Star} alt="starImg" width = "350" height = "350" />
                    <img src={Star} alt="starImg" width = "350" height = "350" /> <br />
                    <img
                      className="google__icon"
                      src={GoogleIcon}
                      alt="googleIcon"
                      width = "350" height = "350"
                    />
                  </div>
                  <div className="author">
                    Adeola Ajiboye
                    <br />
                    <div>
                      <img
                        className="country__icon"
                        src={CountryIcon}
                        alt="countryIcon"
                        width = "183" height = "114"
                      />
                      Father
                    </div>
                  </div>
                </div>
              </div>
              <div className="card2">
                <p>
                  Child will be given exposure to master practical skills,
                  concepts and build real world strengths
                </p>
                <div>
                  <img src={PCIcon} alt="pcIcon" width = "350" height = "350" />
                </div>
              </div>
            </div>
            <div className="row3">
              <div className="card1 google__card">
                <p>
                  Best Educational platform to enhance coding knowledge for
                  every child. A professionally well-managed company.
                </p>
                <div className="google__card__fotter">
                  <div>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/> <br />
                    <img
                      className="google__icon"
                      src={GoogleIcon}
                      alt="googleIcon"
                      width = "350" height = "350"
                    />
                  </div>
                  <div className="author">
                    Sushanth Pujari
                    <br />
                    <div>
                      <img
                        className="country__icon"
                        src={IndiaIcon}
                        alt="countryIcon"
                        width = "183" height = "114"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card2 google__card">
                <span>
                  <p>
                    Well, as for it's my concern, my brother has been loving it
                    so far and the results are good too. So, yes my brother has
                    been taking a class from them for 2 months, he had developed
                    an interest in coding himself. And we did our part of the
                    research and Techokids stood to all our expectations.
                  </p>
                </span>
                <div className="google__card__fotter">
                  <div>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/>
                    <img src={Star} alt="starImg" width = "350" height = "350"/> <br />
                    <img
                      className="google__icon"
                      src={GoogleIcon}
                      alt="googleIcon"
                      width = "350" height = "350"
                    />
                  </div>
                  <div className="author">
                    Nandini Patel
                    <br />
                    <div>
                      <img
                        className="country__icon"
                        src={AmericaIcon}
                        alt="countryIcon"
                        width = "182" height = "114"
                      />
                      Sister
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slot2">
            <div className="card1 google__card">
              <p>
                My son recently joined the class and it's helping him a lot as
                during pandemic it was a tough time to learn from offline mode.
                Techokids has helped him in his learning from home and the
                support from the staff n all teachers is commendable. Kudos to
                the team!
              </p>

              <div className="google__card__fotter">
                <div>
                  <img src={Star} alt="starImg" width = "350" height = "350"/>
                  <img src={Star} alt="starImg" width = "350" height = "350"/>
                  <img src={Star} alt="starImg" width = "350" height = "350"/>
                  <img src={Star} alt="starImg" width = "350" height = "350"/>
                  <img src={Star} alt="starImg" width = "350" height = "350"/> <br />
                  <img
                    className="google__icon"
                    src={GoogleIcon}
                    alt="googleIcon"
                    width = "350" height = "350"
                  />
                </div>
                <div className="author">
                  Patricia cassatt <br />
                  <div>
                    <img
                      className="country__icon"
                      src={AmericaIcon}
                      alt="countryIcon"
                      width = "183" height = "114"
                    />
                    Mom
                  </div>
                </div>
              </div>
            </div>
            <div className="card2">
              <span>Not copy-paste</span> <br />
              but Personalized as per student's need
            </div>
            <div className="card3">
              Teacher's Quality Review
              <img src={TeacherIcon} alt="teacherIcon" width = "349" height = "343"/>
            </div>
            <div className="card4">
              100% refund policy <br />
              <div>
                <div>
                  what you see is
                  <br />
                  what you get
                </div>
                <div>
                  <img src={DollarIcon} alt="dollarIcon" width = "350" height = "350"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
