import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./payment.css";
import { Link } from "react-router-dom";
import logo from "../../Assets/navbar/logo.png";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { validateEmail } from "../BookATrial/BookTrialContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  loadScript,
  GenerateOrderID,
  verifyPayment,
  verifyCoupon,
} from "./PaymentHelper";

function PaymentForm(props) {
  let location = useLocation();

  const [parentName, setparentName] = useState("");
  const [parentNameError, setParentNameError] = useState(false);
  const [parentNameSuccess, setParentNameSuccess] = useState(false);

  const [parentNumber, setparentNumber] = useState("");
  const [parentNumberError, setParentNumberError] = useState("");
  const [parentNumberSuccess, setParentNumberSuccess] = useState(false);

  const [parentEmail, setparentEmail] = useState("");
  const [parentEmailError, setParentEmailError] = useState(false);
  const [parentEmailSuccess, setParentEmailSuccess] = useState(false);

  const [studentName, setstudentName] = useState("");
  const [studentNameError, setStudentNameError] = useState(false);
  const [studentNameSuccess, setStudentNameSuccess] = useState(false);

  const [studentClass, setstudentClass] = useState("");
  const [countrycode, setcountrycode] = useState("+91");
  const [couponcode, setcouponcode] = useState("");

  const [savedAmount, setsavedAmount] = useState(null);
  const [price, setPrice] = useState(null);
  const [discountPrice, setdiscountPrice] = useState(
    parseInt(location.state.discountPrice.replace(",", "").replace(",", ""))
  );
  const [oldDiscount, setoldDiscount] = useState(0);
  const [valid, setValid] = useState(false);

  const [paymentID, setPaymentID] = useState("");

  let history = useHistory();
  // console.log("Location",location);
  // setdiscountPrice(parseInt(location.state.discountPrice.replace(",", "").replace(",", "")));
  useEffect(() => {
    setPrice(parseInt(location.state.price.replace(",", "").replace(",", "")));
  }, []);

  useEffect(() => {
    setsavedAmount(price - discountPrice);
  }, [price, discountPrice]);

  let symbol = location.state.symbol;
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const DisplayGrade = () => {
    if (location.state.grade === 0) {
      return "Grade 1-3";
    } else if (location.state.grade === 1) {
      return "Grade 4-7";
    } else {
      return "Grade 8-12";
    }
  };
  let grade = DisplayGrade();
  let Package = location.state.package;

  const onCouponApply = (code) => {
    if (code == "") {
      alert("Enter Coupon");
      return;
    }
    console.log("Coupon", code);

    verifyCoupon(code, Package).then((res) => {
      if (res.error) {
        alert(res.error);
      } else {
        setValid(true);
        var amount = res.amount;
        var type = res.type;
        if (type == "Percentage") {
          var save = Math.ceil((discountPrice * amount) / 100);
          setdiscountPrice(discountPrice - save);
        } else {
          setdiscountPrice(discountPrice - amount);
        }
      }
    });
  };
  const onPaymentSubmit = async () => {
    const price = discountPrice;
    // console.log(grade,Package,price,parentName,studentName,studentClass,parentEmail,parentNumber)
    validation();
    //  console.log(studentNameSuccess,parentEmailSuccess,parentNameSuccess,parentNumberSuccess);
    //  console.log(studentNameError,parentEmailError,parentNameError,parentNumberError);
    if (
      studentNameError ||
      parentEmailError ||
      parentNumberError ||
      parentNameError
    ) {
      return;
    }
    if (
      !parentNumber ||
      !studentName ||
      !parentName ||
      !parentEmail ||
      !studentClass
    ) {
      return;
    }

    if (couponcode != "") {
      if (valid == false) {
        alert("Coupon not valid");
        return;
      }
    }
    GenerateOrderID(
      grade,
      Package,
      price,
      studentName,
      parentEmail,
      studentClass,
      parentNumber,
      couponcode
    )
      .then((order) => {
        if (!order) {
          alert("Something Went Wrong in ordering");
          return;
        }
        displayRazorpay(order);
      })
      .catch((err) => {
        console.log("error at ordeing", err);
      });
  };

  async function displayRazorpay(order) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    //console.log("order amount", order.amount);
    const options = {
      key: process.env.REACT_RAZOR_PAY_KEY,
      currency: "INR",
      order_id: order.id,
      name: studentName,
      description: Package + " " + grade,
      handler: async function (response) {
        console.log("response of razorPay ", response);
        const data = {
          orderCreationId: order.id,
          email: parentEmail,
          number: parentNumber,
          parentName: parentName,
          studentName: studentName,
          grade: studentClass,
          price: order.amount,
          Package: Package,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          couponcode,
        };
        const result = await verifyPayment(data);
        //alert(result.data.msg);
        alert(result.message);
      },
      prefill: {
        name: parentName,
        email: parentEmail,
        contact: parentNumber,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const validation = () => {
    if (parentName.trim() === "") {
      setParentNameError(true);
    } else {
      setParentNameSuccess(false);
    }
    if (!validateEmail(parentEmail)) {
      setParentEmailError(true);
    } else {
      setParentEmailSuccess(false);
    }
    if (!isValidPhoneNumber(parentNumber)) {
      setParentNumberError(true);
    } else {
      setParentNumberSuccess(false);
    }
    if (studentName.trim() === "") {
      setStudentNameError(true);
    } else {
      setStudentNameSuccess(false);
    }
  };
  return (
    <div className="wrapper container-fluid payment">
      <div className="header brand">
        <img
          className="logo"
          src={logo}
          onClick={() => history.push("/")}
        ></img>
      </div>
      <div className="jumboCard">
        <h1 className="text-center mb-5">PAYMENT REQUEST</h1>
        <div className="containerRow row">
          <div className="cardCol col-lg-12 col-xl-6">
            <div className="amountCard card">
              <div className="card-body">
                <div className="row">
                  <div className="d-flex justify-content-between w-100 payment_plan">
                    <label>{location.state.package} Plan</label>
                    <label className="ml-auto">
                      {symbol} {price}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-between w-100 payment_price_cut">
                    <label>{DisplayGrade()}</label>
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }} className="row">
                  <label className="inputFieldLabels">
                    Do you have a coupon code?
                  </label>
                  <br />
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      value={couponcode}
                      onChange={(e) => {
                        setcouponcode(e.target.value);
                        setValid(false);
                        setdiscountPrice(
                          parseInt(
                            location.state.discountPrice
                              .replace(",", "")
                              .replace(",", "")
                          )
                        );
                      }}
                      className="form-control"
                      placeholder="Enter Coupon Code "
                      aria-label="Coupon Code Input"
                      aria-describedby="button-addon2"
                      style={{ borderColor: "rgba(0,0,0,0.5)" }}
                    />
                    <button
                      className="btn "
                      type="button"
                      class="button-addon2"
                      onClick={() => onCouponApply(couponcode)}
                      disabled={valid}
                      style={{ background: "#5DB11D", color: "white" }}
                    >
                      {valid ? <span>Applied</span> : <span>Apply</span>}
                    </button>
                  </div>
                </div>
                <div
                  style={{ fontWeight: "600", marginTop: "1rem" }}
                  className="row"
                >
                  <label>Total Amount Payable</label>
                  <label className="ml-auto">
                    {" "}
                    {symbol} {discountPrice}
                  </label>
                </div>
                {/* <div style={{ color: "#5DB11D" }} className="row">
                  <label>
                    {" "}
                    Total Savings {symbol} {savedAmount}{" "}
                  </label>
                </div> */}
              </div>
            </div>
          </div>
          <div className="formCol col-lg-12 col-xl-6">
            <label
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              We need your details to get started
            </label>
            <form className="form ">
              <div className="form-group">
                <label className="inputFieldLabels">Parent’s Name* </label>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Parent’s Name"
                    type="text"
                    className="form-control inputFields"
                    value={parentName}
                    onFocus={(event) => {
                      setParentNameError(false);
                      setParentNameSuccess(false);
                    }}
                    onChange={(e) => setparentName(e.target.value)}
                  />
                  {parentNameError && (
                    <span className="error">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  )}
                  {parentNameSuccess && (
                    <span className="success ">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="inputFieldLabels"> Parent’s Email Id* </label>
                <div className="d-flex align-items-center">
                  <input
                    type="email"
                    placeholder="Enter Parent’s Email Id"
                    required
                    className="form-control inputFields"
                    value={parentEmail}
                    onFocus={(event) => {
                      setParentEmailError(false);
                      setParentEmailSuccess(false);
                    }}
                    onChange={(e) => setparentEmail(e.target.value)}
                  />
                  {parentEmailError && (
                    <span className="error">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  )}
                  {parentEmailSuccess && (
                    <span className="success">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="inputFieldLabels"> Parent’s Contact* </label>
                <div className="d-flex align-items-center">
                  <PhoneInput
                    value={parentNumber}
                    onChange={setparentNumber}
                    onFocus={(event) => {
                      setParentNumberError(false);
                      setParentNumberSuccess(false);
                    }}
                    defaultCountry="IN"
                    placeholder="Enter Parent’s Contact"
                  />
                  {parentNumberError && (
                    <span className="error">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  )}
                  {parentNumberSuccess && (
                    <span className="success">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label id="inputFieldLabels">Student Name* </label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Enter student's name"
                    className="form-control"
                    value={studentName}
                    onFocus={(event) => {
                      setStudentNameError(false);
                      setStudentNameSuccess(false);
                    }}
                    onChange={(e) => setstudentName(e.target.value)}
                  />
                  {studentNameError && (
                    <span className="error">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>
                  )}
                  {studentNameSuccess && (
                    <span className="success">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                  )}
                </div>
              </div>
              <div className="form-inputs">
                <div className="form-input-group">
                  <div>Select grade/class*</div>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 1; i < 7; ++i) {
                        let item = (
                          <div
                            className={
                              studentClass === i ? "selected-class" : ""
                            }
                            onClick={(event) => setstudentClass(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                  <div className="classes-row">
                    {(function () {
                      let items = [];
                      for (let i = 7; i < 13; ++i) {
                        let item = (
                          <div
                            className={
                              studentClass === i ? "selected-class" : ""
                            }
                            onClick={(event) => setstudentClass(i)}
                            key={i}
                          >
                            {i}
                            <sup>th</sup>
                          </div>
                        );
                        items.push(item);
                      }
                      return items;
                    })()}
                  </div>
                </div>
              </div>
              <div className="form-footer-message">
                By Processing a request for a payment, you agree to our{" "}
                <Link to="/terms-conditions">Terms of Use</Link> and our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn pay_btn btn-info expand"
                  onClick={onPaymentSubmit}
                >
                  PROCEED TO PAY
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
