import AmbreenFatima from "../../Assets/about_us/lsource/AmbreenFatima.jpeg";
import AnushkaKasera from "../../Assets/about_us/lsource/AnushkaKasera.jpeg";
import AyushDixit from "../../Assets/about_us/lsource/AyushDixit.jpeg";
import HimanshuVerma from "../../Assets/about_us/lsource/HimanshuVerma.jpeg";
import JayVerma from "../../Assets/about_us/lsource/JayVerma.jpeg";
import KItishreePatro from "../../Assets/about_us/lsource/KItishreePatro.jpeg";
import NirmalaT from "../../Assets/about_us/lsource/NirmalaT.jpeg";
import SaumyaAwasthi from "../../Assets/about_us/lsource/SaumyaAwasthi.jpeg";
import SouravKumar from "../../Assets/about_us/lsource/SouravKumar.jpeg";
import SwathiR from "../../Assets/about_us/lsource/SwathiR.jpeg";
import VattamSowmya from "../../Assets/about_us/lsource/VattamSowmya.jpeg";

import sonveerDescImg from "../../Assets/about_us/sonveer_desc.png";
import sonveerImg from "../../Assets/about_us/sonveer.png";
import nimishDescImg from "../../Assets/about_us/nimish_desc.png";
import nimishImg from "../../Assets/about_us/nimish.png";
import abhinavKanagatDescImg from "../../Assets/about_us/abhinav_kanagat_desc.png";
import abhinavKanagatImg from "../../Assets/about_us/abhinav_kanagat.png";
import pallavDescImg from "../../Assets/about_us/pallav_desc.png";
import pallavImg from "../../Assets/about_us/pallav.png";
import kunalDescImg from "../../Assets/about_us/kunal_desc.png";
import kunalImg from "../../Assets/about_us/kunal.png";
import abhinavWaliaDescImg from "../../Assets/about_us/abhinav_walia_desc.png";
import abhinavWaliaImg from "../../Assets/about_us/abhinav_walia.png";
import karanDescImg from "../../Assets/about_us/karan_desc.png";
import karanImg from "../../Assets/about_us/karan.png";
import KunalBasantaniDescImg from "../../Assets/about_us/kunalBasantani_desc.png";
import abhishekDeskImg from "../../Assets/about_us/abhishek_desc.png";
import abhishekImg from "../../Assets/about_us/abhishek.png";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ProfileFooterImg from "../../Assets/about_us/profile_footer.png";

export const lsSource = [
  { img: AmbreenFatima, text: "Ambreen Fatima", width: "247", height: "235" },
  { img: AnushkaKasera, text: "Anushka Kasera", width: "266", height: "384" },
  { img: AyushDixit, text: "Ayush Dixit", width: "419", height: "432" },
  { img: HimanshuVerma, text: "Himanshu Verma", width: "276", height: "278" },
  { img: JayVerma, text: "Jay Verma", width: "1280", height: "926" },
  {
    img: KItishreePatro,
    text: "K Itishree Patro",
    width: "233",
    height: "259",
  },
  { img: NirmalaT, text: "Nirmala T", width: "290", height: "301" },
  { img: SaumyaAwasthi, text: "Saumya Awasthi", width: "351", height: "351" },
  { img: SouravKumar, text: "Sourav Kumar", width: "328", height: "400" },
  { img: SwathiR, text: "Swathi R", width: "370", height: "482" },
  { img: VattamSowmya, text: "Vattam Sowmya", width: "310", height: "360" },
];

export const teamMembers = [
  {
    bgImg: sonveerDescImg,
    img: sonveerImg,
    name: "SONVEER SINGH",
    role: "Co-Founder",
    desc: "Serial entrepreneur with past success (Hirequest recruiting platform). 4+ yrs ed-tech experience in sales & marketing. B.S.C from MSU, Baroda",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },
  {
    bgImg: nimishDescImg,
    img: nimishImg,
    name: "NIMISH GOPAL",
    role: "Co-Founder",
    desc: "Serial entrepreneur with past success (CareerNaksha) Counselled 10000+ students. 10+yrs experience in sales & biz dev. B.Tech from IIT Roorkee & MBS from KGI, USA. ",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },

  {
    bgImg: abhinavKanagatDescImg,
    img: abhinavKanagatImg,
    name: "ABHINAV KANAGAT",
    role: "Head Teacher community",
    desc: "10+ yrs of teaching, content development & professional training. Founder of August Educare. MBA from IBS Hyderabad",
    ProfileFooterImg: ProfileFooterImg,
    width: "216",
    height: "216",
  },
  {
    bgImg: pallavDescImg,
    img: pallavImg,
    name: "PALLAV CHUDHARI",
    role: "Strategies & Growth",
    desc: "Experience in Consulting & working with startups. B.Tech from IIT Bombay & MBA from IIM Ahmedabad",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },

  {
    bgImg: kunalDescImg,
    img: kunalImg,
    name: "KUNAL SINGH",
    role: "Tech Team",
    desc: "6+ years of experience in building tech products. Previously @ Postergully, iDecorama, Ragecoffee. B.Tech from NIT Patna",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },
  {
    bgImg: abhinavWaliaDescImg,
    img: abhinavWaliaImg,
    name: "ABHINAV WALIA",
    role: "Tech Team",
    desc: "5+ yrs of technical experience building web products. UI UX developer. Experiece at Global Logic, DeCurtis, Infosys.",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },
  {
    bgImg: karanDescImg,
    img: karanImg,
    name: "Karan Kahar",
    role: "Design",
    desc: "2+ years experience in design, ex-founder of branding startup. Passionate about building UI UX for web.",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },

  {
    bgImg: KunalBasantaniDescImg,
    img: KunalBasantaniImg,
    name: "Kunal Basantani",
    role: "Digital Marketing Strategist",
    desc: "2+ years of experience in Digital Marketing especially in Google & Web Analytics, Local SEO Intermediator & SEO-SEM Strategist & Blogging.",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },
  {
    bgImg: abhishekDeskImg,
    img: abhishekImg,
    name: "Abhishek Khuswaha",
    role: "Tech Team",
    desc: "5+ years of tech experience in building tech products. Founder @senspark.io B.Tech in Computer Science from UPTU",
    ProfileFooterImg: ProfileFooterImg,
    width: "351",
    height: "351",
  },
];

export const setDesktopViewLeft = () => {
  return teamMembers.map((item, index) => index % 2 == 0 && item);
};
export const setDesktopViewRight = () => {
  return teamMembers.map((item, index) => index % 2 != 0 && item);
};
export const setMobileViewLeft = () => {
  return teamMembers.map((item, index) => index <= 5 && item);
};
export const setMobileViewRight = () => {
  return teamMembers.map((item, index) => index > 5 && item);
};
