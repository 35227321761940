import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import CodingwithMinecraft from "./../../Assets/Blogs/CodingwithMinecraft.png";
import "./content.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MainContentImage from "./../../Assets/Blogs/MainContentImage.png";
import AnimationBlog from "./../../Assets/Blogs/AnimationBlog.gif";
import einstientk from "./../../Assets/Blogs/einstientk.jpg";
import creativity from "./../../Assets/Blogs/creativity.gif";
import ican from "./../../Assets/Blogs/ican.png";
import TechoKidsFinalLogo from "./../../Assets/Blogs/TechoKidsFinalLogo2.png";
import webdev1 from "./../../Assets/Blogs/webdev1.jpg";
import minecraft1 from "./../../Assets/Blogs/minecraft1.jpg";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import Footer from "./../../Components/Footer";
import minecraftutilization from "./../../Assets/Blogs/minecraftutilization.png";

import ScrollTop from "./ScrollTop";
export const Minecraft = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Minecraft Coding for Kids | Education Edition | Techokids
    </title>
      <meta 
      name ="description" 
      content = "Before we continue with how Minecraft is employed in schools, let’s dive into what exactly Minecraft is. Minecraft may be a user-creation game that was released in 2009."/>
  
  <link rel = "canonical" href = "/blog/Minecraft-Beginners-guide"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
        <h1 className="heading-content ">
        {/* Everything You Need to Know About */}
         Minecraft Coding for Kids
      </h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          {/* <div>
            <AccountCircleIcon
              style={{
                fontSize: "350%",
              }}
            />
          </div> */}
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
            
          <div  className="d-flex flex-column inner-blogs-name">
            
            <div>
              <b>Deepali Meena</b>
            </div>
            <div>12 June 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>    </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <div className = "text-center">
        <img  alt = "Minecraft-a-beginners-guide" style = {{maxWidth:850}} className="main-content-image" src={minecraft1}></img>
        </div>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        Lately it's going to seem as if coding for teenagers has become synonymous with Minecraft. within the past few years, numerous coding activities started featuring this open-ended game and teachers started introducing it to their students. In 2016, Minecraft creator Mojang solidified Minecraft’s transition into schools by releasing an education edition of the sport . With its increasing presence within the classroom, it's time to find out exactly what Minecraft is and the way it became so popular within the educational world.

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        <p>
        The use of Minecraft within the classroom all started with a shift within the way we view programming . Coding stopped being viewed as a tedious task reserved for a get few and began being recognized as a fun activity anyone can learn and do. With this new approach, the road between coding and games began to blur. Companies started creating game-based activities that teach code and thereupon , the full-fledged game of Minecraft was remodeled and released as a game that not only entertains but also teaches code. 


        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>What is Minecraft?
</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        Before we continue with how Minecraft is employed in schools, let’s dive into what exactly Minecraft is. Minecraft may be a user-creation game that was released in 2009. it's since won numerous awards and brought over because the best-selling and most downloaded game in history. What makes it so special and in contrast to other games is that users enter a virtual world with no rules. during this world, they create their own environment using solely blocks, a structure that 
        ultimately gives Minecraft its distinctive rectangular look. There also are little instructions as users got to go onto other platforms to research and unlock new features. These elements and therefore the incontrovertible fact that for the primary time within the gaming industry players could enter a world where anything are often built and broken are what contributed to Minecraft’s huge success. 
</p>
<br></br>
<div className = "text-center">
  <img className = "other-image" src = {CodingwithMinecraft} alt = "coding with minecraft" />

</div>
<br></br>
<p>
Minecraft is usually described as a sandbox game.  Sandbox games are those in which the player is subjected to few restrictions. this enables a player to roam through the virtual world and make , modify and destroy their environment at their own will. This setting in Minecraft is usually mentioned as Creative Mode, which suggests there's no danger within the game. during this mode, the environment is formed from a flatland where the player has many different materials, including wood, stone and metal, which will be utilized to make a world. Shortly afterwards, another, tougher mode called Survival Mode was added. In Survival Mode gamers got to find food, build shelter, craft weapons and wear protective gear so as to shield themselves from any existing dangers and survive.
</p>

<p>
More aspects were introduced to Minecraft throughout time, increasing its popularity. People are ready to build replicas of various worlds, including Hogwarts, allowing other gamers to download them and use them in their games. Players like that Minecraft features a very simple look and appears to be a basic game initially glance. However, the deeper you get into the sport , the more you learn you'll do. Even the regular, non-Education Edition resembles aspects that would possibly translate to some coding logic. for instance , players use logic gates and make simple machines so as to automate some tasks. Students get a fun-looking environment to check different features out and show their friends. Since there's little to no instruction in Minecraft, the player must find out the principles on their own or use Internet tutorials.

</p>

<br></br>
<h3>
        <b>How is Minecraft utilized in schools?</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <br></br>
      <div className = "text-center">
          <img style = {{maxWidth:800}}  className = "other-image" src = {minecraftutilization} alt = "utilization of minecraft in schools">
          </img>
      </div>
<br></br>
        <p>
        It is rare that a game made for solely entertainment purposes crosses over and is employed so widely in education. However, Minecraft proved to be an exception. Ever since Microsoft bought Minecraft in 2014, it's been utilized in countless coding courses to show kids real programming . Even with this alteration , a crucial question remains – can a game actually be wont to teach coding? Well, it seems that with a couple of tweaks, it can! 
</p>
         <p>
         Code.org and Microsoft have collaborated to deliver Minecraft coding to children aged 7 and up.  Students can access this virtual environment through numerous activities while learning several computer languages, including block-based coding.. The courses come equipped with tutorials that help guide students on the way to build their own worlds using the famous game. These courses utilize the theme of Minecraft to assist students learn coding as they explore different worlds.</p>
         <p>
         The amount of youngsters learning to code through fun games like Minecraft is constant to grow. the simplest thanks to discover how Minecraft can teach students code is to undertake it for yourself in class!

         </p>
         
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

