import React, {useState} from "react";
import BackNavigation from "./BackNavigation";
import Base from "./Base";
import "./Styles.css";
import pcsetup from "./Assets/Setting/pcsetup.png";
import time from "./Assets/Setting/time.png";
import chrome from "./Assets/Setting/chrome.png";
import ContactInfoItem from "./ContactInfoItem";
import {FiDownload} from "react-icons/fi";
import {openInNewTab} from "./Setting";
import CheckPcModal from "./CheckPcModal";
import Dashboard from "./Dashboard";

const TechSupport = () => {
  const [showModal, setshowModal] = useState(false);

  const handleCheckPc = () => {
    setshowModal(true);
  };
  const onModalBtnClick = () => {
    setshowModal(!showModal);
  };
  return (
      <div className=" w_85 mx-auto">
        <BackNavigation
          title={"Reach out to us for any assistance"}
          color={"#000"}
        />
        <Dashboard/>

        <div className="tech_support w_86 mx-auto">
          <ul>
                <li>
              <ContactInfoItem
                img={pcsetup}
                title={"Set up your PC"}
                text={
                  "Check your network connection and computer set up to have best in class experience"
                }
                action={{
                  title: "Check Pc",
                  handle: () => handleCheckPc(),
                }}
              />
            </li>
            <li>
              <ContactInfoItem
                img={time}
                title={"Download AnyDesk Here"}
                action={{
                  title: "Download",
                  handle: () => openInNewTab("https://anydesk.com/en"),
                  icon: <FiDownload />,
                }}
              />
            </li>
            <li>
              <ContactInfoItem
                img={chrome}
                title={"Download the latest version of chrome"}
                action={{
                  title: "Download",
                  handle: () =>
                    openInNewTab(
                      "https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAiA9vOABhBfEiwATCi7GM7k7e4RuMtyG53A4WpzO-Cl-VHG745bYZhcbrVOoZHFV-rjaeiS8RoC1XYQAvD_BwE&gclsrc=aw.ds"
                    ),
                  icon: <FiDownload />,
                }}
              />
            </li>
          </ul>
        </div>
        <CheckPcModal showModal={showModal} closeModal={onModalBtnClick} />
      </div>
  );
};

export default TechSupport;
