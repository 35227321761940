import React from "react";
import FamilyImage from "../Assets/homepage/family-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import WhiteLogo from "../Assets/homepage/white-logo.png";
import FacebookIcon from "../Assets/homepage/facebook.png";
import InstagramIcon from "../Assets/homepage/instagram.png";
import TwitterIcon from "../Assets/homepage/twitter.png";
import LinkedInIcon from "../Assets/homepage/linkedin.png";
import YoutubeIcon from "../Assets/homepage/youtube.png";
import MapImage from "../Assets/homepage/map.png";
import MailIcon from "../Assets/homepage/mail.png";
import CallIcon from "../Assets/homepage/call.png";
import "../Styles/fotter.css";
import ExternalLink from "./ExternalLink";

export default function Footer(props) {
  return (
    <footer>
      <script type="application/ld+json">
        "@context": "http://schema.org/", "@type": "EducationalOrganization",
        "name": "TechoKids", "alternateName": "TechoKids", "url":
        "https://www.techokids.com/", "image":
        "https://www.techokids.com/static/media/logo.2f37d971.png", "sameAs": [
        "https://www.facebook.com/TechoKids/", "https://twitter.com/TechoKids/",
        "https://www.instagram.com/techokids/",
        "https://www.linkedin.com/company/techokids/",
        "https://www.techokids.com/",
        "https://www.youtube.com/channel/UCIQ3U-ea7I3oOA6a8dnvIcQ",
        "https://in.pinterest.com/techokids" ]
      </script>

      <div className="footer">
        <div className="ischildready">
          <div className="ischildready__left">
            <h1>
              Is your child ready
              <br />
              for Future?
            </h1>
            <span>
              {" "}
              Start{" "}
              <h6
                style={{
                  fontSize: "inherit",
                  display: "inline",
                  fontWeight: 400,
                }}
              >
                {" "}
                Coding{" "}
              </h6>{" "}
              Today!{" "}
            </span>

            <button
              onClick={(event) => {
                event.preventDefault();
                window.location.href = window.location.origin + "/book-trial";
              }}
            >
              Book Now
            </button>
          </div>
          <div className="ischildready__right">
            <img src={FamilyImage} alt="familyImage" width="927" height="506" />
          </div>
        </div>
        <div className="footer__content">
          <div className="footer__contact">
            <div className="footer__contact__left">
              Any query? <br />
              Contact us 24*7
            </div>
            <div className="footer__contact__right">
              <div>
                <span>Send us an Email</span>
                <a href="mailto:hello@techokids.com">
                  <img src={MailIcon} alt="mailIcon" width="250" height="250" />{" "}
                  hello@techokids.com
                </a>
              </div>
              <div>
                <span>Call us</span>
                <a href="tel:918896518896">
                  {" "}
                  <img
                    width="250"
                    height="250"
                    src={CallIcon}
                    alt="callIcon"
                  />{" "}
                  +91 8896518896
                </a>
                <a href="tel:912653561742">
                  {" "}
                  <img
                    width="250"
                    height="250"
                    src={CallIcon}
                    alt="callIcon"
                  />{" "}
                  +91 2653561742
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__separator" />
      </div>

      <div className="footer__moreinfo">
        <div className="footer__moreinfo__col col1">
          <img
            width="1080"
            height="300"
            src={WhiteLogo}
            alt="whiteLogo"
            className="mb-4"
          />{" "}
          <br />
          <div className="footer__social">
            <ExternalLink to="https://www.facebook.com/TechoKids/">
              <img
                src={FacebookIcon}
                width="79"
                height="78"
                alt="facebookIcon"
              />
            </ExternalLink>
            <ExternalLink to="https://www.instagram.com/techokids/">
              <img
                src={InstagramIcon}
                alt="instagramIcon"
                width="79"
                height="78"
              />
            </ExternalLink>
            <ExternalLink to="https://twitter.com/TechoKids/">
              <img width="79" height="78" src={TwitterIcon} alt="twitterIcon" />
            </ExternalLink>
            <ExternalLink to="https://www.linkedin.com/company/techokids/">
              <img
                width="79"
                height="78"
                src={LinkedInIcon}
                alt="linkedInIcon"
              />
            </ExternalLink>
            <ExternalLink to="https://www.youtube.com/channel/UCIQ3U-ea7I3oOA6a8dnvIcQ/">
              <img width="79" height="78" src={YoutubeIcon} alt="youtubeIcon" />
            </ExternalLink>
          </div>
        </div>
        <div className="footer__moreinfo__col col2">
          <span className="footer__moreinfo__col__title">Company</span>
          <div className="footer__moreinfo__links">
            <ExternalLink to="/about-us" noScroll={true}>
              About us
            </ExternalLink>
            <ExternalLink to="/blog">Blog</ExternalLink>
            <ExternalLink to="https://forms.gle/BZ6o9pBy8LANqpk79">
              Teach with us
            </ExternalLink>
            <ExternalLink to="/refer-earn" noScroll={true}>
              Refer & Earn
            </ExternalLink>
            <ExternalLink to="/help-faq" noScroll={true}>
              Help & FAQ
            </ExternalLink>
            <ExternalLink
              to="https://app.pyjamahr.com/careers?company=TechoKids&company_uuid=47CCF059FE"
              noScroll={true}
            >
              We are Hiring!
            </ExternalLink>
          </div>
        </div>
        <div className="footer__moreinfo__col col3">
          <span className="footer__moreinfo__col__title">More</span>
          <div className="footer__moreinfo__links">
            <ExternalLink to="/terms-conditions" noScroll={true}>
              Terms & Conditions
            </ExternalLink>
            <ExternalLink to="/privacy-policy" noScroll={true}>
              Privacy Policy
            </ExternalLink>
            <ExternalLink
              to="https://student.techokids.com/login"
              noScroll={true}
            >
              Student Login
            </ExternalLink>
            <ExternalLink
              to="https://mentor.techokids.com/login"
              noScroll={true}
            >
              Teacher Login
            </ExternalLink>
            {/* <ExternalLink to='/counselling-Session' noScroll={true}>
            Counselling Session
            </ExternalLink> */}
            <ExternalLink to="/system-check" noScroll={true}>
              {/* System check */}
            </ExternalLink>
          </div>
        </div>
        <div className="footer__moreinfo__col col4">
          <img src={MapImage} alt="mapImage" width="349" height="201" />
        </div>
      </div>

      <div className="footer__madewith">
        &copy;2023 Techokids Pvt. Ltd. Made with love{" "}
        <span>
          <FontAwesomeIcon icon={faHeart} />
        </span>{" "}
        in India
      </div>
    </footer>
  );
}
