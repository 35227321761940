import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const AboutTeamMembers = ({item}) => {
  return (
    <div className="memeber__profile" key={item.name}>
      <img src={item.bgImg} alt="" />
      <LazyLoadImage
        alt={item.name}
        src={item.img}
        effect="opacity"
        className="profile__pic"
        // width = {item.width}
        // height = {item.height}
        
      />
      {/* <img className="profile__pic" src={item.img} alt={item.name} /> */}
      <div className="title">
        <h5>{item.name}</h5>
        <div>{item.role}</div>
        {item.ProfileFooterImg && (
          <img   src={item.ProfileFooterImg} alt="profileFooter" />
        )}
      </div>
      <div className="description">
        <p>{item.desc}</p>
      </div>
    </div>
  );
};

export default AboutTeamMembers;
