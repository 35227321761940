import React, { useState, useEffect } from "react";
import "./countdown.css";
import axios from "axios";
import Timer from "./Timer";
import "./Modal.css"
import { useHistory } from "react-router-dom"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { modalSubmit } from "../../DataAccessObject/DataAccessObject";
import Overlay from "../../Components/Overlay";

const CountDown = (props) => {

  const [ type, setType ] = useState("Converted");
  const [amount, setAmount] = useState(null)
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("tk_tok")).token)
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(false);

  // const  refCode = (JSON.parse(localStorage.getItem("ref-code")).scholarshipCode);
  const [refCode, setRefCode] = useState(JSON.parse(localStorage.getItem("ref-code")).refCode)
  



  //////////////////////////// -------------- select dwopdown value -------------------////////////////

const plansArray = [
  {name:'Beginner'},
  {name:'Advance'},
  {name:'Intermediate'},
  {name:'Master'},
  {name:'Ultimate'},
]
const [plan, setPlan] = useState("Beginner");


  const history = useHistory();

  const onModalSubmit = () =>{ 
    setLoading(true);
    modalSubmit(type, amount, plan, selectedDate, token)
    .then((response) => {
      if (response.status === 200) {
        setToken(response.data.token);
        alert("Successfully Submit!!")
        let path = `/`; 
        history.push(path);
        localStorage.removeItem('ref-code');
        localStorage.removeItem('tk-tok');
    setLoading(false);

      } else {
        alert(response.data.message);
    setLoading(false);
      }
    })
    .catch((err) => {
      let message = err.response.message;
      alert(message);
    setLoading(false);
    })
};
  

  //modal
  const [popup,setPop]=useState(false)
  const handleClickOpen=()=>{
      setPop(!popup)
  }
  const closePopup=()=>{
      setPop(false)
  }

 

  // const referralCode = referralCodeGenerator.alphaNumeric("uppercase", 2, 2);

  return (
    <div className="countdown">
      <div className="box">
        <span className="text">Congratulations</span>

        <Timer />
        <span className="referralCode">
          Here is one time scholarship code : {refCode}
        </span>
        <span className="button">
          <button
            className="btnc"
            onClick={handleClickOpen}
          >
            End session
          </button>
        </span>

        <div>
                {
                    popup?
                    <div className="main">
                        <div className="popup">
                            <div className="popup-header">
                                <h1></h1>
                                <h3 className="cross-btn" onClick={closePopup}>X</h3>
                                
                            </div>
                            <div className="row">
  <div className="col-5">
    <div className="list-group" id="list-tab" role="tablist">
      <a className="list-group-item list-group-item-action active  text-sz" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home" onClick={()=>setType("Converted")}>Converted</a>
      <a className="list-group-item list-group-item-action text-sz" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile" onClick={()=>setType("Scholarship Hold")}>Scholarship Hold</a>
      <a className="list-group-item list-group-item-action text-sz" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages" onClick={()=>setType("Lost")}>Lost</a>
    </div>
  </div>
  <div className="col-7">
    <div className="tab-content" id="nav-tabContent">
      <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list"> <div>
      <div className="form-group w-75">
    <input type="number" className="form-control w-75" id="exampleFormControlInput1" placeholder="Enter Amount" onChange={(event) => setAmount(event.target.value)}/>
  </div> 
      </div>
        <div>
        <div className="form-group">
    <label htmlFor="exampleFormControlSelect1">Plan</label>
    <select className="form-control d-inline mx-2" id="exampleFormControlSelect1 select1" onChange={(e)=>setPlan(e.target.value)}>

      {plansArray.map((val, index)=>{
        return(
           <option value={val.name} key={index}> {val.name} </option>
         );
      })}

    </select>
  </div>
        </div>
        <div>
        <button type="button" className="btn btn-primary" onClick={onModalSubmit}>Submit</button>
            </div></div>
      <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
      <div>
      <div className="form-group">
    <input type="number" className="form-control w-75" id="exampleFormControlInput1" placeholder="Enter Amount" onChange={(event) => setAmount(event.target.value)}/>
  </div>
      </div>
        <div>
        <div className="form-inputs"> 
                <div className="form-group">
                  <div className="d-flex align-items-center w-75">
                    
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      isClearable
                      showYearDropdown
                      scrollableMonthYearDropdown
                      placeholderText="Select date"
                      // closeOnScroll={true} 
                      className="form-control inputFields"

                      // maxDate={new Date()}
                      // filterDate={date => date.getDay() !== 6 && date.getDay() !== 0}
                    />
                  </div>
                </div>
              </div>
      </div>
        <div>
        <button type="button" className="btn btn-primary" onClick={onModalSubmit}>Submit</button>
        </div>
      </div>
      <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
      <button type="button" className="btn btn-primary" onClick={onModalSubmit}>Submit</button>

      </div>
    </div>
  </div>
  
</div>
                        </div>
                    </div>:""
                }
            </div>
      </div>

    </div>
  );
};

export default CountDown;
