import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";


import "./content.css";


import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import parenttips from "./../../Assets/Blogs/parenttips.jpg";

export const Parenttips = () => {
  const history = useHistory();
  return (
    <>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content">
        Tips for Parents Who Want to Teach Their Kids Code
</h1>
       
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={parenttips}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        

        Regardless of what your profession is, teaching codes to kids might be a difficult problem. Even if you are a computer scientist or a software developer, teaching the basics could be a hectic task. Some people hurdles thinking about the right age to start. I would suggest that there is no right age to start. If you want your child to learn about coding and gain interest in their surroundings, then start by providing them with coding puzzles, robots, and much more. Robotics is one of the most interesting branches of coding, and hence, you need to open the vivid world of coding for your kids. 
  {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        
        <p>
However, it is important to note that you don't need to force anything onto your child. Let them explore the world with your help. After everything, if they don't show up interest, don't force them to take it up as a subject. All you can do is teach them the basics of coding, as it will help them improve their thinking skills, analytical skills, and understanding skills. In this digitalized era, each of us must know a bit about coding, even if it's not our favorite subject. Having a little knowledge about coding might help kids in ways you wouldn't know. Ultimately, it is a beneficial thing to do to help your child succeed in their life. 

</p>

<p>
With all that being said, let's see the best tips and tricks for parents who want to teach their kids to code. You might think it is a great deal and would be hard to impart your child's coding interest. But the good news is that the story doesn't start like that. With this guide, you will be able to learn about the easiest ways in which you will be able to infuse interest in coding in your child. These steps are effective and involve no mess, no force, just the love for coding and computers. So, let's get started!
</p>
        <h3>
        Top Tips For Parents Who Want To Teach Their Kids To Code </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        {/* <p> */}
            
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    {/* A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out.  */}
{/* </p> */}
<p>Here are the top tips especially crafted for you and your child to grasp coding easily. Have a look. </p>



<ul>
    <li>
       <p> <b>Avoid rushing your child with coding.</b></p>
       As I mentioned earlier that there is no right age to explore the world of coding. A child as early as age five can start to explore the world. All you need to do is show them the interesting path of this world. You don't need to give them a mentor or online classes right away. Start by talking. Yes, you heard that right. TALK. Talk and discuss the added beauty that coding gives to the world. We all know how coding has changed the world and how it is helping the world evolve. Tell them through interesting stories, and you will watch your child gain interest in coding. 
</li>


<li>
       <p> <b>Coding Is Fun! Keep Those Heavy Books Aside!</b></p>
       Don't let your child feel coding is all about writing a complex line of codes. Break 
       it down into reality. What's the reality? You need to learn the fundamentals of coding, the structures, 
       the nodes, and then create what you want. It might look like it's a hectic task, but once they know the
        basics, it would help them further create an application themselves. Now, it doesn't have to be the hard way.
         Provide them with a fun computer or mobile games related to coding, and you will be able to witness the interest
          surging up in them. Let them try out games like <a href = "https://play.google.com/store/apps/details?id=com.learnprogramming.codecamp&hl=en_IN&gl=US">Programming Hero</a> 
          (Android), <a href = "https://www.codemonkey.com/">Code Monkey</a>(PC) 
          , <a href = "https://checkio.org/">CheckIO, And Empire Of Code</a> (PC), <a href = "https://www.codewars.com/">Code Wars </a> (PC), and much more. It is important to note that some games are meant for children who have intermediate knowledge about coding. However, games like these are available for kids of every age. 
</li>



<li>
       <p> <b>Try Games Off-Screen </b></p>
       The best way for your children to exercise coding is by playing off-screen games.
        What are those? Well, there are plenty of such games. You could 
        opt for <a href = "https://www.youtube.com/watch?v=fxw3U2mvXvM">Myland Interactive Houseboat</a>, <a href = "https://www.toysrus.com/">With Go Robot Mouse</a>,
        <a href = "https://www.kickstarter.com/projects/codomo/potato-pirates-the-tastiest-coding-card-game">Potato Pirates</a>, and much more. These engaging tangible games
          will help your child understand the coding world better. 
</li>


<li>
       <p> <b>
Don't Leave It All On Them!
</b></p>
We often make the mistake of leaving our children alone, thinking they can do it by themselves, and they need to be independent. The idea is wholly wrong. Your child needs you. You need to sit with them and play the same games. It would be best if you showed interest in it as they see you as their role models. Don't always talk about coding. Just engage in a good quality time and go with the flow. 

</li>


<li>
       <p> <b>
Find a Good Mentor
</b></p>
After your child reaches the age of 12 or 14, have them enrolled in an online course or tutor who would be able to guide your child further. Slowly and steadily, he or she would be able to grasp a good knowledge about the subject without being forced on. 
</li>


<li>
       <p> <b>A Complete Educational Class Might Be Boring</b></p>
       Undoubtedly, if the online classes are just about switching between the theory and the compiler, it will get boring. It must come with added games and projects that a child can do with the taught courses. Right? TechoKids offers live 1:1 interactive classes filled with robust graphics and projects to help your child understand their course better. 

</li>

<li>
       <p> <b>
Stop Pushing Your Child

</b></p>
Like it has been told earlier, don't push your child into the coding world. Help them explore the full world of coding with games, gadgets, reading, and learning. Inject the passion into them slowly.
    </li>

</ul>

<p>


With all that being said, I hope by now, you must have understood how easy it is to get it going! Start with any one tip, and you will notice the change in your child right away. If you want daily updates about us, keep a follow on our Twitter, Facebook, Linked In, and Instagram handles. Leave your thoughts in the comment section below. 

</p>


<p>
    Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
      
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

