let hostname = window.location.hostname;
let url = `https://${hostname}`;
export const getTitleAndMetaDescription = function () {
  return {
    "/": {
      title: `TechoKids: #1 Live Online Coding & Robotics Classes For Kids`,
      description: `TechoKids is the Best Robotics & Coding Platform For Kids. Online Classes of Robotics For Kids are conducted with expert teachers. Learn Scratch, AI & Python...`,
      keywords:
        "best live free online coding classes for kids of grade 1-12 in india live online coding classes for kids, python for kids, coding for children, coding for kids, app development, game development, virtual reality      programming for kids, python programming, web development, augmented reality, scratch programming, scratch coding for kids, robotics, machine learning      artificial intelligence, coding for kids online, coding for kids near me",
      og: {
        title: "FAQs - TechoKids | Best Live Online Coding Courses For Kids",
        description:
          "TechoKids is the Best Robotics & Coding Platform For Kids. Online Classes of Robotics For Kids are conducted with expert teachers. Learn Scratch, AI & Python...",
        url: " https://www.techokids.com/",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "Do you want your kid to create the next robot or tech platform? Book A Free Live 1:1 Coding Trial Class Now!",
    },

    "/blog": {
      title: `Blog - TechoKids | Best Live Online Coding Courses For Kids`,
      description: `TechoKids’ Blogs are related to STEM Education including best live online coding courses for kids regarding study hacks, parenting tips, & coding for kids.`,
      keywords:
        " coding for kids, python for kids, scratch coding for kids, scratch programming for kids, coding for kids online, minecraft coding for kids, programing for kids, kids coding academy, best coding sites for kids, introduction to coding for kids, best online coding for kids",
      og: {
        title: "Blogs - Techokids | Best Live Online Coding Courses For Kids",
        url: "https://www.techokids.com/blog",
        description: `TechoKids’ Blogs are related to STEM Education including best live online coding courses for kids regarding study hacks, parenting tips, & coding for kids.`,
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "Blogs section at Techokids will help you understand more about the Best Live Online Coding Classes For Kids",
    },

    "/help-faq": {
      title: `FAQs - TechoKids | Best Live Online Coding Courses For Kids`,
      description: `TechoKids FAQs will surely give you all answers you’re looking for related Best Live Online Coding Classes For Kids in Coding Courses, Sign Up Process, & more…
      `,
      keywords:
        "coding kids, coding for kids free, scratch coding for kids, computer coding for kids, coding websites for kids, javascript for kids, coding courses for kids, online coding courses for kids, learn coding for kids, free online coding for kids, best coding for kids, free coding websites for kids, coding for toddlers",
      og: {
        title: "TechoKids: #1 Live Online Coding & Robotics Classes For Kids",
        url: "https://www.techokids.com/help-faq",
        description: `TechoKids FAQs will surely give you all answers you’re looking for related Best Live Online Coding Classes For Kids in Coding Courses, Sign Up Process, & more…`,
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "TechoKids FAQs will surely give you all answers you’re looking for related Best Live Online Coding Classes For Kids in Coding Courses, Sign Up Process, & more…",
    },

    "/book-trial": {
      title: `Book A Free Trial - TechoKids | Best Live Online Robotics Classes For Kids
      `,
      description: `Book A Free 1:1 Online Trial Session on TechoKids. We offer the Best Live Online Kids Coding Platform and Robotics Classes For Kids.`,
      keywords:
        "book a free online trial class on the best live free online coding classes for kids of grade 1-12 in india, learn from home, elearning from home, virtual learning from home, virtual learning platform, multiple live projects online training certification, games, chatbot, website, apps, animations, world class teachers, strong technical experienced teachers, gamified curriculum, world class events, online coding hackathons, online coding competitions, online coding challenges, book your free trial class, book your online free trial class, book your online free live 1:1 coding trial class",
      og: {
        title:
          "Book A Free Trial - TechoKids | Best Live Online Robotics Classes For Kids",
        url: "https://www.techokids.com/book-trial",
        description:
          "Book A Free 1:1 Online Trial Session on TechoKids. We offer the Best Live Online Kids Coding Platform and Robotics Classes For Kids. ",
        img: `${url}/ogImg/og-book-trial.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        " Do you want your kid to create his/her own apps, games, websites, & robot? Book A Free Live 1:1 Coding Trial Class Today!",
    },

    "/login": {
      title: `Login - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `TechoKids offers Best Live Online Coding Classes For Kids. Start learn Coding through Self-Paced Learning, Multiple Live Projects Training by Technical Experts.`,
      keywords:
        "fun coding for kids, learn coding online for kids, kids and coding, teach kids coding online, best way for kids to learn coding, coding and programming for kids, coding for kids beginners, best way to teach coding, best way to learn coding for kids",
      og: {
        title:
          "TStudent Login - TechoKids | Best Live Online Coding Classes For Kids",
        url: " http://www.techokids.com/",
        description:
          "TechoKids offers Best Live Online Coding Classes For Kids. Start learn Coding through Self-Paced Learning, Multiple Live Projects Training by Technical Experts.",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "Do you want your kid to create the next robot or tech platform? Book A Free Live 1:1 Coding Trial Class Now!",
    },

    "/about-us": {
      title: `About Us - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `TechoKids’ About Us will show you an overview of our vision, mission & beliefs of the successful leaders through Best Live Online Coding Classes For Kids.`,
      keywords:
        "best online learning platform for kids of 6-18 years, online learning platform for kids in india, best free 1:1 live online coding classes for kids, best free 1:1 live online coding sessions for kids in india, best self paced online coding courses for kids, best self paced online coding sessions for kids in india, best elearning platform for kids of 6-18 years, online elearning platform for kids in india, talented teachers, talented tutors, trained teachers, trained tutors, technical experts, technical tutors",
      og: {
        title:
          " About Us - TechoKids | Best Live Online Coding Classes For Kids",
        url: "https://www.techokids.com/about-us",
        description:
          "TechoKids About Us will show you an overview of our vision, mission & beliefs of the successful leaders through Best Live Online Coding Classes For Kids.",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "TechoKids About Us will show you an overview of our vision, mission & beliefs of the successful leaders through Best Live Online Coding Classes For Kids",
    },

    "/teach-with-us": {
      title: `Teach With Us - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `Teach With Us includes a detailed form for those who are looking to apply for Teachers Profile who provides the Best Live Online Coding Classes For Kids.
      `,
    },

    "/tech-check": {
      title: `TechCheck - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `TechoKids provides the Best Live Online Coding Classes For Kids. For that, TechCheck checks WebCamera Quality, Internet Speed Test, and Microphone Testing.`,
    },

    "/refer-earn": {
      title: `Refer & Earn - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `Refer & Earn offers you to invite your friends to join TechoKids and you’ll get a MacBook plus 10% off on your Enrolled Coding Courses based on TechoKids Plans.`,
      keywords:
        "refer and earn, referral program, cash rewards and macbook, win cash rewards, win macbook, refer and earn sites, refer and earn apps, unique referral link, invite your friends, share your referral code, invite friends, invite family, share your unique referral link, earn cash rewards, earn macbook, win macbook, enroll our courses, win macbook pro, referral program eligibility, free trial, giveaway, advance packages, master packages, coding, refer and earn gifts, refer and earn rewards, earn macbook pro, referral links",
      og: {
        title:
          " Refer & Earn - TechoKids | Best Live Online Coding Classes For Kids",
        url: "https://www.techokids.com/refer-earn",
        description:
          "Refer & Earn offers you to invite your friends to join TechoKids and you’ll get a MacBook plus 12% off on your Enrolled Coding Courses based on TechoKids Plans.",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "Refer & Earn offers you to invite your friends to join TechoKids and you’ll get a MacBook plus 12% off on your Enrolled Coding Courses based on TechoKids Plans.",
    },
    "/terms-conditions": {
      title: `Terms & Conditions - TechoKids | Best Live Online Coding Classes For Kids
      `,
      description: `While accessing our website, www.techokids.com, you are accepting to be bound by all of our below-mentioned terms of service, all relevant laws, & regulations.
      `,
      og: {
        title:
          "Terms & Conditions - TechoKids | Best Live Online Coding Classes For Kids",
        url: "https://www.techokids.com/terms-conditions",
        description:
          "While accessing our website, www.techokids.com, you are accepting to be bound by all of our below-mentioned terms of service, all relevant laws, & regulations.",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "While accessing our website, www.techokids.com, you are accepting to be bound by all of our below-mentioned terms of service, all relevant laws, & regulations.",
    },
    "/privacy-policy": {
      title: `Privacy Policy - TechoKids | Best Live Online Coding Classes For Kids
      `,
      description: `TechoKids Privacy Policy guarantees data security, rights to your personal information which includes the purpose of collection, and other required data collection.
      `,
      og: {
        title:
          "Privacy Policy - TechoKids | Best Live Online Coding Classes For Kids",
        url: " https://www.techokids.com/privacy-policy",
        description:
          "TechoKids Privacy Policy guarantees data security, rights to your personal information which includes the purpose of collection, and other required data collection.",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        " TechoKids Privacy Policy guarantees data security, rights to your personal information which includes the purpose of collection, and other required data collection.",
    },
    "/payment": {
      title: `Payment - TechoKids | Best Live Online Coding Classes For Kids`,
      description: `TechoKids offers Best Live Online Robotics Classes for Kids. Start learns Coding through Self-Paced Learning, Multiple Live Projects Training by Technical Experts.`,
      og: {
        title:
          "Privacy Policy - TechoKids | Best Live Online Coding Classes For Kids",
        url: " https://www.techokids.com/payment",
        description:
          "TechoKids is the Best Robotics & Coding Platform For Kids. Online Classes of Robotics For Kids are conducted with expert teachers. Learn Scratch, AI & Python...",
        img: `${url}/ogImg/og-default.jpeg`,
        imgAlt: "",
        siteName: "TechoKids",
      },
      twitterCard:
        "Payment - TechoKids | Best Live Online Coding Classes For Kids ",
    },
  };
};
