import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import Image2 from "./../../Assets/Blogs/Image2.png";
import "./content.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MainContentImage from "./../../Assets/Blogs/MainContentImage.png";

// import einstientk from "./../../Assets/Blogs/einstientk.jpg";
import { useHistory } from "react-router-dom";
import Footer from "../../Components/Footer";
import healthyrelationships from "./../../Assets/Blogs/healthyrelationships.jpg";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Healthyrelationship = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center" >
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content">
        How to teach kids about building healthy relationships? 
</h1>
</div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          {/* <div>
            <AccountCircleIcon
              style={{
                fontSize: "350%",
              }}
            />
          </div> */}
          <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
 
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <div className = "text-center">
        <img className="main-content-image" src={healthyrelationships}></img>
        </div>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        Maintaining healthy relationships is crucial for mental and emotional well-being. Not only that, but also for your survival. Having good relationships with teachers and parents could be a blessing. Moreover, one should always treat their loved ones with respect and kindness without expecting anything back from them. It is not being a coward but being down to earth. There is a shortage of good humans already. 

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        <p>
        So, why not become a good human first? Before bringing A grades, or a full 100 marks, or becoming a CEO, what’s more important is being a generous human being. Well, now you might ask what counts as a good human being? Just being kind and giving? The obvious answer is no. It involves other things like being honest, being helpful, being forgiving. It wouldn’t always be possible to maintain everything. 
 </p>
        <p>
        Well, first thing first. What a child needs is nourishment and strength. It starts by building healthy relationships. This begins within their nest, from their parents and then their teachers. Often, humans have desires to be near others. “healthy” could be romantic, friendships, family, or much more. A good relationship doesn’t happen in one way. It is possible to achieve a healthy relationship when both parties are working on it. The parties need to understand each other, listen to each other, communicating with each other without judging, trusting, making time for one another, and engaging in activities that would keep the relationship healthy. 

</p>
<p>It is relatively easy to read it out but difficult to apply. Please don’t leave it all on your children as it is not only their part. Often when a child starts growing, they are humiliated by teachers and parents themselves. One doesn’t need to elaborate on this as we already have heard or seen many cases. Even you might have a story like that. So, why make your child go through the same thing? Let’s get into details about how to teach kids about building a healthy relationship.</p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Benefits of maintaining a healthy relationship

</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        
        There are innumerable benefits of maintaining a healthy relationship, and hence, kids need to learn how to. The services include low stress, inner-happiness, good behavior, and a sense of purpose, and it would help them live a longer, healthier life. </p>

<h3>
        <b>
Top Ways To Teach Kids About Building Healthy Relationships
</b>  </h3>
      <p>Are you looking for how you can teach your kid about building healthy relationships with parents, teachers, and everyone else? This is the right place to look for your answers. Now that you know the importance of building a healthy relationship, we can come to how you can teach your child about it. Have a look at the following points to learn more. </p>
        <ul>
            <li>Discuss the subject with your kids. Tell them about how important it is to have a good relationship with everyone. Talk about what are the things that it counts. What does it mean to have a good relationship? Tell them about being honest, respectful, or communicating. 
</li>
            <li>They need to learn the meaning of an unhealthy relationship too. So, don’t forget to talk to them about an unhealthy relationship. Let them know an unhealthy relationship is a situation where one has got more power balance. They would emotionally abuse you, call you names, threaten you, insult you, withhold money, stalking, and many other things.</li>
            <li>Please encourage them to speak up when needed. Let them feel free to discuss their stress and needs. Children often might not like the behavior they get. It could be at school or online classes, or within the family. </li>
            <li>Help them define their boundaries. Yes, you heard that right. Let them know up to what extent an action is right or wrong. It would help them understand distinguish violent acts of abuse in the form of verbal or physical. 
</li>
            <li>As this is a digitalized century, you need to talk about digital abuse to your children as well. Texting has become immensely open, which has lead to open doors for digital abuse and harassment. These might include forcing partners to share their social media sites passwords, sexting, cyberbullying, unwanted calls, and others. Let your child be aware of these.</li>
            <li>Teach them how to balance a relationship. Children often prefer only a single person over others, and they might force only that person to play with them. It might put unwanted pressure on them, and the other person might feel betrayed if they do not play with your child. Teaching them about the healthy relationship is essential. </li>
            <li>Reduce the time they spend on screen. How is it related? Well, the more they are on the net, the more introverted they become!
</li>
            <li>Don’t forget to respect the feelings of your child, as well. Your child cannot take it up all alone. They need an idol figure to look up to. So, if you want them to be respectful and kind to others, you need to show it yourself to them by being their idol. 
</li>
        </ul>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        {/* <div className="text-center">
        <img style = {{marginRight:40, borderRadius:20} } width = "600" src={einstientk}></img>
      </div> */}
      <br></br>
      <p>
In a nutshell, we all know how important it is to maintain a healthy relationship. A child needs to nourish this from the very first day. Above were some of the most critical points about how you can help kids teach about building healthy relationships. What do you think about it? Moreover, if you would like to stay updated about TechoKids, keep a follow on our Twitter, Facebook, Linked In, and Instagram handles. </p>
      
        
            
        


<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>

   
    </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer/>
  </>
  );
};

