import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import Image2 from "./../../Assets/Blogs/Image2.png";
import "./content.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MainContentImage from "./../../Assets/Blogs/MainContentImage.png";
import AnimationBlog from "./../../Assets/Blogs/AnimationBlog.gif";
import einstientk from "./../../Assets/Blogs/einstientk.jpg";
import creativity from "./../../Assets/Blogs/creativity.gif";
import ican from "./../../Assets/Blogs/ican.png";
import TechoKidsFinalLogo from "./../../Assets/Blogs/TechoKidsFinalLogo2.png";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import AppDevThumbnail from "./../../Assets/Blogs/AppDevThumbnail.jpg"
import appdev from "./../../Assets/Blogs/appdev.jpeg";
import  appdev2 from "./../../Assets/Blogs/appdev2.jpeg"
import  appdev3 from "./../../Assets/Blogs/appdev3.jpeg"
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
export const Appdev = () => {
  const history = useHistory();
  return (
    <div>
    <Helmet>
      <title> 
      App Development For kids | Complete Guide | Techokids </title>
      <meta 
      name ="description" 
      content = "Application development is highly needed in businesses. It is mostly for the growth of the company and to connect with other people and competitors. "/>
  <link rel = "canonical" href = "/blog/app-development"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width : "100%"}} >
         <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img> 
        {/* <div className="heading-content "> */}
       <h1 className="heading-content "> Application Development</h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  alt = "Deepali Meena" style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
            
            <div  className="d-flex flex-column inner-blogs-name">
              <div>
              <b>Deepali Meena </b>
            </div>
            <div>15 May 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink> </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img alt = "Application-Development for 21 century kids" className="main-content-image" src={AppDevThumbnail}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        Technology has been innovating new things every single day. This brings the necessity of having an application for all things around us. There are apps available for the simplest things like calculators to complex stuff like tuning an instrument. To build an app, coding is the first step that one should know. If the code is proper, your application can turn out to be a hit. App developers are in high demand as people have started depending on the technology way more than before. Therefore, the kids of this and coming generations need to learn coding to face the challenge of creating something new every day.
Techokids is among the first in Vadodara to develop the concept of online coding classes. Through 1:1 interactive sessions, students can learn, interact, solve their doubts and create something new every day.
 An application is required in all fields, be it teaching and artistic or in heavy industrial work. There are three main types of app development:
 1.Custom application development
 2.Rapid application development
 3.Low code application development

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is App Development Important?</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
          Application development is highly needed in businesses. It is mostly for the growth of the company and to connect with other people and competitors. It lets you work on different aspects of a company to boost sales at a rapid speed. 
Over the years, app development has gained quite a lot of popularity among youngsters as it is directly or indirectly linked with coding. 
Also, those who know programming languages are a huge plus as it lets you experiment with different application courses. 
For beginners, start the journey of app development by taking baby steps and learning how to code. 
The importance also consists of:
<ul>
    <li>Having access to various online platforms</li>
    <li>Reaching the target audience</li>
    <li>Lets you be efficient and effective</li>
    <li>To be able to reach the clients easily</li>
    </ul>
</p>
<br></br>
<p>
<h3>
  
        <b>How Coding Helps In App Development</b>
      </h3>
      <br></br>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <div className="text-center">
        <img  alt = "How Coding Helps In App Development" style = {{marginLeft:-30, maxWidth:750}} className = "main-content-image" src={appdev}></img>
      </div>
      <br></br>
        <p>
        Application development requires one to know the basics of coding and programming languages. Knowing python is the least one can ask when you are learning how to develop apps. You can start with python for beginners as it is the easiest and the quickest programming language that one can learn. 
To develop an app for iPad or iPhone, you need to learn objective C language and the Cocoa framework and use an integrated development environment called Xcode.
For Android, Java coding is in demand and the platforms used are Linux, Mac and Eclipse. 
Those who need guidance in building an app can enrol for coding classes, like Techokids, where you get qualified mentors who help you with the whole process. You can learn from scratch to the advanced levels.
</p>
      
</p>
         <br>
         </br> 
         <h3>
        <b>Steps Of Learning App Development</b>
      </h3>   
<br></br>
      <div className="text-center">
        <img  alt = "steps-of-learning-app-development"style = {{marginLeft:-30, maxWidth:750}} className = "main-content-image" src={appdev2}></img>
      </div> 
         <br></br>
         <p>
         Before starting with step one, one should know coding and programming languages. If not, then it would not be easy to build an app and introduce it to the audience. </p>
        

        <p>
          <b>
        Step 1: Pick an idea
       </b>
        </p>
        <p>The first step is to decide what application you want to develop and what work will be done by the same. The app can be about anything, a temperature checking device, a game or a social media platform. It should be based on your interest. If you are new at this, then start from scratch. Try choosing apps that are not so complex to create. Write the code properly that is identified by the computer. Once all of it falls in place, your application is ready to run.</p>
        <p><b>Step 2: Build the functionalities.</b></p>
        <p>Once you are clear with what to create, the next step is to build the code for all functions to be done by the app. If it is a small app, don’t add a lot of things in one. This will only make things complex for you and the users. Keeping it simple will help you to have fun and learn new things throughout the process. Start with an app where users can create a profile, change passwords and usernames, etc.</p> {/* <img style = {{marginLeft:70}}loading="lazy" src="https://images.squarespace-cdn.com/content/v1/56e460d7b09f956469fa7154/1565111323207-YZ1AMHQFSGY4M1T2EY8Q/ke17ZwdGBToddI8pDm48kHldqyjDwaeS7kYSmaCmglZ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QHyNOqBUUEtDDsRWrJLTmusP150YD-QtWqScwdS_85tP8joIlojSVzXUcummRsz2cF0ZJEme1wcEnRHcGyqT5/illustration_andre_levy_zhion_vector_outline_minimal_playegg_startup_spain_educational_toys_drawing_imagination_creativity_kid_creating_animation.gif" alt="Creativity" width="500" height="500" title="Creativity"></img> */}
        <p>
          <b>
        Step 3: Create A format
       </b>
        </p>
        <p>The next step is to create a format of how you want your app to look. Keep a note of the format and where you should keep your title or if it is a game, what kind of a background suits your theme, etc. 
This leads to better configuration of your app and lets the audience use it without any disturbance. 
</p>
        
        <br></br>
<h3>
        <b>App Development And Techokids</b>
      </h3> 
        
        
        <p>
        As mentioned above, coding is the first step to the development of anything related to Technology. Looking at the future, Technology will take over most of the manual work and prepare kids for the same. Techokids have come up with online coding classes where students learn and create. As for application development, having some knowledge of coding lets you learn it quicker. 
With expert mentors, you can have fun learning and experience like never before. Through 1:1 interactive sessions and hackathons, you can gain the confidence to compete with the world.
   {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
          <br></br>
         <div className="text-center">
        <img alt = "App development and techokids" style = {{marginLeft:-30, maxWidth:750}} className = "main-content-image" src={appdev3}></img>
      </div>
        <br></br>
        <h3>
            <b>
            Conclusion
            </b>
        
        </h3>


<p stye = {{textalign:"center"}}>From the start of the day till the end, you are surrounded by apps. There is an application for almost everything, but coding is something through which the application is made. Therefore, to get into a development field, apply for a course at Techokids and from the expert mentors, you can learn the basics and all the courses from scratch. 
</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer/>
    </div>
  );
};

