import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import webdev1 from "./../../Assets/Blogs/webdev1.jpg";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
import webdevfns from "./../../Assets/Blogs/webdevfns.png";

export const WebDevelopment = () => {
  const history = useHistory();
  
  return (
    <>
    <ScrollTop/>
    <Helmet>
      <title> Web Development For Kids | Steps To Learn Web Development | Techokids</title>
      <meta 
      name ="description" 
      content = "Does Coding Help In Web Development? Beginners should know that while working on web development, two versions of code are learned Front end code and Back end code. "/>
  <link rel = "canonical" href = "/blog/WebDevelopment"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content"> */}
        <h1 className="heading-content ">
        Introduction to Web Development
      </h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
            
            <div style = {{marginLeft:20}} className="d-flex flex-column">
             <div>
              <b>Deepali Meena</b>
            </div>
            <div>10 June 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>         </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img alt = "web-development for kids" className="main-content-image" src={webdev1} style = {{maxWidth:850}}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        Websites are a part of every profession you go in. It is not only for those computer freaks but is also required to keep a record of items, employees, and other work to make day-to-day life more manageable. 
Building a website is no rocket science, but one should also know how to maintain them. 
Coding comes into great use while building one. Through different programming languages, you can create a website of your choice. Who can decide the language to be used based on what web development you want to work on? Python, being one of the easiest languages to learn, works the best as it helps in designing and works on a trial and error basis. With technology overpowering manual work, websites are highly required by people in jobs and other grounds, and therefore it is one of the highest-paid careers worldwide. 
Looking at the current situation of Corona, a question that pops up in people's minds is, "how can children learn it while sitting at home"? Let us make it easy for you. Companies have developed various online learning platforms to learn web development and more right from their homes. Techokids is a platform where students can learn web development and coding from expert teachers and have fun in 1:1 interactive sessions. 


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is Web Development Important?</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
         Over the years, many businesses have missed out on an ample number of opportunities to flourish. One of the main reasons for the same is having less or zero knowledge of web development. Therefore entrepreneurs have come up with the solution of having those employees who have this skill.
Web development is trendy amongst students and helps them navigate pertinent information from the website and apply it in their projects. 
Web development is a big help to those struggling with SEO-based content. Through website designing, you can have the highest ranking for yourself on the search engine. It includes a bundle of features and can be used by all.
Students who know web development can also apply for web development internships and jobs to get the highest post based on their skills.
Other uses of web development are:

<ul>
    <li>Increases visual content online
</li>
    <li>Reaching the target audience</li>
    <li>Lets you be efficient and effective</li>
    <li>To be able to reach the clients easily</li>
    </ul>
</p>
<br></br>
<h3>
        <b>How Does Coding Help In Web Development?</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        
      
        <p>
        Coding is the first step to web development. To create any website, you need a code that the computer can crack. Coding works to design, develop and initiate a website. Line-based coding is the best for applications. 

</p>
         <p>
         All the websites that we see online are coded. It is essential as it allows you to install applications and icons to the dashboard. It understands the code and works accordingly. Beginners should know that while working on web development, two versions of code are learned. 
         </p>
         <ul>
           <li>
           Front end code
           </li>
          
          <li>
          Back end code
          </li>
           </ul> 
<p>
Front end codes can generally be used on platforms like HTML and CSS whereas the Back end codes are for the audience to use as it allows them to send messages, create a profile or add a picture. It can be applied on PHP and works with .net behind the address. 
In here, it's all like a movie. When you work on your code correctly, which is a backstage thing, your website will be a superhit. 

</p>
<br></br>

         <h3>
        <b>Steps To Learn Web Development</b>
      </h3>    
         
        
        <p>
        <b>Step 1: Start with patience.</b>
        </p>
        <p>In the field of web development, there might be scenarios where you might not get instant success. But that's okay. Having a little faith and patience can help. Having some coding knowledge can help as all the applications and websites require a code for them to run. 
You can have mentors by your side whenever you want. Techokids is the perfect platform as they have qualified mentors, and they solve doubts like no other. 
</p>

<p><b>Step 2: Know the programming languages.</b></p>
        <p>The next step would be to know programming languages. who can perform web development through two main languages</p>  <p>
       <ul>
         <li>Python</li>
         <li>HTML</li>
       </ul>
       <p>
       These are the easy ones to learn. Python being the popular one, is used by most coders as it helps find errors and correct them immediately. 
On the other hand, HTML lets you search the files and documents from the bundle and makes it easier to create web designs and applications. 

       </p>
     <b>  Step 3: Make a small website.
        </b>
        </p>
        <p>The final step would be to create a website on your own. Having experience can help you in the future, and there is nothing better than having built one. Start with creating small websites before jumping into complex ones. If you know how to code well, you can try building applications that work with a dashboard and other tools. 
</p>
        <br></br>
<h3>
  <b>Functions</b></h3>
  <br></br>
<div className = "text-center">
  <img src = {webdevfns} className = "other-image" alt = "web development functions"  />
</div>
<br></br>
<p>Web development brings along a lot of skills to be learned and functions to be performed. When you start learning this kind of subject at a young age, you give yourself problem-solving skills, leadership skills, and your mind starts working faster than other people. Web development can let you learn programming languages like HTML and CSS. You can create an error-free website for the audience and work with tools and features like never before. 
</p>
<p>Other functions of web development are:
<ul>
  <li>You can reduce the traffic online</li>

  <li>Collaborate with companies having similar products</li>
  <li>Present your ideas to the world</li>
  <li>Handle complex tools with excellent features.</li>

</ul>

</p>

<br></br>
<h3>
  <b>
        Web Development In Techokids
        </b>
      </h3> 
        
        
        <p>
        As stated above, Techokids is an online learning platform for students of 1st to 12th standard, where they can learn coding and related topics, web development being one of them. They teach web development to the students of 6th standard and above as it is categorized as advanced level learning. 
The online platform supports line-based coding, making it easier for kids to understand and learn from expert teachers. 
One should know basic coding to get the perfect grip on web development. The platforms used are HTML and CSS.
{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
       <br></br>
        <h3>
            <b>
            Conclusion
            </b>
        
        </h3>


<p>So, here we come to the end of our article. Web development is one of the most exciting topics under coding. Techokids provides you with numerous fun learning and free and certified demo classes where 1:1 sessions with expert teachers can help you learn and grow. In one session, you can create a small website that can be a massive achievement for you. What are you waiting for? Go and book your slots now.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

