import React from "react";
import ".././Core/Home/Home.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
const CourseCard = ({item, className}) => {
  const history = useHistory();
  return (
    <div className={`homepage__course__card ${className}`}
     onClick={() => history.push(item.url)}
    >
      {/* <LazyLoadImage
        alt={item.text}
        src={item.img}
        effect="opacity"
        className="profile__pic"
      /> */}
      <img src={item.img} alt={item.text} width = {item.width} height= {item.height} /> <br />
      <h4 className="homepage__course__name">{item.text}</h4>
    </div>
  );
};

export default CourseCard;
