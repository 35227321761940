import React, { useState } from "react";
import ClassStatusModal from "./Modal/StatusModal";
import { useHistory } from "react-router-dom";


const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);

  // console.log("userData", userData);
  const history = useHistory();
  const [successModal, setSuccessModal] = useState({
    successModal: true,
    successText: "",
  });
  

  const onModalBtnClick = (btnItem) => {
    //setmodalBtnClickedItem(btnItem);
    setShowModal(!showModal);
  };

  return (
      <div >
       
      </div>
    
  );
};

export default Dashboard;
