import React, { useState, useEffect } from "react";
import "../Styles/navbar.css";
import Logo from "../Assets/navbar/logo.png";
import LogoSticky from "../Assets/navbar/logo-sticky.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import MenuIcon from "../Assets/navbar/menu.png";
import CloseIcon from "../Assets/navbar/close.png";
import ExternalLink from "./ExternalLink";

export default function Navbar(/*props*/) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const subMenuOptions = [
    { id: "grade_1_3", text: "Grade 1-3" },
    { id: "grade_4_7", text: "Grade 4-7" },
    { id: "grade_8_12", text: "Grade 8-12" },
  ];
  let nextItem1 = document.getElementsByClassName("homepage__base")[0];

  // adjust the next subsequent item for spacing
  if (nextItem1) {
    nextItem1.setAttribute("style", "padding-top: 150px !important");
    if (isOpen)
      nextItem1.setAttribute(
        "style",
        `padding-top: ${isSubMenuOpen ? "400px" : "300px"} !important`
      );
  }

  // toggles the view of navbar on scroll!
  useEffect(function () {
    window.addEventListener("scroll", (/*event*/) => {
      if (window.location.pathname !== "/") {
        if (window.scrollY > 100) {
          let item = document.getElementById("top_desktop_navbar");
          if (!item) return;
          item.classList.add("sticky__navbar");
        } else {
          let item = document.getElementById("top_desktop_navbar");
          if (!item) return;
          item.classList.remove("sticky__navbar");
        }
      }
    });
  }, []);

  return (
    <header className="top__navbar " >
      <div className="top__desktop__navbar" id="top_desktop_navbar" style= {{width:"100%"}}>
        <div className="top__navbar__brand" >
          <ExternalLink to="/" keepOrigin={true}>
            <img height = "300" width = "1080" className="top__navbar__brand__image" src={Logo} alt="logo" />
            <img
               className="top__navbar__brand__sticky__image"
              
              // className="top-nav"
              src={LogoSticky}
              alt="logo"
              width = "1080"
              height = "301"
            />
          </ExternalLink>
        </div>
        <div className="top__navbar__options">
          {isSubMenuOpen && (
            <div className="top__navbar__submenu">
              <div className="top__navbar__submenu__tip"></div>
              <div className="top__navbar__submenu__items">
                {subMenuOptions.map((item, i) => (
                  <ExternalLink
                    to={"/#" + item.id}
                    keepOrigin={true}
                    key={i}
                    onClick={(/*event*/) => {
                      let divs = document.getElementsByClassName(
                        "curriculum__pricing"
                      );
                      if (divs.length > 0) divs[0].setAttribute("id", item.id);
                      setIsSubMenuOpen(false);
                    }}
                  >
                    {item.text}
                  </ExternalLink>
                ))}
              </div>
            </div>
          )}
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault();
              setIsSubMenuOpen(!isSubMenuOpen);
            }}
          >
            Curriculum <FontAwesomeIcon icon={faChevronDown} />
          </a>
          <ExternalLink to="/about-us" keepOrigin={true}>
            About us
          </ExternalLink>
          <ExternalLink to="/blog">Blog</ExternalLink>
          <ExternalLink to="/refer-earn" keepOrigin={true}>
            Refer & Earn
          </ExternalLink>
        </div>
        <div className="top__navbar__buttons">
          <ExternalLink to="/login" keepOrigin={true}>
            <button className="top__navbar__login">Login</button>
          </ExternalLink>
          <ExternalLink to="/book-trial" keepOrigin={true}>
            <button className="top__navbar__bookatrial">
              Book free trial now
            </button>
          </ExternalLink>
        </div>
      </div>
      <div className="top__mobile__navbar">
        <div className="top__mobile__navbar__header">
          <div>
            <img
              className="top__mobile__navbar__toggler"
              src={isOpen ? CloseIcon : MenuIcon}
              alt="menuIcon"
              onClick={(/*event*/) => setIsOpen(!isOpen)}
            width = "350"
            height = ""
            />
          </div>
          <div
            className="top__mobile__navbar__brand__container"
            onClick={() => (window.location.href = "/")}
          >
            <img width = "1080" height = "300" className="top__mobile__navbar__brand" src={Logo} alt="logo" />
          </div>
        </div>
        {isOpen && (
          <div className="top__mobile__navbar__content">
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                setIsSubMenuOpen(!isSubMenuOpen);
              }}
            >
              {/* Curriculum <FontAwesomeIcon icon={isSubMenuOpen ? faChevronUp : faChevronDown} /> */}
              Curriculum <FontAwesomeIcon icon={faChevronDown} />
            </a>
            {isSubMenuOpen && (
              <div className="top__mobile__navbar__submenu">
                {subMenuOptions.map((item, i) => (
                  <ExternalLink
                    to={"/#" + item.id}
                    keepOrigin={true}
                    key={i}
                    onClick={(/*event*/) => {
                      let divs = document.getElementsByClassName(
                        "curriculum__pricing"
                      );
                      if (divs.length > 0) divs[0].setAttribute("id", item.id);
                      setIsSubMenuOpen(false);
                    }}
                  >
                    {item.text}
                  </ExternalLink>
                ))}
              </div>
            )}
            <ExternalLink to="/about-us" keepOrigin={true}>
              About us
            </ExternalLink>
            <ExternalLink to="/blog">Blog</ExternalLink>
            <ExternalLink to="/refer-earn" keepOrigin={true}>
              Refer & Earn
            </ExternalLink>
            <div className="top__navbar__buttons">
              <ExternalLink to="/login" keepOrigin={true}>
                <button className="top__navbar__login">Login</button>
              </ExternalLink>
              <ExternalLink to="/book-trial" keepOrigin={true}>
                <button className="top__navbar__bookatrial">
                  Book free trial now
                </button>
              </ExternalLink>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
