import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import arvrthumbnail from "./../../Assets/Blogs/arvrthumbnail.jpg";
import arvr2 from "./../../Assets/Blogs/arvr2.jpg";
import arve3 from "./../../Assets/Blogs/arve3.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
export const ArVr = () => {
  const history = useHistory();
  return (
    <>
    <Helmet>
      <title>  AR and VR |  Augmented Reality and Virtual Reality | Techokids </title>
      <meta 
      name ="description" 
      content = "Pokemon Go was the first AR game released all over the place in 2016. It demanded the people capture the aliens and look for stuff by going around the area. "/>
  <link rel = "canonical" href = "/blog/arvr"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" /> */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
         <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img> 
        {/* <div className="heading-content "> */}
        <h1 className="heading-content ">Virtual & Augmented Reality for 3D</h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
                  
        <img  alt = "Deepali Meena" style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
          
          <div  className="d-flex flex-column inner-blogs-name">
           <div>
              <b>Deepali Meena </b>
            </div>
            <div>15 May 2021 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
          {/* <img className="icon" src={WhatsappIcon}></img>
          <img className="icon twitter" src={TwitterIcon}></img>
          <img className="icon facebook" src={FacebookIcon}></img> */}
        
          <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <a
              href='https://wa.me/9198918508'
              target = "_blank"
              rel = "noopener noreferrer"> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </a>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>
           
        
        </div>
      </div>
      
      
      
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img alt = "augmented reality and virtual reality" style = {{maxWidth:850}} className="main-content-image" src={arvrthumbnail}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
    
        <p>
        Over the years, the definition of technology has been completely changed by new inventions and creativity. Augmented Reality and Virtual Reality have an enormous contribution to them. AR and VR let you escape Reality only through a pair of glasses. However, both are very different in their ways and have created a special place in people's minds, especially the youngsters. 
Augmented Reality lets you add in what you are already seeing. That is, it adds to the Reality, and you gain more information about the same.
On the other hand, Virtual Reality lets you slip into a completely different world through a headset and powerful technology. 
The question that often pops in people's minds is, how does this work? Through multiple coding lessons and programming languages, you can create AR and VR by yourself. To help you, many companies have come up with online coding classes where students fascinated by the idea of technology can enrol and learn from the best teachers. Techokids is a platform where you can learn all of it and more. Beginners can start from scratch and learn the basics of AR and VR.


          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Why Is AR And VR Important?</b>
      </h3>
      <br></br>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
          <div className="text-center">
        <img  alt = "Why is ar vr important" width = "700" height = "350" style = {{maxWidth:680} } className = "other-image" src={arvr2}></img>
      </div>
      <br></br>
     
         Pokemon Go was the first AR game released all over the place in 2016. It demanded the people capture the aliens and look for stuff by going around the area. This was applied by a lot of companies later either for fun or as a marketing strategy. Snapchat is one of those companies that chose augmented Reality to let young audiences explore all the app features and have fun.
Since the time it has been in the market, VR has been on the buying list of students. The USP of the product has created a lot of excitement amongst the people to at least try it once. 
Virtual Reality works best when you need to get out from all the chaos you are going through. 
Both AR and VR have created a bond between the digital world and Reality. However, this has caused a challenging future for kids of today's generation to deal with such heavy technology. 
Therefore, the kids need to know the basics of coding right from the age of six. To get the proper guidance, online classes are always at your service.

</p>

<br></br>
<h3>
        <b>How Coding Helps In AR And VR</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <br></br>
        <div className = "text-center">
        <iframe  alt = "video" className = "main-content-image" style = {{ maxWidth:700}} width="700" height="350" src="https://www.youtube.com/embed/QhYyyMitxQ0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <p>
          <br></br>
        Augmented Reality and Virtual Reality have gained a lot of popularity in no time. The reasons are that the idea was fresh and never used before, it lets people think beyond the real world, and the makers' creativity was at its peak.
The base to AR and VR is coding. Without the computer understanding the code, it would have been impossible to create a masterpiece of this kind. Along with coding, one should know programming languages that can make a real-world yet natural. 
Beginners should start from Python. It is the most accessible language of all and lets you develop the technology step by step. Other languages that play a significant role in creating augmented and virtual Reality are:

<ul>
    <li>
        C++
    </li>
    <li>
        Java 
    </li>
    <li>
        Javascript
    </li>
    <li>
        C#
    </li>
    <li>
        Swift
    </li>
    <li>
        
        Visual Development Tools
    </li>
</ul>
All these languages are bound to AR and VR and can be used in multiple technologies where one can do wonders.
</p>         <br>
         </br> 
         <h3>
        <b>Step Of Learning AR and VR</b>
      
      </h3>    
      <br></br>
      <div className="text-center">
        <img  alt ="Step Of Learning AR and VR"  style = {{ maxWidth:700}} className = "main-content-image" src={arve3}></img>
      </div>
     
<br></br>
<br></br>
        <p>
          <b>       Step 1: One should know to code
        </b>
        </p>
        <p>Coding is the base of technology. Who can build any new machinery through coding? Over the years, AR and VR have gained a lot of popularity, but this would not have been possible if computers could not decode the typed code. Therefore knowing the basics of coding, programming and programming languages is essential. 
For kids, knowing how to code from the age of 6 is essential as what the future holds for them is all about technology.
</p>
        <p>
<b>          Step 2: Understand the functions and tools.
</b>
</p>
        <p>The next step is to build the functionalities that can use in the headsets to create a magical world away from Reality. Knowing programming languages can be a massive plus as it can be easier to handle the tools and work with them. Tools are usually to add designs, colours and background to give you the feel of some other place.
        </p>
        <p>
          <b> Step 3: Work on a project
          </b>
 
        </p>
        <p>To get the experience of what goes behind the whole idea of AR and VR, you need to work on the projects. Go step by step and start with a small project. To get the guidance, you can apply for paid courses in online platforms like Techokids, wherewith expert mentors, and you can build a parallel universe for yourself. </p>

    <br></br>    
<h3>
        <b>AR And VR With Techokids</b>
      </h3> 
        
        
        <p>
        Techokids help kids bring out the best of their creativity. Creativity is one of the most encouraging ways a child can learn about coding. From the beginning, children can learn about machines, codes and all that goes behind making one. Virtual Reality is something you would have heard a lot recently. Coding can help you go through the process behind making augmented, and virtual reality comes to life. {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
        <br></br>
        <h3>
            <b>
            Conclusion
            </b>
        
        </h3>


<p>Over the years, the existence of virtual Reality was not known by a lot of people. However, with the increase in the learning of coding, virtual reality has come to life. Thanks to online platforms like Techokids, which take up the challenge of educating people about coding and other things that create something worth the wait. For beginners, you can start learning from scratch from the experts who will help you with all the doubts and more and also let you build an app or website or game of your choice.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

