import React, {useState} from "react";
import img1 from "../../Assets/Artboard 1.png";
import img2 from "../../Assets/Mobile Version Image.png";
import gift from "../../Assets/Gift.png";
import img3 from "../../Assets/Artboard 8.png";
import img4 from "../../Assets/Artboard 9.png";
import img5 from "../../Assets/Artboard 10.png";
import "../../Styles/Referearn.css";
import TnC from "./TnC";
import Faq from "./Faq";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../../Components/Navbar";
import Fotter from "../../Components/Footer";

const ReferEarn = () => {
  let [tnc, setTnC] = useState(true);

  const howWorks = [
    {
      img: img3,
      head: "Share your referral code",
      info: "Invite Friends & Family (by sharing your unique link)",
    },
    {
      img: img4,
      head: "Earn cash rewards",
      info: "They enrol, you start getting rewards",
    },
    {
      img: img5,
      head: "Win MacBook",
      info: "Accumulate upto 10 referrals & Win Macbook Pro",
    },
  ];

  return (
    <>
    <Helmet>
      <title> Best Live Online Coding Classes For Kids |Refer & Earn |TechoKids</title>
       <meta 
      name ="description" 
      content = "Refer & Earn offers you to invite your friends to join TechoKids and you’ll get a MacBook plus 10% off on your Enrolled Coding Courses based on TechoKids Plans. "/> 
  <link rel = "canonical" href = " https://www.techokids.com/refer-earn"/>
  <meta 
  name = "keywords" 
  content = " refer and earn, referral program, cash rewards and MacBook, win cash rewards, win MacBook, refer and earn sites, share your unique referral link, earn cash rewards, earn MacBook, win MacBook, enroll our courses, win MacBook pro, referral program eligibility, free trial, giveaway" />
  </Helmet>
      <Navbar />
      <div className="refer-earn">
        <h1 className="home-header">Refer and Earn</h1>
        <img
          src={window.innerWidth <= 600 ? img2 : img1}
          width="94%"
          style={{margin: "3%"}}
          alt=""
        />
        <div className="content">
          <div
            className="content-info"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <div id="invite-login-div">
              <h2 style={{fontSize: "2.5em"}}>
                Invite your friends to TechoKids and <br />
                Win Cash Rewards & MacBook!
              </h2>
              <p>Login to generate your unique referral link</p>
              <br></br>
              <Link to="/login">
                <button id="login">Login</button>
              </Link>
            </div>
            <img
              id="gift"
              src={gift}
              style={{float: "right", margin: "1em"}}
              width="auto"
              height="200px"
              alt=""
            />
          </div>
          <div className="content-info">
            <h2 style={{fontSize: "2.5em"}}>How it works?</h2>
            {howWorks.map((item, index) => {
              return (
                <div className="works-list" key={index}>
                  <img
                    src={item.img}
                    style={{width: "150px", marginRight: "2em"}}
                    alt={item.head}
                  />
                  <div>
                    {item.head === "Win MacBook" ? (
                      <h3 style={{fontSize: "1.5rem"}}> {item.head} </h3>
                    ) : (
                      <h4>{item.head}</h4>
                    )}

                    <h5 style={{fontSize: "22px"}}> {item.info} </h5>
                  </div>
                </div>
              );
            })}
          </div>
          <div id="tnc">
            <div className="button-group">
              <button
                id="tc"
                className={tnc ? "active" : ""}
                onClick={(event) => setTnC(true)}
              >
                T&C
              </button>
              <button
                id="faq"
                className={tnc ? "" : "active"}
                onClick={(event) => setTnC(false)}
              >
                FAQs
              </button>
            </div>
            {tnc ? <TnC /> : <Faq />}
          </div>
        </div>
      </div>
      <Fotter />
    </>
  );
};

export default ReferEarn;
