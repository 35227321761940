import React from "react";
import "../../Styles/BookATrial/bookDateTime.css";
import WhiteLogo from "../../Assets/bookatrail/white-logo.png";
import BotIcon from "../../Assets/bookatrail/bot-icon.png";
import {useEffect} from "react";
import {useState} from "react";

// functional component
export default function BookDateTime(props) {
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsRedirect(true);
    }, 20000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const disabledTimes = [];
  const {
    dateIndex,
    setDateIndex,
    timeIndex,
    setTimeIndex,
    dates,
    times,
    onBookDateTime,
    havePC,
  } = props;

  const bookDateView = () => {
    return (
      <div className="book__date__time__container">
        <div className="book__date__time__header">
          <img src={WhiteLogo} alt="whiteLogo" />
        </div>
        <div className="book__date__time__panel">
          <h1>Schedule your 60min FREE Trial Class</h1>
          <div className="book__date__time__panel-items">
            <div className="book__date__time__left-panel">
              <img src={BotIcon} alt="botIcon" />
              <h1>Congratulations & Welcome to Techokids</h1>
            </div>
            <div className="book__date__time__right-panel">
              <div className="book__date__time__date-picker">
                <span>Select a date</span> <br />
                <div className="book__date__time__date-picker-row">
                  {(function () {
                    let items = [];
                    for (let i = 0; i < 6; ++i) {
                      let item = (
                        <button
                          className={
                            dateIndex === i
                              ? "book__date__time__date-picker-selected"
                              : ""
                          }
                          onClick={(event) => setDateIndex(i)}
                          key={i}
                        >
                          {dates[i]}
                        </button>
                      );
                      items.push(item);
                    }
                    return items;
                  })()}
                </div>
              </div>
              <div className="book__date__time__time-picker">
                Select a time (Asia, Calcutta)
                <div className="book__date__time__time-picker-row">
                  {(function () {
                    let items = [];
                    for (let i = 0; i < 12; ++i) {
                      let item = (
                        <button
                          className={
                            timeIndex === i
                              ? "book__date__time__date-picker-selected"
                              : ""
                          }
                          onClick={(/*event*/) => setTimeIndex(i)}
                          key={i}
                          disabled={disabledTimes.indexOf(times[i]) !== -1}
                        >
                          {times[i]}
                        </button>
                      );
                      items.push(item);
                    }
                    return items;
                  })()}
                </div>
              </div>

              <div className="book__date__time__submit-container">
                <button onClick={onBookDateTime}>Schedule Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const {email, token} = JSON.parse(localStorage.getItem("tk_jwt"));
  const dontHavePcView = () => {
    return (
      <div className="booking__congratulations__container">
        {isRedirect && (
          <span style={{display: "none"}}>
            {" "}
            {
              (window.location.href = `https://student.techokids.com/dashboard/?email=${email}&token=${token}`)
            }{" "}
          </span>
        )}
        <div className="booking__congratulations__wrapper_nopc">
          <div className="booking__congratulations__nopc">
            <h1>Thank you for Registration</h1>
            <p className="booking__congratulations__text__nopc">
              Whoops! You don't have a laptop to start learning to code.
              Currently we require you to have access to desktop/laptop for
              taking our Free 60 minute online coding class
              <br />
              <br />
              No need to worry at all! We're with you. We are offering FREE
              Online <br /> Coding Classes For Kids worth Rs. 5000/- <br />{" "}
              Watch our free courses & tutorials in our dashboard
            </p>
            <p className="wait_text_nopc load_text">
              You are being redirected to your dashboard, Be patient
            </p>
          </div>
        </div>
      </div>
    );
  };
  // handle the component
  return <>{havePC ? bookDateView() : dontHavePcView()}</>;
}
