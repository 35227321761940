import React from "react";
import "../../Styles/BookATrial/loginWithOTP.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

// functional component
export default function LoginWithOTP(props) {
  let {
    digit1,
    setDigit1,
    digit2,
    setDigit2,
    digit3,
    setDigit3,
    digit4,
    setDigit4,
    digit5,
    setDigit5,
    digit6,
    setDigit6,
    onValidateOTP,
    onCloseValidation,
    OTPerror,
  } = props;

  // handles the on change listener
  const onChange = function (event, setter) {
    let value = event.target.value;
    if (value.length > 1) return;
    if (value !== "" && (value < "0" || value > "9")) return;
    setter(value);
  };

  // jumps to given Id
  const jumpToId = function (id) {
    if (id < 1 || id > 6) return;
    let item = document.getElementById("digit" + id);
    if (!item) return;
    item.focus();
    item.select();
  };
  // handles the key typed event
  const onKeyDown = function (event) {
    let value = event.target.value;
    let id = event.target.getAttribute("id");
    let prevId = parseInt(id.substring(5)) - 1;
    let nextId = parseInt(id.substring(5)) + 1;
    if (event.keyCode === 8 && value === "") return jumpToId(prevId);
    if (value !== "" && "0" <= value && value <= "9") return jumpToId(nextId);
  };

  // ui to be rendered
  return (
    <div className="login__with__otp__page">
      <div className="login__with__otp__form-container">
        <div className="close-button-container">
          <button onClick={onCloseValidation}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="login__with__otp__header">Counselling Code</div>
        <div className="login__with__otp__subheader">
        Code has been sent to your phone
        </div>
        <div className="login__with__otp__input-container">
          <input
            id="digit1"
            type="number"
            value={digit1}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit1)}
          />
          <input
            id="digit2"
            type="number"
            value={digit2}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit2)}
          />
          <input
            id="digit3"
            type="number"
            value={digit3}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit3)}
          />
          <input
            id="digit4"
            type="number"
            value={digit4}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit4)}
          />
          <input
            id="digit5"
            type="number"
            value={digit5}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit5)}
          />
          <input
            id="digit6"
            type="number"
            value={digit6}
            onKeyDown={onKeyDown}
            onChange={(event) => onChange(event, setDigit6)}
          />
        </div>
        <h6 className="otp_err_text">{OTPerror && "Invalid OTP"}</h6>
        <div className="login__with__otp__submit-container">
          <button onClick={onValidateOTP}>Submit</button>
        </div>
      </div>
    </div>
  );
}
