import React from "react";
import TkButton from "./TkButton";
import "./components.css";

const ContactInfoItem = ({img, title, text, action}) => {
  return (
    <>
      <div className="contact_info_item">
        <span className="contact_info_icon">
          <img src={img} alt="" />
        </span>

        <div className="contact_info_info">
          <span className="contact_info_title">
            <b>{title}</b>
          </span>
          <span className="contact_info_text">{text}</span>
          {action && (
            <TkButton
              name={action.title}
              style={{backgroundColor: "#00B23B", width: 140}}
              _class={"sm mt-2"}
              _onBtnClick={action.handle}
              Ricon={action.icon}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactInfoItem;
