export const getPrice = (countryCode) => {
  switch (countryCode) {
    case "IN":
      return {
        symbol: "\u20B9",
        price: [59999, 114999, 164999],
        price1to4: [35988, 71982, 119976],
        monPrice: [5000, 6388, 6874],
        monPrice1to4: [2999, 3999, 4999],
        discountPrice: [59999, 114999, 164999],
        discountPriceGroup: [35988, 71982, 119976],
        off: [6, 12, 18],
        perSession: [1250, 1200, 1146],
      };
    case "GB":
      return {
        symbol: "\u00A3",
        price: [170, 977, 2004],
        monPrice: [15, 76, 150],
        discountPrice: [159, 859, 2299],
        off: [6, 12, 18],
        perSession: [20, 18, 16],
      };

    default:
      return {
        symbol: "\u0024",
        price: [299, 1636, 4390],
        monPrice: [15, 76, 150],
        discountPrice: [279, 1439, 3599],
        off: [6, 12, 18],
        perSession: [35, 30, 25],
      };
  }
};
