import axios from "axios";
const dev = "http://localhost:8000";
const prod = "https://techokids.onrender.com";
axios.defaults.baseURL = prod;

// makes an API request for scheduleTrial
export const bookTrial = function (
  parentName,
  parentEmail,
  parentPhone,
  hasLaptop,
  studentName,
  studentGrade,
  role,
  refferalCode
) {
  let payload = {
    parentName,
    email: parentEmail,
    parentPhone,
    hasLaptop,
    studentName,
    name: studentName,
    studentGrade,
    grade: studentGrade,
    role,
    referredBy: refferalCode,
  };
  return new Promise((resolve, reject) => {
    axios
      .post("/register/new", payload)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};



// makes an API request for scheduleTrial
export const CounsellingFn = function (
  parentName,
  parentEmail,
  parentPhone,
  studentName,
  counsellorName,
  grade,
  counsellingDateAndTime
  // role
  // refferalCode
) {
  let payload = {
    parentName,
    parentEmail,
    parentPhone,
    // hasLaptop,
    studentName,
    counsellorName,
    grade,
    counsellingDateAndTime
    // role,
    // referredBy: refferalCode,
  };
  return new Promise((resolve, reject) => {
    axios
      .post("/counselling/register-counselling", payload)
      .then((response) => {resolve(response); 
        let localData = { refCode: response.data.scholarshipCode };
        localStorage.setItem("ref-code", JSON.stringify(localData));})
      .catch((err) => reject(err));
  });
};


// makes an API request to verify OTP for counselling page
export const verifyCounsellingOTP = function (email, otp, token) {
  let payload = { email, otp, token };
  return new Promise((resolve, reject) => {
    axios
      .post("/counselling/verify-counselling", { email, otp, token })
      .then((response) =>{ resolve(response);
        let localData = { token: response.data.token };
        localStorage.setItem("tk_tok", JSON.stringify(localData));
      })
      .catch((err) => reject(err));
  });

};

//for submit counselling modal
export const modalSubmit = function (type, amount , plan, date, token) {
  let payload = { token , type, amount , plan};
  const tok = token;
  
  const config = {
    headers: { "bearer-token" : tok }
  };
  return new Promise((resolve, reject) => {
    axios
      .put("/counselling/update-counselling", { type, amount , plan, date}, config)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};



// makes an API request to verify OTP
export const verifyScheduleTrialOTP = function (email, otp, token) {
  let payload = { email, otp, token };
  return new Promise((resolve, reject) => {
    axios
      .post("/register/verify", { email, otp, token })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};



// makes an API request to schedulleTrial
export const scheduleTrial = function (
  studentName,
  studentEmail,
  bookingTime,
  bookingDate,
  parentName,
  grade,
  slot,
  contact
) {
  let payload = {
    studentName,
    studentEmail,
    bookingTime,
    bookingDate,
    parentName,
    grade,
    slot,
    contactNo: contact,
  };
  return new Promise((resolve, reject) => {
    axios
      .post("/booking/scheduleTrail", payload)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
