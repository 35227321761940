import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const Timer = () => {
  const history = useHistory();

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  // console.log(setHours)
  // console.log(setMinutes)
  // console.log(setSeconds)

  let timer;

  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds + 1);

      if (seconds === 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
      if (minutes === 59) {
        setHours(hours + 1);
        setMinutes(0);
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  const stop = () => {
    console.log("initiate");
    clearInterval(timer);
    alert("session has been end.");
    history.push("/");
  };


  return (
    <div className="counter">
      <section className="timer-container">
        <section className="timer">
          <div className="clock">
            <section>
              <p>{hours < 10 ? "0" + hours : hours}</p>
              <small>Hours</small>
            </section>{" "}
            <span className="dots">:</span>
            <section>
              <p>{minutes < 10 ? "0" + minutes : minutes}</p>
              <small>Minutes</small>
            </section>{" "}
            <span className="dots">:</span>
            <section>
              <p>{seconds < 10 ? "0" + seconds : seconds}</p>
              <small>Seconds</small>
            </section>{" "}
          </div>
        </section>
      </section>

      {/* <span className='button'>
            <button className='btn' onClick={stop}>End session</button>
    </span> */}

     
    </div>
  );
};

export default Timer;
