import React, { useEffect, useState } from "react";
import "../Styles/pricing-card.css";
import BeginnerImage1 from "../Assets/pricing-card/beginner1.png";
import BeginnerImage2 from "../Assets/pricing-card/beginner2.png";
import BeginnerImage3 from "../Assets/pricing-card/beginner3.png";
import AdvanceImage1 from "../Assets/pricing-card/advance1.png";
import AdvanceImage2 from "../Assets/pricing-card/advance2.png";
import AdvanceImage3 from "../Assets/pricing-card/advance3.png";
import MasterImage1 from "../Assets/pricing-card/master1.png";
import MasterImage2 from "../Assets/pricing-card/master2.png";
import MasterImage3 from "../Assets/pricing-card/master3.png";
import BuildIcon1 from "../Assets/pricing-card/build1.png";
import BuildIcon2 from "../Assets/pricing-card/build2.png";
import BuildIcon3 from "../Assets/pricing-card/build3.png";
import BuildIcon4 from "../Assets/pricing-card/build4.png";
import BuildIcon5 from "../Assets/pricing-card/build5.png";
import BuildIcon6 from "../Assets/pricing-card/build6.png";
import BuildIcon7 from "../Assets/pricing-card/build7.png";
import BuildIcon8 from "../Assets/pricing-card/build8.png";
import BuildIcon9 from "../Assets/pricing-card/build9.png";
import BuildIcon10 from "../Assets/pricing-card/build10.png";
import Achievement1 from "../Assets/pricing-card/achievement1.png";
import Achievement21 from "../Assets/pricing-card/achievement2-1.png";
import Achievement22 from "../Assets/pricing-card/achievement2-2.png";
import Achievement23 from "../Assets/pricing-card/achievement2-3.png";
import Achievement31 from "../Assets/pricing-card/achievement3-1.png";
import Achievement32 from "../Assets/pricing-card/achievement3-2.png";
import DownloadIcon from "../Assets/pricing-card/download.png";
import DollarIcon from "../Assets/pricing-card/dollar.png";
import CurriculumGrade_1_3 from "../Assets/pricing-card/Company Brochure copy.pdf";
import CurriculumGrade_4_7 from "../Assets/pricing-card/Company Brochure copy.pdf";
import CurriculumGrade_8_12 from "../Assets/pricing-card/Company Brochure copy.pdf";
import { useHistory } from "react-router-dom";
import ExternalLink from "./ExternalLink";
import { CountryContext } from "../Routes";
import { useContext } from "react";
import { getPrice } from "./Helper";
import Modal from "react-modal";
import PricingModal from "./PricingModal";

// formats a number in indian format
const formatPrice = function (x) {
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

// gets the index of the curriculum based on url
const getCurriculumIndex = function () {
  let href = window.location.href;
  let origin = window.location.origin;
  let items = href.split(origin + "/#");
  if (items.length > 1) {
    let id = items[1];
    if (id === "private_class_grade_1_3") return 0;
    if (id === "private_class_grade_4_7") return 1;
    if (id === "private_class_grade_8_12") return 2;
    if (id === "group_class_grade_1_3") return 0;
    if (id === "group_class_grade_4_7") return 1;
    if (id === "group_class_grade_8_12") return 2;
  }
  return 0;
};

// gets the index of the curriculum based on url
const getCurriculumIndexstruct = function () {
  let href = window.location.href;
  let origin = window.location.origin;
  let items = href.split(origin + "/#");
  if (items.length > 1) {
    let id = items[1];
    if (
      id === "private_class" ||
      id === "private_class_grade_1_3" ||
      id === "private_class_grade_4_7" ||
      id === "private_class_grade_8_12"
    )
      return 0;
    if (
      id === "group_class" ||
      id === "group_class_grade_1_3" ||
      id === "group_class_grade_4_7" ||
      id === "group_class_grade_8_12"
    )
      return 1;
  }
  return 0;
};

// component
export default function PricingCard(props) {
  let money = 0;
  let dMoney = 0;
  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => {
    setModalOpen(false);
  };

  const handleClickOpen = () => {
    setModalOpen(!modalOpen);
  };
  //
  const [gradeIndex, setGradeIndex] = useState(getCurriculumIndex());
  const [gradeIndexstruct, setGradeIndexstruct] = useState(
    getCurriculumIndexstruct()
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [more2, setMore2] = useState(true);
  const [more3, setMore3] = useState(true);
  const [more1, setMore1] = useState(true);
  // console.log("props at price card",props);
  const countryCode = useContext(CountryContext);

  const cards = ["Grade 1-3", "Grade 4-7", "Grade 8-12"];
  const Structure = ["Private Classes", "Group Classes"];
  const subtitle = ["(1 Teacher & 1 Student)", "(1 Teacher & upto 4 Students)"];
  const ids = ["grade_1_3", "grade_4_7", "grade_8_12"];
  const color0 = "#00b4db",
    color1 = "#f34444",
    color2 = "#e55ee5";
  let origin = window.location.origin;
  const locationsStructure = [
    origin + "/#private_class",
    origin + "/#group_class",
  ];
  const locationspc = [
    origin + "/#private_class_grade_1_3",
    origin + "/#private_class_grade_4_7",
    origin + "/#private_class_grade_8_12",
  ];
  const locationsgc = [
    origin + "/#group_class_grade_1_3",
    origin + "/#group_class_grade_4_7",
    origin + "/#group_class_grade_8_12",
  ];
  const beginnerImage = [BeginnerImage1, BeginnerImage2, BeginnerImage3];
  const advanceImage = [AdvanceImage1, AdvanceImage2, AdvanceImage3];
  const masterImage = [MasterImage1, MasterImage2, MasterImage3];
  const build1 = [BuildIcon1, BuildIcon5, BuildIcon9];
  const build2 = [
    [BuildIcon2, BuildIcon3],
    [BuildIcon6, BuildIcon7],
    [BuildIcon10, BuildIcon8],
  ];
  const build3 = [
    [BuildIcon4, BuildIcon5],
    [BuildIcon3, BuildIcon8],
    [BuildIcon4, BuildIcon3],
  ];
  const achievement2 = [Achievement21, Achievement22, Achievement23];
  const classes = [48, 96, 144];

  let symbol = getPrice(countryCode).symbol;
  let price = getPrice(countryCode).price;
  let price1to4 = getPrice(countryCode).price1to4;
  let monPrice = getPrice(countryCode).monPrice;
  let monPrice1to4 = getPrice(countryCode).monPrice1to4;
  let discountPrice = getPrice(countryCode).discountPrice;
  let discountPriceGroup = getPrice(countryCode).discountPriceGroup;
  let off = getPrice(countryCode).off;
  let perSession = getPrice(countryCode).perSession;

  let history = useHistory();
  // downloads the curriculum
  const onDownloadCurriculum = function (event, toDownload) {
    event.stopPropagation();
    let parent = document.getElementById(ids[gradeIndex]);
    let link = document.createElement("a");
    link.setAttribute("download", "TechoKids-curriculum.pdf");
    link.setAttribute("href", toDownload);
    parent.appendChild(link);
    link.click();
    parent.removeChild(link);
  };

  let indexstruct = getCurriculumIndexstruct();
  if (gradeIndexstruct !== indexstruct) setGradeIndexstruct(indexstruct);
  let index = getCurriculumIndex();
  if (gradeIndex !== index) setGradeIndex(index);

  let body = document.getElementsByTagName("BODY")[0];
  let width = body.offsetWidth;

  useEffect(() => {
    // window.location.reload();
  }, [countryCode]);

  useEffect(() => {
    window.innerWidth <= "990" ? setAllMore(false) : setAllMore(true);
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleResize = () => {
    let bodyElement = document.getElementsByTagName("BODY")[0];
    let newWidth = bodyElement.offsetWidth;
    if (newWidth != width) {
      window.innerWidth <= "990" ? setAllMore(false) : setAllMore(true);
    }
  };

  const setAllMore = (type) => {
    setMore1(type);
    setMore2(type);
    setMore3(type);
  };

  // component to be rendered

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <section className="curriculum__pricing" id={ids[gradeIndex]}>
      <div className="pricing__header">
        <h2 className="md_h2 mb-4">Curriculum & Pricing</h2>
        <div className="pricing__header__buttonx">
          {Structure.map((card, i) => (
            <div
              className={
                getCurriculumIndexstruct() === i
                  ? "selected__price__button"
                  : ""
              }
              onClick={(event) => {
                window.location.href = locationsStructure[i];
                setGradeIndexstruct(i);
              }}
              key={i}
            >
              <h4>{card}</h4>
              <h6>{subtitle[i]}</h6>
            </div>
          ))}
        </div>

        <div className="pricing__header__button">
          {cards.map((card, i) => (
            <div
              className={gradeIndex === i ? "selected__price__button" : ""}
              onClick={(event) => {
                if (getCurriculumIndexstruct() == 0) {
                  window.location.href = locationspc[i];
                  setGradeIndexstruct(i);
                }
                if (getCurriculumIndexstruct() == 1) {
                  window.location.href = locationsgc[i];
                  setGradeIndexstruct(i);
                }
              }}
              key={i}
            >
              <h4>{card}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="pricing__card__container">
        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={advanceImage[gradeIndex]}
            alt="beginnerImage1"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 0 ? color1 : "transparent",
              outline: selectedCardIndex === 0 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(0)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{ background: color1 }}>
                <span className="pricing__level__title">Advance</span>
                {classes[0]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <span className="pricing__card__box__title">Build</span>
                <img src={build2[gradeIndex][0]} alt="buildIcon20" />
                <img src={build2[gradeIndex][1]} alt="buildIcon21" /> <br />
                {gradeIndex === 0 && (
                  <span>Create Multi-page Website, Logic Building</span>
                )}
                {gradeIndex === 1 && (
                  <span>Create Multi-page Website, Logic Building</span>
                )}
                {gradeIndex === 2 && (
                  <span>Create Multi-page Website, Logic Building </span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{ display: more2 ? "block" : "none" }}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <span className="pricing__card__box__title">
                    Program Highlights
                  </span>
                  <span className="homapage__beginner__bagde">Beginner +</span>
                  {gradeIndex === 0 && (
                    <span>
                      Algorithm Design, Loops & Nested Loops, Block Sequencing,
                      Conditionals, User Interactivity, Functions
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Frontend Development, Web Designing, Data Structures,
                      Complex Problem Solving, Nested & Complex Loop Handling,
                      Advanced Designing And Development
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Frontend Development, Web Designing, Data Structures,
                      Complex Problem Solving, Nested & Complex Loop Handling,
                      Advanced Designing And Development
                    </span>
                  )}
                </div>

                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <span className="pricing__card__box__title">
                    Achievements
                  </span>
                  <img
                    className="pricing__card__achievement"
                    src={achievement2[gradeIndex]}
                    alt="achievement2"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>
                      Web Developer Certificate, Lifetime Community Access
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Web Developer Certificate, Lifetime Community Access
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Web Developer Certificate, Lifetime Community Access
                    </span>
                  )}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore2(!more2)}
                style={{ color: color1 }}
              >
                {more2 ? "See less" : "See more"}
              </div>
              <div className="pricing__card__price">
                <span
                  className="discount__price"
                  style={{ width: "180px", color: color1 }}
                >
                  {symbol}{" "}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(monPrice[0])
                    : formatPrice(monPrice1to4[0])}
                  <span className="permonth">/12mo</span>
                </span>
              </div>
              <div className="pricing__session__price">
                {/* {symbol} {formatPrice(perSession[0])} per session */}
                <span className="payment-badge">
                  {symbol}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(price[0])
                    : formatPrice(price1to4[0])}
                </span>
              </div>
            </div>
            {gradeIndex === 0 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 1 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 2 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            <ExternalLink
              {...(getCurriculumIndexstruct() === 0
                ? (money = formatPrice(price[0]))
                : (money = formatPrice(price1to4[0])))}
              {...(getCurriculumIndexstruct() === 0
                ? (dMoney = formatPrice(discountPrice[0]))
                : (dMoney = formatPrice(discountPriceGroup[0])))}
              to="/payment"
              data={{
                discountPrice: dMoney,
                price: money,
                symbol: symbol,
                grade: gradeIndex,
                package: "Advanced",
              }}
            >
              <div
                className="buynow"
                style={{
                  background: selectedCardIndex === 0 ? color1 : "transparent",
                  color: selectedCardIndex === 0 ? "white" : color1,
                  borderColor: color1,
                  borderBottomLeftRadius:
                    selectedCardIndex === 0 ? "5px" : "10px",
                  borderBottomRightRadius:
                    selectedCardIndex === 0 ? "5px" : "10px",
                }}
              >
                Buy now
              </div>
            </ExternalLink>
          </div>
        </div>

        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={beginnerImage[gradeIndex]}
            alt="beginnerImage1"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 1 ? color0 : "transparent",
              outline: selectedCardIndex === 1 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(1)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{ background: color0 }}>
                <span className="pricing__level__title_intermediate">
                  Intermediate
                </span>
                {classes[1]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <span className="pricing__card__box__title">Build</span>
                <img src={build1[gradeIndex]} alt="buildIcon1" /> <br />
                {gradeIndex === 0 && (
                  <span>
                    Create basic Level Programs In Python, Logic Building,
                    Project-Based On Real-World Data
                  </span>
                )}
                {gradeIndex === 1 && (
                  <span>
                    Create basic Level Programs In Python, Logic Building,
                    Project-Based On Real-World Data
                  </span>
                )}
                {gradeIndex === 2 && (
                  <span>
                    Create basic Level Programs In Python, Logic Building,
                    Project-Based On Real-World Data
                  </span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{ display: more1 ? "block" : "none" }}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <span className="pricing__card__box__title">
                    Program Highlights
                  </span>
                  <span className="homapage__advance__bagde">Advance +</span>
                  {gradeIndex === 0 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals
                    </span>
                  )}
                </div>

                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <span className="pricing__card__box__title">
                    Achievements
                  </span>
                  <img
                    className="pricing__card__achievement"
                    src={Achievement1}
                    alt="achievement1"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>
                      Web Developer & Python Certificate, Lifetime Community
                      Access
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Web Developer & Python Certificate, Lifetime Community
                      Access
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Web Developer & Python Certificate, Lifetime Community
                      Access
                    </span>
                  )}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore1(!more1)}
                style={{ color: color0 }}
              >
                {more1 ? "See less" : "See more"}
              </div>
              <div className="pricing__card__price">
                <span
                  className="discount__price"
                  style={{ width: "180px", color: color0 }}
                >
                  {symbol}{" "}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(monPrice[1])
                    : formatPrice(monPrice1to4[1])}
                  <span className="permonth">/18mo</span>
                </span>
              </div>
              <div className="pricing__session__price">
                {/* {symbol} {formatPrice(perSession[1])} per session */}
                <span className="payment-badge">
                  {symbol}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(price[1])
                    : formatPrice(price1to4[1])}
                </span>
              </div>
            </div>
            {gradeIndex === 0 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 1 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 2 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}

            <ExternalLink
              to="/payment"
              {...(getCurriculumIndexstruct() === 0
                ? (money = formatPrice(price[1]))
                : (money = formatPrice(price1to4[1])))}
              {...(getCurriculumIndexstruct() === 0
                ? (dMoney = formatPrice(discountPrice[1]))
                : (dMoney = formatPrice(discountPriceGroup[1])))}
              data={{
                discountPrice: dMoney,
                price: money,
                symbol: symbol,
                grade: gradeIndex,
                package: "Intermediate",
              }}
            >
              <div
                className="buynow"
                style={{
                  background: selectedCardIndex === 1 ? color0 : "transparent",
                  color: selectedCardIndex === 1 ? "white" : color0,
                  borderColor: color0,
                  borderBottomLeftRadius:
                    selectedCardIndex === 1 ? "5px" : "10px",
                  borderBottomRightRadius:
                    selectedCardIndex === 1 ? "5px" : "10px",
                }}
              >
                Buy now
              </div>
            </ExternalLink>
          </div>
        </div>

        <div className="pricing__card">
          <img
            className="pricing__card__image"
            src={masterImage[gradeIndex]}
            alt="masterImage3"
          />
          <div
            className="pricing__card__content box-shadow"
            style={{
              borderColor: selectedCardIndex === 2 ? color2 : "transparent",
              outline: selectedCardIndex === 2 ? "3px" : "0px",
            }}
            onClick={(event) => setSelectedCardIndex(2)}
          >
            <div className="pricing__card__main">
              <div className="pricing__level" style={{ background: color2 }}>
                <span className="pricing__level__title">Master</span>
                {classes[2]} Classes
              </div>
              <div className={`pricing__card__box box${gradeIndex}1`}>
                <span className="pricing__card__box__title">Build</span>
                <img src={build3[gradeIndex][0]} alt="buildIcon30" />
                <img src={build3[gradeIndex][1]} alt="buildIcon31" /> <br />
                {gradeIndex === 0 && (
                  <span>
                    Create Advanced Level Programs In Python, Logic Building,
                    Project Based On Real World Data
                  </span>
                )}
                {gradeIndex === 1 && (
                  <span>
                    Create Advanced Level Programs In Python, Logic Building,
                    Project Based On Real World Data
                  </span>
                )}
                {gradeIndex === 2 && (
                  <span>
                    Create Advanced Level Programs In Python, Logic Building,
                    Project Based On Real World Data
                  </span>
                )}
              </div>
              <div
                className="pricing__extra__boxes"
                style={{ display: more3 ? "block" : "none" }}
              >
                <div className={`pricing__card__box box${gradeIndex}2`}>
                  <span className="pricing__card__box__title">
                    Program Highlights
                  </span>
                  {gradeIndex === 0 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals, Data Structures, Complex
                      Problem Solving, Web Scrapping
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals, Data Structures, Complex
                      Problem Solving, Web Scrapping
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      Conditionals, User Interactivity, Logic Building, Decision
                      Making, Programming Fundamentals, Data Structures, Complex
                      Problem Solving, Web Scrapping
                    </span>
                  )}
                </div>

                <div className={`pricing__card__box box${gradeIndex}5`}>
                  <span className="pricing__card__box__title">
                    Achievements
                  </span>
                  <img
                    className="pricing__card__achievement"
                    src={Achievement31}
                    alt="achievement31"
                  />
                  <img
                    className="pricing__card__achievement"
                    src={Achievement32}
                    alt="achievement32"
                  />{" "}
                  <br />
                  {gradeIndex === 0 && (
                    <span>
                      App & Game Developer, Web Developer Certificate, Lifetime
                      Community Access, Opportunity To Visit ISRO
                    </span>
                  )}
                  {gradeIndex === 1 && (
                    <span>
                      App & Game Developer, Web Developer Certificate, Lifetime
                      Community Access, Opportunity To Visit ISRO
                    </span>
                  )}
                  {gradeIndex === 2 && (
                    <span>
                      App & Game Developer, Web Developer Certificate, Lifetime
                      Community Access, Opportunity To Visit ISRO
                    </span>
                  )}
                </div>
              </div>
              <div
                className="pricing__card__box pricing__card__toggler"
                onClick={(event) => setMore3(!more3)}
                style={{ color: color2 }}
              >
                {more3 ? "See less" : "See more"}
              </div>
              <div className="pricing__card__price">
                <span
                  className="discount__price"
                  style={{ width: "180px", color: color2 }}
                >
                  {symbol}{" "}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(monPrice[2])
                    : formatPrice(monPrice1to4[2])}
                  <span className="permonth">/24mo</span>
                </span>
              </div>
              <div className="pricing__session__price">
                {/* {symbol} {formatPrice(perSession[2])} per session */}
                <span className="payment-badge">
                  {symbol}
                  {getCurriculumIndexstruct() === 0
                    ? formatPrice(price[2])
                    : formatPrice(price1to4[2])}
                </span>
              </div>
            </div>
            {gradeIndex === 0 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 1 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            {gradeIndex === 2 && (
              <div
                className="download__currciculum"
                onClick={(event) => handleClickOpen()}
              >
                <img src={DownloadIcon} alt="downloadIcon" />
                Download Brochure
              </div>
            )}
            <ExternalLink
              {...(getCurriculumIndexstruct() === 0
                ? (money = formatPrice(price[2]))
                : (money = formatPrice(price1to4[2])))}
              {...(getCurriculumIndexstruct() === 0
                ? (dMoney = formatPrice(discountPrice[2]))
                : (dMoney = formatPrice(discountPriceGroup[2])))}
              to="/payment"
              data={{
                discountPrice: dMoney,
                price: money,
                symbol: symbol,
                grade: gradeIndex,
                package: "Master",
              }}
            >
              <div
                className="buynow"
                style={{
                  background: selectedCardIndex === 2 ? color2 : "transparent",
                  color: selectedCardIndex === 2 ? "white" : color2,
                  borderColor: color2,
                  borderBottomLeftRadius:
                    selectedCardIndex === 2 ? "5px" : "10px",
                  borderBottomRightRadius:
                    selectedCardIndex === 2 ? "5px" : "10px",
                }}
              >
                Buy now
              </div>
            </ExternalLink>

            <Modal
              isOpen={modalOpen}
              onRequestClose={modalClose}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <PricingModal
                modalClose={modalClose}
                onDownloadCurriculum={onDownloadCurriculum}
              />
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}
