import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";

import "./content.css";
import readinghabits from "./../../Assets/Blogs/readinghabits.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";

export const Readinghabit = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        Ways to encourage reading habits in children and Why is it essential for them?

        </h1>
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
        {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        
        <img className="main-content-image" src={readinghabits}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        

        There are many ways to hit the top, which includes good nourishment from an early age. As a parent, everyone knows that already. One of the best ways your child can be better at communication and understanding are reading. Inculcating the habit of reading is a must thing to do for your child’s betterment. It will help them in multiple ways, not only in school but also in their personal lives. Communication is the key to any successful business or relationship. Reading books will boost their vocabulary, and they will be able to grasp knowledge faster than other kids of their age. The skill is fundamental, and a child needs to keep a reading habit. It will help them in their lifelong success and increase their attention span and analytical skills. 

 {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        
        <p>
        “My child hates reading! No matter how much I try, he wouldn’t care!” Is this the problem you are facing too? No worries, you have come to the right place to get your answers. The portal will guide you with every step! So, fasten your seat belts and get ready for the journey! 
</p>


        <h3>
        Why does my child keep away from books? What Can I do to solve this?
 </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        {/* <p> */}
            
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    {/* A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out.  */}
{/* </p> */}
<p>
Before we get to the top ways to encourage reading habits in children, let’s see why your child hates reading. This would help you understand the mental process of your child and guide them better. Almost every one out of two parents might see their child-hating to read books. Not every child would like to read. Why?
</p>

<ul>
    <li>The child might feel like reading is just like any other routine-based chore. Who would love to do something when it feels like a chore? 
</li>
<li>The child might face some problems while reading, and they have nobody to sort out the problem. 
    </li>
    <li>They might not have found an exciting book and hence, keeps away from reading. 
        </li>
        <li>The child might think reading is boring. 
</li>
</ul>
<p>
Now that you know the possible problems your child might face; you can encourage them to read books with a passion that would turn the whole table! Help your child love reading books and gaining new knowledge. 
</p>

<h3>
    
The top ways to encourage reading habits in children

</h3>
<ul>
    <li>
       <p> <b>
Become Their Role Model
</b></p>
Yes, you read that right. If you want your child to infuse a reading habit in themselves, you must first set an example for them to follow. If your child observes you read newspapers, e-books, magazines, and books, they will understand that reading is essential. Take them on your lap and read out the texts, and encourage a healthy reading habit. 
</li>


<li>
       <p> <b>
Access To Reading Materials
</b></p>
To give your child the vibe that reading is possible anywhere:
<ol>
    <li>Keep reading materials at home or near their reach.</li>
    <li>Keep books on the tables or shelves.
</li>
    <li>Let them know that task is not something that only happens in school but also on their bed!</li>
</ol>
</li>



<li>
       <p> <b>Reading Habits </b></p>
       If you want your child to impart a reading habit, then make sure you read to them every night. When they grow up a little, hand them a book to read on their own before sleeping. Say goodbye to phones or tablets before bedtime and let them welcome exciting books to help them enhance their reading and communication skills. 
</li>


<li>
       <p> <b>
       Help Them Explore the World Of Reading
</b></p>
Please don’t buy them the same genre every time. The child will get bored eventually. Let them know that there are multiple genres to read from. It could be a comedy, tragedy, fantasy, fictional, non-fictional, horror, adventures, classics, and so much more. The benefits of reading books from different genres are plenty. It will open up their imagination power and improve their analytical skills. 

</li>


<li>
       <p> <b>

       Reading Habits Come From Anywhere And Not Just Books Or Papers!

</b></p>
Give yourself a break. You don’t need to hand them a book all the time to encourage reading habits in children. For instance, when you are at a restaurant with your child, ask them to read out the menu. You could also ask them to read out the banners and road signs, milestones, instructions that come in-game, movie subtitles, and so much more</li>


<li>
       <p> <b>
Make Reading Interesting
</b></p>
This is a skill that you need to teach your child by first reading it out yourself. Now, what exactly am I talking about? Read out the character dialogues, with particular emphasis and different voices. Trust me; this would help your child gain a lot of interest in reading. They would love to try it out themselves. All you need to do is make reading fun without forcing it on them. Keep the flow natural, and you will see how fast your child grapes the habit of reading. 
</li>

</ul>
       <p> <b>

       Key Takeaways


</b></p>
    



<p>


Encouraging reading habits in children is crucial for success in life and relationships. We all know the importance of good communication. It could either make a thing or break a thing. Words are potent weapons to portray our thoughts and grasp the audience. The benefits of reading are plenty. It will help the children gain confidence, gain analytical skills, increase vocabulary power, assertive communication skills, etc. It might also make your child a great orator! What do you think about teaching reading habits in children? Let us know in the comments below. If you want daily updates about us, keep a follow on our Twitter, Facebook, Linked In, and Instagram handles. 
</p>


<p>
    Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
      
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

