import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import Image2 from "./../../Assets/Blogs/Image2.png";
import "./content.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MainContentImage from "./../../Assets/Blogs/MainContentImage.png";
import AnimationBlog from "./../../Assets/Blogs/AnimationBlog.gif";
import einstientk from "./../../Assets/Blogs/einstientk.jpg";
import creativity from "./../../Assets/Blogs/creativity.gif";
import ican from "./../../Assets/Blogs/ican.png";
import TechoKidsFinalLogo from "./../../Assets/Blogs/TechoKidsFinalLogo2.png";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
import onlinelearning from "./../../Assets/Blogs/onlinelearning.jpg";

export const Onlinelearning21 = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content">
        Why online learning for kids is required in 21st Century?
        </h1>
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          {/* <div>
            <AccountCircleIcon
              style={{
                fontSize: "350%",
              }}
            />
          </div> */}
           <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={onlinelearning}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        The trend of Online learning for kids has become a buzzword amongst the education industry through the intervention of various digital technologies. In a classroom environment with another 40 students, all of them having different grasping power, a different pace of learning, and the distributed attention of the teacher. As observed in such situations, many students fail to cope up with the speed of the teaching. But now we have an option that will neglect all of these situations. That is adopting online learning for kids.

          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        <p>
        Nowadays, the availability of various online courses has increased, which will help children to expand their educational scope. For instance, if they want to explore something that hasn't been taught to them in school, like coding, they will have to look out for other alternative means. Virtual learning for kids is a worthy and remarkable possibility that will allow students to explore more than a regular course. 
        </p>
        <p>
        Over the years, it is observed that e-learning has experienced an immense rise. Let us now discuss the major benefits of online learning for kids over traditional mass learning, shown below.
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>1. Flexibility & Self-Paced Learning:

</b>
      </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        
Individual students can study at their own pace with flexible schedules. They can plan & manage their entire educational courses and give time to each content according to their capabilities. No fear of lacking behind or rushing to complete the course without even understanding it properly. That means a student will never miss out on learning or have that fear of falling behind. 

</p>
<p>
Online learning for kids allows becoming self-motivated individuals who make their own choices. It grants them to step out of their comfort zone and start to experience the world from a different perspective. In this way, their evolution as an individual has escalated, and they learn to become more responsible.
</p>
<p>"You don't understand anything until you learn it more than one way" - Marvin Minsky</p>

<h3>
        <b>2. Gain Concentration by Eliminating Distractions:</b>
      </h3>
      <p>In this digital world of online learning, learners explore more to stay focused. Online learning for kids avoids distractions and focus mainly on learning by attending online 1:1 classes. 

Some might prefer the calm of the park next to their house or the buzz of a coffee shop. Altogether a place where Wi-Fi range could provide a comfortable learning scenario. That means the location of the student is not a matter of concern. So, there are no interferences with his/ her chances of pursuing a particular subject.</p>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        <div className="text-center">
        <img style = {{marginRight:40, borderRadius:20} } width = "600" src={einstientk}></img>
      </div>
      <br></br>
      <h3>
          <b>
          3. Acquire Confidence by Interacting with e-Tutors:
          </b>
      </h3>
        <p>
     
Every student is not the same. 70% of the students don't ask their doubts because they avoid having one on one interaction with the teacher in front of a whole class. They have this mentality that others might make fun of them or judge them for their silly doubts.
</p>
<p>
But here in online learning for kids, there is a complete reverse scenario student can ask as many as doubts arise in their mind without worrying about getting judged by anybody. This way, their confusion will get cleared.
</p>
<p>
Results and observations show that e-learning for kids has proven plus helped children to develop their confidence. The early-aged attained faith always supports students in their higher studies. Also, it gives them a little push to improve their performance in all phases of their life.
</p>

         <br>
         </br> 
         <h3>
        <b>4. Exposure Increment via Opportunities to Step Up:</b>
      </h3>    
         
         <p>
       
Students have this tendency to get distracted and copy others' work or rely on somebody else. However, online learning for kids ensures that one completes their tasks on their own, plus there is an advantage of monitoring students more closely. Also, teachers or faculties can give their immediate feedback to the students. Adapting these techniques can highly increase a student's responsibility for their learning.
</p>
        <p>
        Online learning for kids(https://www.youngwonks.com/blog/Are-Online-Classes-for-Kids-Effective) is beneficial as it offers students the opportunity to come in contact with and regularly interact with people (teachers or students) from different regions, even other nations or continents. This way, it teaches kids to develop healthy collaboration while teaming up on projects. Consistently, children grow culturally more open-minded as a consequence of learning online and thus more accepting of other cultures. 
        </p>
  <h3>      <p>"Education is the most powerful weapon which you can use to change the world" - Nelson Mandela </p>
<p>5. Convenience & Reduced Costs:</p>
</h3>

<p>
There are several reasons how online learning for kids costs less than traditional ways of learning. For example, one saves on commuting costs as students can learn from online courses in the comfort of their homes. It includes various costs related to transporting like fuel, parking, car maintenance, or public transportation costs.

</p>


        <p>
Virtual learning is more affordable than mass schooling. Therefore, the financial strain that sometimes becomes a part of academic aspirations is reduced. Students get an opportunity to gain success without being burdened with a price tag.
</p>

<p>Importantly, kids also feel more in control of the process and charge of their learning.
Instead of mass learning, online learning for kids is a better option as it enhances student's technical, course-related knowledge as well as develops interpersonal skills like communication skills, flexibility, and capability of completing the entire contents of the course at their speed. Besides this, learning online boosts students in becoming the best versions of themselves and discovering a joy for lifelong learning.

        </p>

        <p>Now that you have read about why online learning for kids is preferred more(https://labusinessjournal.com/news/2020/oct/12/distance-learning-education-why-do-students-online/), how will you make that happen?</p>
        

        
        
        <p>
        We both know that you are looking at a flexible solution for you!{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        </p>
        <p>We at Techokids offer 1:1 interactive online learning classes where your child can easily and effectively learn online, from anywhere in the world.
Read our trained kids' feedbacks and what their parents think about us on (Text: our website)linked to (https://techokids.com/) 
(whenever a user would tap on this link, this link should open in a new tab.)
</p>
        
            <b>
            Now, what are you waiting for?
            </b>
        
        


<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
"
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <Footer/>
  </>
  );
};

