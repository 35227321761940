import React, { useState } from "react";
import BaseImage from "../../Assets/homepage/base-image.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CircularBarsImage from "../../Assets/homepage/circular-bars.png";
import YelloLeftCircle from "../../Assets/homepage/yellow-left-circle.png";
import BoyWithPhoneImage from "../../Assets/homepage/boy-with-phone.png";
import BoyWithPhoneImageMobile from "../../Assets/homepage/boy-with-phone-mobile.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import RectangleDotsImage from "../../Assets/homepage/rectangle-dots.png";
import DiamondDotsImage from "../../Assets/homepage/diamond-dots.png";
import HowToStartImage from "../../Assets/homepage/how-to-start.jpg";
import HomepageFeedback from "../../Components/HomepageFeedback";
import Navbar from "../../Components/Navbar";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Typewriter from "typewriter-effect";

import { Helmet } from "react-helmet";
import {
  getRefferalCode,
  getRefferalName,
  homepage_base_bottom_cards,
  homepage_whylearnwithus_cards,
  homepage__broughttoyou_cards,
  homepage__countup,
  homepage__practical__skill_cards,
} from "./Helper";
import PricingCard from "../../Components/PricingCard";
import Footer from "../../Components/Footer";
import "./Home.css";
import CourseCard from "../../Components/CourseCard";
import ExternalLink from "../../Components/ExternalLink";
import { useHistory } from "react-router-dom";
import ReferModal from "../../Components/ReferModal";
import resize from "../../Assets/homepage/resize.png";

const Home = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);

  let refferalCode = getRefferalCode();
  let refferalName = getRefferalName();
  const [showModal, setShowModal] = useState(refferalCode ? true : false);

  let history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          {" "}
          #1 Live Online Coding classes for kids |learn coding |Techokids
        </title>
        <meta
          name="description"
          content="Best Online Coding Platform For Kids. Online Classes of Robotics For Kids are conducted with expert teachers. Join online tuition to get your doubts solved."
        />
        <link rel="canonical" href="https://www.techokids.com/" />
        <meta
          name="keywords"
          content=" online coding classes for kids of grade 1-12 in India, online coding classes for kids, python for kids, coding for children, coding for kids, app development, game development, virtual reality, python programming, web development, augmented reality, game programming, scratch coding for kids, machine learning artificial intelligence, coding for kids online, coding for kids near me."
        />
      </Helmet>
      <Navbar />
      <main>
        <div style={{ background: "#d7eefd" }}>
          {/* TOP SECTION */}
          <div className="homepage__top">
            <div className="homepage__view">
              <div className="homepage__base__left">
                <h1 className="homepage__left__header">
                  #1 Live Online Coding
                  <br />
                  Classes for Kids
                </h1>
                <div className="homepage__left__header__box">
                  <div className="homepage__left__subheader">
                    <h2>Become the Next Generation Techpreneur</h2>
                  </div>
                  <div className="homepage__tags__container">
                    <p>Age 5-18</p>
                    <p>Top 1% Teacher</p>
                    <p>50+ Countries</p>
                    <p>
                      <button
                        style={{
                          backgroundColor: "transparent",
                          color: "white",
                          fontWeight: "600",
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          window.location.href = "https://g.co/kgs/UvM8nT";
                        }}
                      >
                        4.8/5 Google Rating{" "}
                      </button>
                    </p>
                  </div>
                  <div className="homepage__bookatrial__container">
                    <h3>Book a Free Trial Class</h3>
                    <div className="homepage__bookatrial__input__container">
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        defaultCountry="IN"
                      />
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          window.location.href =
                            window.origin +
                            "/book-trial?phoneNumber=" +
                            phoneNumber;
                        }}
                      >
                        Book Now
                      </button>
                    </div>
                    <div className="containerHH">
                      <span>
                        <span class="material-symbols-outlined">
                          library_books
                        </span>
                        Free Class <del>worth $25</del>
                      </span>
                      <span>
                        <span class="material-symbols-outlined">
                          query_stats
                        </span>
                        500+ classes booked in last 12 hours
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="homepage__base__right">
                <LazyLoadImage
                  alt="baseImage"
                  src={BaseImage}
                  effect="opacity"
                  className="baseImage"
                  width="2045"
                  height="1734"
                />
              </div>
            </div>
          </div>
          {/* END TOP SECTION */}

          {/* COURSES SECTION */}
          <section className="homepage__base__bottom">
            <div className="img__circular__bar">
              <img
                className="homepage__base__bottom__circular__bar"
                src={CircularBarsImage}
                alt="circularBars"
                width="350"
                height="350"
              />
            </div>
            <div className="homepage__course__content">
              <div className="homepage__course__header">
                <h2 className="md_h2 ">
                  Start Your Success with our <br />
                  Specialized Courses designed by Experts
                </h2>
              </div>
              <div className="homepage__course__cards__container">
                {homepage_base_bottom_cards.map((item, index) => {
                  return (
                    <CourseCard
                      item={item}
                      key={index}
                      width={item.width}
                      height={item.height}
                    />
                  );
                })}
              </div>
              <div className="homepage__course__cards_button">
                <ExternalLink to="/book-trial">Enroll Now</ExternalLink>
              </div>
            </div>

            <div className="homepage__base__bottom__yellow__left__circle__container">
              <img
                className="homepage__base__bottom__yellow__left__circle"
                src={YelloLeftCircle}
                alt="yellowLeftCircle"
                width="178"
                height="300"
              />
            </div>
          </section>
          {/* END COURSES SECTION */}
        </div>

        <div style={{ background: "#fff" }}>
          {/* IMPORTANCE OF CODING */}
          <section className="homepage__coding__importance">
            <div className="homepage__coding__importance__left">
              <LazyLoadImage
                alt="boyWithPhoneImage"
                src={BoyWithPhoneImage}
                effect="opacity"
                className="homepage__boy__with__phone__image desktop__message"
                width="882"
                height="1080"
              />
              {/* <img
                className="homepage__boy__with__phone__image desktop__message"
                src={BoyWithPhoneImage}
                alt="boyWithPhoneImage"
              /> */}
              <img
                className="homepage__boy__with__phone__image mobile__message"
                src={BoyWithPhoneImageMobile}
                alt="boyWithPhoneImage"
                width="729"
                height="1085"
              />
            </div>
            <div className="homepage__coding__importance__right">
              <h2 className="md_h2 ">
                Why Coding Is Necessary For Kids In 21st Century?
              </h2>

              <p className="md_p homepage__coding__importance__message ">
                Coding is just a set of instructions given to a computer to do
                what we want it to do. It is used to create games, websites,
                apps, etc.{" "}
                <span className="desktop__message">
                  {" "}
                  In today’s digital world, every single digital application or
                  website we use runs on coding functionalities. Coding for kids
                  has become very necessary in the 21st Century.
                </span>
                <ExternalLink
                  to="/blog/5-reasons-why-coding-is-important-for-kids"
                  className="homepage__coding__importance__readmore"
                >
                  Read more
                </ExternalLink>
              </p>

              <button
                className="homepage__start__journey__button"
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = window.location.origin + "/book-trial";
                }}
              >
                Start your journey
              </button>
              <div className="homepage__coding__importance__circular__bars__image">
                <img
                  src={CircularBarsImage}
                  className="circularBarsImage"
                  alt="circularBarsImage"
                  height="350"
                  width="350"
                />
              </div>
            </div>
          </section>
          {/* END IMPORTANCE OF CODING */}

          {/*  PRACTICAL LIFE SKILLS  */}
          <section className="homepage__nurture__content">
            <div className="homepage__nurture__diamond__dots__image">
              <img
                width="350"
                height="350"
                src={DiamondDotsImage}
                alt="diamondDotsImage"
              />
            </div>
            <h2 className="md_h2 homepage__nurture__header">
              Empower your child with practical life skills to become the
              next-generation
            </h2>
            <div className="typewriter">
              <div class="box1"></div>
              <div class="box2"></div>
              <div class="box3"></div>
              <div class="box4"></div>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Young Entrepreneurs")
                    .pauseFor(1500)
                    .deleteAll()
                    .typeString("Creators")
                    .deleteAll()
                    .typeString("Innovators")
                    .start();
                }}
                options={{
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
            <div className="homepage__nurture__cards__wrapper">
              <div className="homepage__nurture__cards__container">
                {homepage__practical__skill_cards.map((item) => {
                  return (
                    <div className="homepage__nurture__card" key={item.text}>
                      <LazyLoadImage
                        alt={item.text}
                        src={item.img}
                        effect="opacity"
                        width={item.width}
                        height={item.height}
                      />
                      {/* <img src={item.img} alt={item.text} /> <br /> */}
                      <h4 className="md_h4">{item.text}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="homepage__nurture__rectangular__image">
              <img
                height="350"
                width="350"
                src={RectangleDotsImage}
                alt="rectangleDotsImage"
              />
            </div>
          </section>
          {/* END PRACTICAL LIFE SKILLS */}

          {/* PRICING CARD */}
          <PricingCard />
          {/* END PRICING CARD */}
        </div>

        <section className="homepage__whylearnwithus">
          <div className="homepage__whylearnwithus__header">
            <div className="homepage__whylearwithus__header1">
              <h2 className="md_h2">Why Learn With TechoKids?</h2>
            </div>
            <p className="homepage__whylearwithus__header2 md_p">
              Kids Learn Fundamentals Of Coding – Logic, Structure, Sequence And
              Develop Algorithmic
              <br /> Thinking to generate creative outcomes
            </p>
          </div>
          <div className="homepage__whylearnwithus__grid">
            <div className="homepage__whylearnwithus__left">
              <img src={resize} height="726" width="946" alt="girlWithPC" />
            </div>
            <div className="homepage__whylearnwithus__right">
              {homepage_whylearnwithus_cards.map((item) => {
                return (
                  <div
                    className="homepage__whylearnwithus__card"
                    key={item.header}
                  >
                    <div>
                      <img
                        width={item.width}
                        height={item.height}
                        src={item.img}
                        alt={item.header}
                      />
                    </div>
                    <div className="homepage__whylearnwithus__card__content">
                      <div className="homepage__whylearnwithus__card__header">
                        <h4 className="md_h4">{item.header}</h4>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section className="homepage__howtostart">
          <h2 className="md_h2">How To Start Your Coding Journey?</h2>
          <br />
          <div className="homepage__howtostart__image__container">
            <img
              src={HowToStartImage}
              alt="howToStartImage"
              width="2156"
              height="820"
            />
          </div>
        </section>

        <HomepageFeedback />

        <section className="homepage__broughttoyou">
          <h5>Brought To You By Experienced Team From</h5>
          <div className="homepage__broughttoyou__grid">
            {homepage__broughttoyou_cards.map((item) => {
              return (
                <div className="homepage__broughttoyou__card" key={item.text}>
                  <img
                    width={item.width}
                    height={item.height}
                    src={item.img}
                    alt={item.text}
                  />
                </div>
              );
            })}
          </div>

          <div className="homepage__broughttoyou__stats box-shadow">
            {homepage__countup.map((item) => {
              return (
                <div
                  className="homepage__broughttoyou__stats__card"
                  key={item.text}
                >
                  <div>
                    <img
                      src={item.img}
                      alt={item.text}
                      height={item.height}
                      width={item.width}
                    />
                  </div>
                  <div>
                    <div className="countup">
                      <CountUp
                        end={item.countEnd}
                        redraw={true}
                        separator=","
                        duration={1.5}
                        // suffix="+"
                      >
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <ReferModal
          showModal={showModal}
          RefferalName={refferalName}
          RefferalCode={refferalCode}
          closeModal={() => setShowModal(false)}
        />
        <Footer />
      </main>
    </>
  );
};

export default Home;
