import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import timemanagement from "./../../Assets/Blogs/timemanagement.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
export const Timemanagement = () => {
  const history = useHistory();
  return (
    <>
    <ScrollTop/>

    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style = {{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        How/What are the ways to teach kids about Time Management?</h1>
        
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={timemanagement}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        Guess what’s one of the essential skills that one must have? If you thought about time management, then you are right. Individuals grow up learning several skills from their elders and peers. Missing out on time management would mean missing out on the importance of handling everything else. Time runs on its own, and it waits for none. Hence, one needs to utilize the time efficiently and not waste it away. 
          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <p>If one has good time management skills, they will achieve a lot in a short time. It would let you get enough free time for yourself. There would be no chance of procrastination, And hence, you will be getting enough leisure time to boost yourself. It will lead to more success in their career and work. Moreover, grown-up adults would learn to manage a good work-life balance. It will lower their stress and would help them focus. </p>
        
        <p>
        The fundamental fact is that time management would ensure the proper allocation of time for the right activity based on priorities. It would help them in the following ways:</p>
    
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        {/* <p> */}
            
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    {/* A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out.  */}
{/* </p> */}
<ul>
    <li>They would be able to set an appropriate time for goals and objectives.</li>
    <li>They would be able to set meet up deadlines.</li>
    <li>They would be able to take responsibility</li>
    <li>They would spend the right time on the right activity. </li>
    <li>They would be free from stress.
</li>
    <li>They won’t misuse time. </li>
    <li>They would be more organized and live a good life.  </li>

</ul>
<p>Now, we got the in-depth details of time management. Let’s see how to teach kids about time management. To learn about it, keep reading the post. </p>

<h3>
    
Easy Ways To Teach Kids About Time Management

</h3>

<p>Here are the ways which you could follow and aid your kids.</p> 
<ul>
    <li>
    Start with a simple routine. Set a good bedtime and let them follow it. The kids aged between 5-12 need around 11-12 hours of sleep. Set a good schedule for them to follow it. Make sure they get enough sleep. However, when it is their time to wake up, wake them up, and don’t let them get lazy and stick to their bed. 

</li>
<li>
Secondly, one of the best ways to help your child practice time management is by showing them how they could create a calendar of their own. Yes, you heard that right. If they learn early to curate their calendar, the better it would be for themselves and their parents. Let them plan out how their day should look and prepare themselves for the upcoming days or events. 

</li>
<li>

One needs to make sure that the kids are well-aware of time. Now how can you help out with that? It is easy—set mealtime for the kids. For example, let the dinner time be 8 pm every night. If they start maintaining the time since their childhood, it would gradually become a habit, and they would eventually be aware of their time. 
</li>

<li>
As we are not living in the classical ages, kids are heavily distracted by digital gadgets. You can’t tell them a no as it is the new normal. However, don’t let it go out of your hands and designate a good electronic device time. Set a time in a day wherein they will be able to play games or watch videos. One needs to be strict with this; otherwise, it would result in severe effects that would affect them mentally and physically. 
</li> 
   
<li>
The next crucial step is that as parents, you must aid them to create plans for themselves. Now the question comes: what plans exactly? Well, this is quite easy to explain. Planning a day results in success. You could ask them to think about the things they would need for their homework, project, the time required, sports gear required, or anything similar. This would ensure your kid is well maintained beforehand and doesn’t suffer from any default when using it. 

</li>

<li>
Given them an area in which they would be able to study without getting distracted. The less they get distracted, the better would be the focus. Please make sure the electronic gadgets are out of sight, and they are concentrating on books in front of them.

</li>
    
<li>
Don’t speak at all about yourself. Let your kids speak up. They might suffer from anxiety, stress, or get tired. Hear them out and help them accordingly. They might need a break or might not feel like doing something at the moment. 

</li>    

    </ul>


<p>

No matter what, time management is an important skill that is to be harnessed by every individual. It helps one immensely in their life. It will help them set a proper schedule and establish a good and care-free life. Think about a life where you are not late and always on time. Less stress and more leisure time, right? Follow us on Twitter, Facebook, Linked In, and Instagram to stay updated! To help your child kickstart their coding life, join us at TechoKids!

</p>

<p>In a nutshell, parents need to look after the stress factor in their child. Ignoring mental health can affect the mental growth of your child. Due to this reason, such children grow up to be depressed or aggressive teenagers. Above were the points about how to teach kids to deal with stress management. Age doesn’t matter when it comes to someone’s mental well-being. Check out the symptoms. Is your child suffering from any? Follow us on Twitter, Facebook, Linked In, and Instagram to stay updated! </p>
<p>Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
      
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

