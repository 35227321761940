import React from "react";
import {Link} from "react-router-dom";

export default function ExternalLink(props) {
  const {keepOrigin, onClick, data, className} = props;
  let newUrl = (keepOrigin ? window.location.origin : "") + props.to;
  return data ? (
    <Link to={{pathname: newUrl, state: data}}>{props.children}</Link>
  ) : (
    <Link
      to={{pathname: newUrl}}
      onClick={(event) => {
        event.preventDefault();
        if (onClick) onClick();
        window.location.href = newUrl;
      }}
      className={className}
    >
      {props.children}
    </Link>
  );
}
