const dev = "http://localhost:8000";
const prod = "https://techokids.onrender.com";
let base_route = prod;
export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const GenerateOrderID = (
  grade,
  Package,
  price,
  studentName,
  parentEmail,
  studentClass,
  parentNumber,
  couponcode
) => {
  // console.log("data at order ID",grade,Package,price,studentName,parentEmail,studentClass,parentNumber);
  const data = {
    grade,
    Package,
    price,
    studentName,
    parentEmail,
    studentClass,
    parentNumber,
    couponcode,
  };
  // console.log("Order data", data);
  return fetch(`${base_route}/payment/order`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const verifyPayment = (data) => {
  return fetch(`${base_route}/payment/verify`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};

export const verifyCoupon = (code, Package) => {
  return fetch(`${base_route}/payment/coupon/verify`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, Package }),
  })
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => console.log(err));
};
