import React from "react";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PrimaryBlogImg from "./../../Assets/Blogs/MainImage.png";
import Avatar from "@material-ui/core/Avatar";
import ReactHtmlParser from "react-html-parser";
import { useState, useEffect } from "react";

function FirstBlog({
	id,
	attachment,
	author,
	authorImage,
	title,
	description,
	readTime,
	date,
}) {
	const history = useHistory();
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	useEffect(() => {
		console.log(date, "date");
		console.log(Date.parse(date));
		let d = new Date(Date.parse(date));
		console.log(d);
		setDay(d.getDate());
		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		setMonth(monthNames[d.getMonth()]);
		setYear(d.getFullYear());
	}, []);

	return (
		// <div
		// 	className="primary-blog d-flex justify-content-between"
		// 	style={{ cursor: "pointer" }}
		// 	onClick={() => history.push(`/blog/content/${id}`)}
		// >
		// 	<div className="primary-blog-img-div">
		// 		<img className="primary-img w-100" src={attachment} />
		// 	</div>
		// 	<div className="primary-blog-content-div">
		// 		<div className=" primary-blog-heading mb-3">
		// 			<b>{title}.</b>
		// 		</div>
		// 		<div className="mb-4 h5 main-para">{ReactHtmlParser(description)}</div>
		// 		<div className="d-flex author-blog">
		// 			<div>
		// 				{/* <AccountCircleIcon
		//               style={{
		//                 fontSize: "350%",
		//               }}
		//             /> */}
		// 				<Avatar
		// 					aria-label="recipe"
		// 					// src={`http://localhost:8000/${data.authorImage}`}
		// 					src={authorImage}
		// 					// className={classes.avatar}
		// 				></Avatar>
		// 			</div>
		// 			<div className="d-flex flex-column">
		// 				<div>{author}</div>
		// 				{/* <div>25 May 2021 | 5 min read</div> */}
		// 				<div>{`${day} ${month} ${year} | ${readTime}`}</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<div
			className="primary-blog d-flex justify-content-between"
			style={{ cursor: "pointer" }}
			onClick={() => history.push(`/blog/content/${id}`)}
			style={{ cursor: "pointer" }}
		>
			<div className="primary-blog-img-div">
				<img
					alt={attachment}
					className="primary-img w-100"
					src={attachment}
					style={{ marginTop: 30 }}
				/>
			</div>
			<div className="primary-blog-content-div">
				<div className=" primary-blog-heading mb-3" style={{ marginTop: 25 }}>
					{/* {Data.map((post) => {
						return <super>{post.title}</super>;
					})} */}
					{/* <super><b>  5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b></super> */}
					<super>
						<b>{title}</b>
					</super>
				</div>
				<br></br>
				<div className="mb-4 h5 main-para">{ReactHtmlParser(description)}</div>
				<div className="d-flex author-blog">
					<div>
						{/* <AccountCircleIcon
            style={{
              fontSize: "350%",
            }}
          
          /> */}

						<img
							alt={author}
							width="50"
							height="50"
							style={{ borderRadius: "50%" }}
							src={authorImage}
						></img>
					</div>
					<div className="d-flex flex-column" style={{ paddingLeft: 10 }}>
						<div>{author}</div>
						<div>{`${day} ${month} ${year} | ${readTime}`}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FirstBlog;
