import React from "react";
import Modal from "react-modal";
import referBg from "../Assets/homepage/referBg.png";
import "../Styles/referModal.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react/cjs/react.development";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const ReferModal = ({ showModal, closeModal, RefferalName, RefferalCode }) => {
  return (
    <Modal isOpen={showModal} style={customStyles} contentLabel="Example Modal">
      <div className="refer_modal">
        <img src={referBg} className="refer_bg" alt="refer-bg" />

        <div className="refer_info_left">
          <span className="join_msg">Come join {RefferalName} and get</span>
          <h5 className="refer_off">
            <b>12% off</b>
            <br />
            on purchase of any course
          </h5>
          <button
            onClick={(event) => {
              event.preventDefault();
              window.location.href =
                window.origin +
                "/book-trial?referral=" +
                RefferalCode +
                "&By=" +
                RefferalName;
            }}
          >
            Book A Free Trial
          </button>
        </div>

        <FontAwesomeIcon
          icon={faTimes}
          className="refer_info_icon"
          onClick={closeModal}
        />
        {/* <div className="refer_info_right">
          Your Friend fkneak Lorem Ipsum
          <br />
          has referred you to Techokids
        </div> */}
      </div>
    </Modal>
  );
};

export default ReferModal;
