import AppDevelopmentIcon from "../../Assets/homepage/app-development.png";
import ArtificalIntelligenceIcon from "../../Assets/homepage/artificial-intelligence.png";
import PythonProgrammingIcon from "../../Assets/homepage/python-programming.png";
import ARVRIcon from "../../Assets/homepage/ar-vr.png";
import GameProgrammingIcon from "../../Assets/homepage/game-programming.png";
import WebDeleopmentIcon from "../../Assets/homepage/web-development.png";
import RoboticsIcon from "../../Assets/homepage/robotics.png";
import DataScienceIcon from "../../Assets/homepage/data-science.png";
import MITIcon from "../../Assets/homepage/MIT.png";
import IITMIcon from "../../Assets/homepage/IITM.png";
import IITRIcon from "../../Assets/homepage/IITR.png";
import IIMIcon from "../../Assets/homepage/IIM.png";
import IITBIcon from "../../Assets/homepage/IITB.png";
import MSUIcon from "../../Assets/homepage/MSU.png";
import Live11ClassIcon from "../../Assets/homepage/live-1-1-classes.png";
import ExpertTeachersIcon from "../../Assets/homepage/expert-teachers.png";
import CurriculumIcon from "../../Assets/homepage/curriculum.png";
import HackathonsCompetitionsIcon from "../../Assets/homepage/hackathons-competitions.png";
import LiveProjectIcon from "../../Assets/homepage/live-project.png";
import GoogleIcon from "../../Assets/homepage/google.png";
import AmazonIcon from "../../Assets/homepage/amazon.png";
import AlgorithmicThinkingIcon from "../../Assets/homepage/algorithmic-thinking.png";
import LogicalReasoningIcon from "../../Assets/homepage/logical-reasoning.png";
import DesignSkillsIcon from "../../Assets/homepage/design-skills.png";
import ProblemSolvingSkillsIcon from "../../Assets/homepage/problem-solving-skills.png";
import PracticallyExperienceIcon from "../../Assets/homepage/practically-experience.png";
import CollaborationIcon from "../../Assets/homepage/collaboration.png";
import StudentsRegisteredIcon from "../../Assets/homepage/students-registered.png";
import ClassesConductedIcon from "../../Assets/homepage/classes-conducted.png";

export const getRefferalCode = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let refferalCode = urlParams.get("referral");
  //console.log("refferalCode",refferalCode);
  if (refferalCode) return refferalCode;
  return null;
};

export const getRefferalName = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let refferalName = urlParams.get("By");
  if (refferalName) return refferalName;
  return null;
};
export const formatNumber = function (x) {
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

export const homepage_base_bottom_cards = [
  {
    img: AppDevelopmentIcon,
    text: "App Development",
    url: "/blog/app-development",
    width: "72",
    height: "72",
  },
  {
    img: ArtificalIntelligenceIcon,
    text: "Artificial Intelligence",
    url: "/blog/artificial-intelligence",
    width: "72",
    height: "72",
  },
  {
    img: PythonProgrammingIcon,
    text: "Python Programming",
    url: "/blog/Python-Programming",
    width: "72",
    height: "72",
  },
  {
    img: RoboticsIcon,
    text: "Robotics-",
    url: "/blog/Robotics-for-kids",
    width: "350",
    height: "350",
  },
  {
    img: ARVRIcon,
    text: "AR & VR",
    url: "/blog/ar-vr",
    width: "72",
    height: "72",
  },
  {
    img: GameProgrammingIcon,
    text: "Game Programming",
    url: "/blog/game-programming",
    width: "72",
    height: "72",
  },
  {
    img: WebDeleopmentIcon,
    text: "Web Development",
    url: "/blog/web-development",
    width: "72",
    height: "72",
  },
  {
    img: DataScienceIcon,
    text: "Data Science & IoT",
    url: "/blog/data-science",
    width: "72",
    height: "72",
  },
];

export const homepage_whylearnwithus_cards = [
  {
    img: Live11ClassIcon,
    header: "Live 1:1 Classes",
    text: "Live 1:1 & 1:4 Classes Will Be Taught With Personal Attention Creating Your Schedule",
    width: "150",
    height: "150",
  },
  {
    img: ExpertTeachersIcon,
    header: "Expert Teachers",
    text: "Highly Selective With Strong Technical & Industry   Background, Experience To Teach Kids",
    width: "150",
    height: "150",
  },
  {
    img: CurriculumIcon,
    header: "Curriculum",
    text: "Customized, Gamified & Engaging With Fun,   Interactive Based Learning (Bloom Taxonomy)",
    width: "150",
    height: "150",
  },
  {
    img: HackathonsCompetitionsIcon,
    header: "Hackathons & Competitions",
    text: "Access to world class events having partenrships with top universities, school & companies",
    width: "350",
    height: "350",
  },
  {
    img: LiveProjectIcon,
    header: "Live Project",
    text: "Build Your Games, Chatbot, Website, Apps & Working Projects During The Trial Like Productive Learning, Not A Rat-race",
    width: "150",
    height: "150",
  },
];

export const homepage__broughttoyou_cards = [
  { img: GoogleIcon, text: "Google", width: "512", height: "512" },
  { img: AmazonIcon, text: "Amazon", width: "512", height: "512" },
  { img: MITIcon, text: "MIT", width: "512", height: "512" },
  { img: IITMIcon, text: "IIT Madras", width: "512", height: "512" },
  { img: IITRIcon, text: "IIT Roorke", width: "512", height: "512" },
  { img: IIMIcon, text: "IIM Ahmed", width: "512", height: "512" },
  { img: IITBIcon, text: "IIT Bombay", width: "512", height: "512" },
  { img: MSUIcon, text: "MSU", width: "512", height: "512" },
];

export const homepage__practical__skill_cards = [
  {
    img: AlgorithmicThinkingIcon,
    text: "Algorithmic Thinking",
    width: "289",
    height: "295",
  },
  {
    img: LogicalReasoningIcon,
    text: " Logical Reasoning",
    width: "289",
    height: "295",
  },
  {
    img: DesignSkillsIcon,
    text: "Designing Skills",
    width: "289",
    height: "295",
  },
  {
    img: ProblemSolvingSkillsIcon,
    text: "Problem Solving Skills",
    width: "289",
    height: "295",
  },
  {
    img: PracticallyExperienceIcon,
    text: "Practically Experience",
    width: "289",
    height: "295",
  },
  {
    img: CollaborationIcon,
    text: "Collaboration",
    width: "289",
    height: "295",
  },
];

export const homepage__countup = [
  {
    img: StudentsRegisteredIcon,
    text: "Registered Students",
    countEnd: 92474,
    width: "229",
    height: "251",
  },
  {
    img: ClassesConductedIcon,
    text: "Classes Conducted",
    countEnd: 720342,
    width: "229",
    height: "251",
  },
];
