import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import "./content.css";
import gameprog1 from "./../../Assets/Blogs/gameprog1.jpg";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
import ScrollTop from "./ScrollTop";
import gameprog from "./../../Assets/Blogs/gameprog.png";
import codinghelpgameprog from "./../../Assets/Blogs/codinghelpgameprog.png";

export const GameProgramming = () => {
	const history = useHistory();
	return (
		<>
			<ScrollTop />
			<Helmet>
				<title>
					Game Programming For Kids | Teach Your Kids to Code | Techokids{" "}
				</title>
				<meta
					name="description"
					content=" Game programming refers to decoding a code to bring the characters of the game to life. The programming language used in creating games is C++."
				/>
				<link rel="canonical" href="/blog/Gameprogramming" />
				<meta
					name="keywords"
					content=" Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift"
				/>
			</Helmet>

			<div className="d-flex flex-column align-items-center justify-content-center">
				<div
					className="main-content d-flex text-white align-items-center"
					style={{ width: "100%" }}
				>
					<img
						onClick={() => history.push("/blog")}
						className="content-img"
						src={Logo}
						alt="logo"
						style={{ cursor: "pointer" }}
					></img>
					<div className="heading-content ">
						<h2>Game Programming</h2>
					</div>
				</div>
				<div className="author-icon w-50">
					<div className="author d-flex ">
						<img
							style={{ borderRadius: "50%" }}
							width="50"
							height="50"
							src={deepali}
						></img>

						<div className="d-flex flex-column inner-blogs-name">
							<div>
								<b>Deepali Meena</b>
							</div>
							<div>25 May 2021 | 5 min read</div>
						</div>
					</div>
					<div className="icons">
						<ExternalLink to="https://www.facebook.com/TechoKids/">
							<img
								className="icon facebook"
								src={FacebookIcon}
								alt="facebookIcon"
							/>
						</ExternalLink>

						{/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
						{/* <ExternalLink to='https://wa.me/9198918508'> */}
						<img
							className="icon whatsapp"
							src={WhatsappIcon}
							alt="instagramIcon"
						/>
						{/* </ExternalLink>  */}
						<ExternalLink to="https://twitter.com/TechoKids/">
							<img
								className="icon twitter"
								src={TwitterIcon}
								alt="twitterIcon"
							/>
						</ExternalLink>
					</div>
				</div>
				{/* <div className="text-center"> */}
				{/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
				{/* </img> */}
				<img
					alt="Game-Programming for kids"
					className="main-content-image"
					src={gameprog1}
					style={{ maxWidth: 850 }}
				></img>
				{/* </div> */}
				<div className="para-div ">
					<h2>
						<b> Introduction</b>{" "}
						{/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
					</h2>

					<p>
						Game programming refers to decoding a code to bring the characters
						of the game to life. Coding is the first step to learn game
						programming. For beginners, it works best when you know programming
						languages to run an application. As the technology has improved,
						gamers are rejoicing to have a better version of video games with
						designs and developments. Earlier, the games were in 2-D versions
						with a few techniques and no drama. Now that the number of
						programmers has increased, it is good for the game fans to enjoy the
						same on big screens and joysticks. A misconception is taking grips
						where it is said that a game programmer cannot design the game.
						Well, it is not at all true. They have a say in what designs they
						want and how characters should look. For those who want to improve
						their communication skills, game programming is one way to do that.
						Along with the same, you learn problem-solving skills and
						computational skills. There are multiple game programming sites
						where you can start from the basics, and Techokids is one. You can
						learn the subject right from the basics and create your own game.
						With expert teachers on your site, who can quickly solve your
						doubts, and in 1:1 interactive sessions, programming becomes more
						fun.
						{/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
					</p>
					{/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
					<br></br>
					<h3>
						<b>Why Is Game Programming Important? </b>
					</h3>
					{/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
					{/* <br>
        </br> */}
					<p>
						{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
						Being a game programmer is one of the most significant ways to show
						your skills in game designing. Game programming demands to possess
						an outstanding level of skills and patience. The developers have to
						keep the excitement of players' fun in mind for the game to work in
						the market. It also requires the developer to be of utmost
						creativity and innovate something different from others. Over the
						years, video games have made their way through the list of
						entertaining youth. New games and game applications are introduced
						every day, which increases gamers' expectations and something
						different and better. A game programmer's job lets you:
						<ul>
							<li>Customize engines for games</li>
							<li>Deal with the graphics of the game and modify them</li>
							<li>Work on coding and practice the same</li>
							<li>
								Make it enjoyable by adding sound effects, background score,
								etc.
							</li>
						</ul>
					</p>
					<br></br>
					<h3>
						<b>How Does Coding Help In-Game Programming</b>
					</h3>
					<br></br>
					{/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
					{/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
					{/* </img> */}
					<div className="text-center">
						<img
							alt="how does coding help in game programming "
							src={codinghelpgameprog}
							className="other-image"
						/>
					</div>
					<br></br>
					<p>
						Coding is an easy yet vital part of game programming. It is the only
						way to get the game characters to life. Once a computer can read
						your code, it is easy for you to present your game to the audience.
						Knowing programming languages is also a massive plus in creating a
						game. There are six programming languages that one is expected to
						know. It can turn out to be a long list, but the mains are C++, C#,
						Python and HTML. Python is the most accessible language to learn, is
						used by most game developers to create their masterpiece. Therefore
						we can say that coding is the basis of game programming as without
						any code, one cannot even initiate the project.
					</p>
					<br></br>
					<h3>
						<b>Steps To Learn Game Programming</b>
					</h3>
					<br></br>
					<p>
						<b>Step 1: Start with an idea.</b>
					</p>
					<p>
						The best way to grow as a game developer is by having an accurate
						idea of what game is created. To initiate with, one should divide
						the concept into various parts, starting with coding. Once you are
						familiar with how to write the codes, and if computers approve them,
						you can jump to the next step of building the scenario of your game.
					</p>
					<p>
						<b>Step 2: Choose the preferred engine.</b>
					</p>
					<p>
						The next step has to be choosing the engine that goes down in
						creating a game. The machines have their default functions which can
						be of great help in developing games. There are two different types
						of engines, one to be used by those who have some knowledge about
						coding and its terms. On the other hand, the other set of machines
						can be used by beginners. Those who already know coding can use:
						GUIs, UI, Unity and Unreal For beginners: Unity and Godot
					</p>

					<p>
						<b>Step 3: Initiate your project.</b>
					</p>
					<p>
						The final step is to start working on your project. As a beginner,
						try developing a small game first and then jump to massive projects.
						All you have to do is type the proper code, create a prototype,
						choose the correct engine and develop your game. This way, you can
						have a great learning experience. If you need a mentor to help you
						out, try enrolling in a coding class, like Techokids, where expert
						teachers can help you through the whole process of learning.{" "}
					</p>

					<br></br>

					<h3>
						<b>Functions</b>
					</h3>

					<p>
						Game programmers have a very subtle way of developing games. For
						those who know coding and the basics of programming, it is easier to
						cope with heavy projects. Programmers function through their skills
						and creativity. Some of the main functions of a programmer are:
						<ul>
							<li>
								They can work with massive projects and design the characters.
							</li>
							<li>They put their creativity to work and create wonders.</li>
							<li>
								One should know programming languages to initiate their
								projects, and this way, they can function while being motivated.{" "}
							</li>
						</ul>
					</p>

					<br></br>
					<h3>
						<b>Game Programming With Techokids</b>
					</h3>
					<br></br>
					<div className="text-center">
						<img
							alt="game programming with techokids"
							src={gameprog}
							className="other-image"
						/>
					</div>
					<br></br>

					<p>
						Games are fascinating. Nowadays, gamers are rejoicing in the new and
						3-D versions of the characters and more. Game programming,
						therefore, is in high demand among youngsters. Techokids are leading
						platforms where you can learn game programming from scratch and
						become one of them. It is an achievement to be cherished for a long
						time. Here, students can learn game programming right from the age
						of 6 years and through block-based coding, students can have fun and
						learn something new at the same time. A demo class can turn into a
						fun ride with the experts where they can create short stories or
						some fun cheat codes for games. The platform that one can use to
						apply game programming is MIT Scratch.
						{/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
					</p>
					<br></br>
					<h3>
						<b>Conclusion</b>
					</h3>

					<p>
						Creating even the most miniature game is the moment of pride. Even
						those who do not know coding can be brilliant game programmers and
						create a masterpiece. The programming language used in creating
						games is C++, and here at Techokids, you can take up a game
						programming demo class and at least build a match for your
						satisfaction.
					</p>
					{/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
				</div>
			</div>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<Footer />
		</>
	);
};
