import React from "react";
import "../../Styles/BookATrial/bookingCongratulations.css";
import Animation from "../../Assets/bookatrail/animation.gif";
import Emoji from "../../Assets/bookatrail/emoji.png";
import {Redirect} from "react-router-dom";
import {useState} from "react";
import {useEffect} from "react";

// functional component
export default function BookingCongratulations({havePC}) {
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsRedirect(true);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const havePcView = () => {
    return (
      <div className="booking__congratulations__container">
        <div className="booking__congratulations__wrapper">
          <div className="booking__congratulations__box">
            <img src={Animation} alt="animation" />
            <h1>Congratulations!</h1>
            <div className="booking__congratulations__footer-message">
              <div>Your first coding class is booked. Happy Learning</div>
              <div>
                <img src={Emoji} alt="emoji" />
              </div>
            </div>
          </div>
          <p className="wait_text load_text">
            You are being redirected to your dashboard, Be patient
          </p>
        </div>
      </div>
    );
  };
  
  const {email,token} = JSON.parse(localStorage.getItem("tk_jwt")) 


  return (
    <>
      {isRedirect ? (
        <span style={{display: "none"}}>
          {" "}
          {(window.location.href = `https://student.techokids.com/dashboard/?email=${email}&token=${token}`)}{" "}
        </span>
      ) : null}
      {havePcView()}
    </>
  );
}
