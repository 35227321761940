import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import ExternalLink from "../../Components/ExternalLink";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import ScrollTop from "./ScrollTop";
import "./content.css";

import bestmovies from "./../../Assets/Blogs/bestmovies.jpg";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
export const Bestmovies = () => {
  const history = useHistory();
  return (
    <>
   <ScrollTop/>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center">
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        <h1 className="heading-content ">
        Best Movies & Documentaries for Kids and Teens To become curious about coding


</h1>
        
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
        <img   alt = "Kunal Basantani" style = {{borderRadius:"50%"}} width = "55" height = "55" src = {KunalBasantaniImg}></img>      
            <div className="d-flex flex-column inner-blogs-name" >
            <div>
              <b>Kunal Basantani</b>
            </div>
            <div>10 October 2020 | 5 min read</div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img  className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img  className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img  className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>      </div>
      </div>
      <br></br>      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <img className="main-content-image" src={bestmovies}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
      <br></br>
        <p>
        
        

        One of the best ways to help children get curious about coding is by letting them watch movies related to such subjects. You might wonder what's the benefit of watching movies for coding. When your child watches something, instead of reading, they will quickly process all the factors. It will help them retain their lessons better and increase their memory power. When a child watches the lessons he learned to float in front of their eyes, he or she will be able to grasp the knowledge better. 
 {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        
        <p>
       
        For instance, if your kids learn a rhyme and then watch a video on that. Don't you think they will be able to enjoy their lessons better? It will help enhance their performance and bring better results too. Well, how about coding? How can coding movies help your child? It will ultimately help them gain interest in the subject when they see how a code can transform an object. Some movies show challenges that teenagers solve, and some movies are about little girls and boys with a good knowledge of science and coding who are ready to change the world. Let's focus on the best movies and documentaries for kids and teens to become curious about coding. </p>



        <h3>
       

The Top Movies And Documentaries For Kids And Teens To Become Curious About Coding
        </h3>
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        {/* <p> */}
            
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
    {/* A small amount of stress could be beneficial as it will push your child to do better. However, excessive stress might be harmful and would cause loss to their academics or regular life. It would affect the way they would talk, feel, or think. So, why let your child feel that way when you could help them out? The fundamental fact is that children would automatically learn how to deal with it with advancing time. However, to make sure it doesn’t affect your child in unwanted ways, parents are advised to help them out.  */}
{/* </p> */}
<p>
To amuse the tester in your child or teens, go through the provided list of movies and watch them asap! 

</p>
<ul>
<li>
       <p> <b>The Social Network</b></p>
       It is a top-notch movie about coding and programming. Even though the coding portion is less as the story is based on Mark Zuckerberg's true-life, founder, and Facebook CEO, you must know that the social media network was famous and still a lot relevant. 
</li>


<li>
       <p> <b>The Imitation Game</b></p>
       It is an extremely engaging movie that would keep anyone hooked till the end. Benedict Cumberbatch stars in the movie, and it is about the invention of the first computer. It would surely inspire and mold young minds and programmers to do better. 
</li>

<li>
       <p> <b>
The Fifth Estate
</b></p>
We all know about Wikileaks and its founder Julian Assange, who has a significant role in disclosing government and militaries' secret documents. It was a platform of whistleblowers. They gather evidence for all illegal and corrupt activities going on in these departments.</li>


<li>
       <p> <b>

  
The Matrix


</b></p>
Undoubtedly, the matrix has always been popular among every individual. The John Wick star, Keanu Reeves, starred in the movie. This was, in fact, the first movie that brought hackers into the limelight. The movie portrays the concept of loops, and one would find this movie very interesting. It is a mind-wobbling movie that is going to get anyone addicted to it. 
</li>


<li>
       <p> <b>
       Source Code

</b></p>
Don't you get that coding vibe when you hear the name "Source Code"? Indeed it does. The main plot revolved around a suicide bomb in a Chicago train. What's more interesting about this movie is that the protagonist enters another human body with the help of programming techniques and skills. 
</li>

<li>
       <p> <b>
       The Startup Kids


</b></p>
If you want your child to stir up that programming passion, they must watch this documentary. The story revolves around kids building up web startups. They make a significant impact in the social world by making sacrifices. It would inspire teens and kids to do better in their coding or academic field. Give it a try!</li>

<li>
       <p> <b>
       APEX: The Story of Hypercar


</b></p>
One of the best documentaries for any age looking up for the best technical content must watch this documentary. It will show you the in and out of real craftsmanship and motivate coders to turn their dreams into reality. It would help any coder understand that their code could become a masterpiece with a little hard work. </li>

<li>
       <p> <b>
       Unfriended: Dark Web


</b></p>
This is a movie meant for teens who are enthusiastic about searching deep into the web. The story revolves around a teenage boy who brought home a computer, which ultimately leads to suspenseful stories. The coders who want to get inspired about hacking must watch this movie without delay. The plot is exciting, and you will find yourself stuck to your seat till the end. However, keep in mind that the story is not a comedy, and individuals who do not like tragedies might want to avoid it.
</li>
</ul>


<h3>
Takeaways

</h3>
<p>
With all that being said, what movies do you suggest? Let us know in the comments below. With so many documentaries and movies available, your children will never get bored out of it. Grab on a movie every weekend and get inspired to become the future "Techpreneur." TechoKids is here to save their days and build them up for a better future. To keep up with the subject's updates and snippets, follow us on Twitter, Facebook, Linked In, and Instagram! 
</p>





<p>
    Book your first Free Online Trial Demo(https://techokids.com/book-trial/)
(whenever a user would tap on this link, this link should open in a new tab.) now!

We are looking forward to connecting with you on all the social media platforms we have mentioned links below.</p>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   <p>Facebook Page:
   <a href = "https://www.facebook.com/TechoKids">https://www.facebook.com/TechoKids</a> 
   </p>
   <p>Instagram:
   <a href = "https://instagram.com/techokids"> https://instagram.com/techokids
   </a>
   </p>
   <p>Linked In:
   <a href = "https://www.linkedin.com/company/techokids/">
   https://www.linkedin.com/company/techokids/
       </a> 
   </p>
   <p>Twitter:
   <a href = "https://twitter.com/TechoKids"> https://twitter.com/TechoKids
   </a>
   </p>
   <p>Also, do give a look and read all our must-read blogs.

Link of our first blog when published.
</p>
<a href = "https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/">https://connectgujarat.com/vadodara-startup-techokids-all-set-to-give-children-a-unique-learning-platform/
</a>
 
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
      
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
    <br></br>
  <br></br>
    <Footer/>
  </>
  );
};

