import React from "react";
import Logo from "./../../Assets/navbar/logo.png";
import WhatsappIcon from "./../../Assets/Blogs/WhatsappIcon.png";
import TwitterIcon from "./../../Assets/Blogs/TwitterIcon.png";
import FacebookIcon from "./../../Assets/Blogs/FacebookIcon.png";
import Image2 from "./../../Assets/Blogs/Image2.png";
import "./content.css";
import aifunc from "./../../Assets/Blogs/aifunc.png";
import aiimp from "./../../Assets/Blogs/aiimp.png";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MainContentImage from "./../../Assets/Blogs/MainContentImage.png";
import AnimationBlog from "./../../Assets/Blogs/AnimationBlog.gif";
import einstientk from "./../../Assets/Blogs/einstientk.jpg";
import creativity from "./../../Assets/Blogs/creativity.gif";
import ican from "./../../Assets/Blogs/ican.png";
import TechoKidsFinalLogo from "./../../Assets/Blogs/TechoKidsFinalLogo2.png";
import { useHistory } from "react-router-dom";
import ai1 from "./../../Assets/Blogs/ai1.jpg";
import Footer from "./../../Components/Footer";
import { Helmet } from "react-helmet";
import deepali from "./../../Assets/Blogs/deepali.jpeg";
import ExternalLink from "../../Components/ExternalLink";
export const ArtificialIntelligence = () => {
  const history = useHistory();
  return (
    <>
    <Helmet>
      <title> Importance of artificial intelligence | AI For Kids | Techokids</title>
      <meta 
      name ="description" 
      content = "Artificial intelligence is defined as intelligence exhibited by an artificial entity that uses binary languages. AI is becoming more significant day by day in the 20th century. "/>
  <link rel = "canonical" href = "/blog/Artificialintelligence"/>
  {/* <meta 
  name = "keywords" 
  content = " Coding for kids Programming Languages Every Kid Should Learn, Programming Languages, top 5 programming languages, JAVA, Python,JavaScript, Kotlin, Swift" />
   */}
  </Helmet>
    
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="main-content d-flex text-white align-items-center" style ={{width:"100%"}}>
        <img  onClick={() => history.push("/blog")} 
        className="content-img" 
        src={Logo} 
        alt="logo"
        style = {{cursor:"pointer"}}
        >

        </img>
        {/* <div className="heading-content "> */}
        <h1 className="heading-content "> Artificial Intelligence</h1>
        {/* </div> */}
      </div>
      <div className="author-icon w-50">
        <div className="author d-flex ">
          
        <img  alt = "Deepali Meena" style = {{borderRadius:"50%"}} width = "50" height = "50" src = {deepali}></img>      
            
            <div  className="d-flex flex-column inner-blogs-name">
             <div className = "author-name">
              <b>Deepali Meena</b>
            </div>
            <div classname = "date">
              20 May 2021 | 5 min read
              </div>
          </div>
        </div>
        <div className="icons">
        <ExternalLink to='https://www.facebook.com/TechoKids/'>
              <img className = "icon facebook" src={FacebookIcon} alt='facebookIcon' />
            </ExternalLink>
            
             {/* <ReactWhatsapp  style = {{}} number = "9198918508" message = "Hello">
             <img className = "icon whatsapp" src={WhatsappIcon} alt='WhatsappIcon' />
               </ReactWhatsapp> */}
              {/* <ExternalLink to='https://wa.me/9198918508'> */}
              {/* <img className = "icon whatsapp" src={WhatsappIcon} alt='instagramIcon' /> */}
            {/* </ExternalLink>  */}
            <ExternalLink to='https://wa.me/918896518896'> 
              <img className = "icon whatsapp" src={WhatsappIcon} alt='whatsappIcon' />
             </ExternalLink>
            <ExternalLink to='https://twitter.com/TechoKids/'>
              <img className = "icon twitter" src={TwitterIcon} alt='twitterIcon' />
            </ExternalLink>    </div>
      </div>
      {/* <div className="text-center"> */}
      {/* <img width="601" height="351" src="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png" class="attachment-full size-full wp-post-image" alt="5 Reasons Why Coding Is Necessary For Kids In 21st Century?" loading="lazy" itemprop="image" srcset="http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1.png 601w, http://blog.techokids.com/wp-content/uploads/2020/12/Why-Coding-is-important-for-kids-1-300x175.png 300w" sizes="(max-width: 601px) 100vw, 601px"> */}
        {/* </img> */}
        <br></br>
        <img style = {{maxwidth:850}} alt = "Artificial Intelligence for kids" className="main-content-image" src={ai1}></img>
      {/* </div> */}
      <div className="para-div ">
      <h2>
        <b>
      Introduction
      </b>
      {/* <b>5 Reasons Why Coding Is Necessary For Kids In 21st Century?</b> */}
      </h2>
      
     
    
        <p>
        One of the features that distinguish humans from other animals is the use of labor-saving devices that they use today. Only recently have we had the technology to begin to explore a general-purpose thinking machine. The computer is less than 100 years old and is exceptionally complex – but we are now beginning to see the fruits of our labor. When we ask our phone what the time is and how quickly it answers, that's artificial intelligence. When we play a video game, and a computer-controlled man sneaks around and stabs us in the back, that's artificial intelligence.  
 The search is not yet over. These are merely the first products of our research, but – as artificial intelligence becomes ever more pervasive – we have to understand what it is and what it is not to make sense of our world. 
Artificial intelligence (AI) is defined as intelligence exhibited by an artificial entity that uses binary languages. Such a system is generally assumed to be a computer that always does everything in terms of binary digits 0 and 1.



          {/* Coding is also known as computer programming. It is done by writing
          computer programs, and those who do it are known as programmers.
          Coding can help make things go digital, for example, on a website, but
          to learn coding, proper knowledge of coding, a coding language is
          essential, such as Java. */}
        </p>
        {/* <h2>
          <b>Why should Kids Learn Coding? Is K-12th Grade School Enough?</b>
        </h2> */}
        <br>
        </br>
        <h3>
        <b>Importance of AI</b>
      </h3>
      <br></br>
      <div className = "text-center">
        <img className= "other-image" style = {{maxwidth:700}}   src= {aiimp} alt = "importance of artificial intelligence" >
     </img>
     </div>
        
        {/* <h4>
        “Age doesn’t matter if you have the attitude and drive to succeed.”
         
        </h4>
        <h4 style = {{marginLeft:470}}>          – Sachin Bansal
          
        </h4> */}
        {/* <br>
        </br> */}
        <br></br>
        <p>
          {/* Kids and teens should learn to code as coding can open more roads for
          them. Today, many parents want their kids to learn coding. It is one
          of the best careers with the highest pays; also, demand for
          programmers is very high and will grow in the following years.
          Considering the data on the Bureau of Labor’s median salary, software
          developers earn $103,560 every year. This will incline by 24 percent
          every year till 2026. This fact makes it the fastest-growing
          occupation than any other resulting in the creation of so many coding
          apps like SoloLearn */}
        AI has a strong science fiction connotation, and it forms a vital branch of computer science, which deals with intelligent behavior, learning machines, and whatnot coding. Examples include control, planning, and scheduling, answering diagnostic and consumer questions, handwriting, speech, gaming, coding, and even facial recognition. It has become a scientific discipline focused on providing solutions to real-life problems that we face continuously. AI systems are now part of routine use in maths, economics, medicine, engineering, and the military and being built into many common home computer software applications, traditional strategy games like computer chess, and other video games like PS ones.
It is not easier to state what part of our modern society artificial intelligence hasn't touched to show how important it is to our daily lives. Still, it's not that hard to distinguish business operations and society, but sooner or later, it would reach that part also. Intelligence machines influence nearly every activity of our lives to help improve efficiencies and augment our human capabilities. AI is so intertwined in everything we do; it's hard to imagine living without it, as if living without our smartphones, computers, and laptops.

</p>
<br></br>
<h3>
        <b>Role of coding in AI</b>
      </h3>
        {/* <img src = "http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg"> */}
        {/* <img style = {{marginLeft:70}} lazy src="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg" alt="Coding Myth Vs Fact " class="wp-image-42" width="512" height="512" title="Coding Myth Vs Fact " srcset="http://blog.techokids.com/wp-content/uploads/2020/12/1-1024x1024.jpg 1024w, http://blog.techokids.com/wp-content/uploads/2020/12/1-300x300.jpg 300w, http://blog.techokids.com/wp-content/uploads/2020/12/1-150x150.jpg 150w, http://blog.techokids.com/wp-content/uploads/2020/12/1-768x768.jpg 768w, http://blog.techokids.com/wp-content/uploads/2020/12/1.jpg 1080w" sizes="(max-width: 512px) 100vw, 512px"> */}
        {/* </img> */}
        {/* <div className="text-center">
        <img style = {{marginRight:40, borderRadius:20} } width = "600" src={einstientk}></img>
      </div> */}
      
        <p>
        Like ours, most of the platforms like ours, Techokids, need to deal with various tough problems like errors, defects, and other complexities. So, here comes the role of artificial intelligence. There are a lot of artificial intelligence applications which help us to generate complex codes. Some of them include Bayou, Clever-Commit, Deepcode, Embold, and there are many more. Some of them are python integrated software, and some are programming software. The applications of Artificial intelligence are expanding every day.
 Self-coding apps will help Artificial intelligence to move further into many more fields and applications.
How can you get started with Artificial Intelligence?
Artificial Intelligence is becoming more significant day by day in the 20th century. So there are few things one should know. Firstly, you need to know why one should learn these programming languages. When one Starts analyzing things perfectly, there come the results of learning all these languages. What should establish Interest at an early age? It is very popular now among kids to learn Java. It will practice their logical skill and analytical thinking process. Language C++ is better for children because it is a basic language for programming. So go grab your thoughts and enroll your child into a good online course; this is what is needed.
you need to know why one should learn these programming languages. When one Starts analyzing things perfectly, there come the results of learning all these languages. What should establish Interest at an early age? It is very popular now among kids to learn Java. It will practice their logical skill and analytical thinking process. Language C++ is better for children because it is a basic language for programming. So go grab your thoughts and enroll your child into a good online course; this is what is needed.
 All these languages are bound to AR and VR and can be used in multiple technologies where one can do wonders.
</p>         <br>
         </br> 
         <h3>
        <b>Functions of AI</b>
      </h3>
      <br></br>    
      <div className = "text-center">
      <img style = {{maxwidth:700}} className = "other-image"  alt = "Artificial Intelligence for kids"  src={aifunc}></img>
      </div>
      <br></br>
        <p>Artificial intelligence can be automatized in various activities like education viz, grading.
 In colleges, homework and exams can be monotonous. In lower grades, according to teachers, grading consumes a lot of time. Instead, they can interact with students or prepare for class. Thus, AI may not take human's place, but it's viable how beneficial it is. Teachers now can perform automate grading for nearly all kinds of multiple-choice and fill-in-the-blank testing effortlessly. Today, essay-grading software is still in its initial stage. However, it will facilitate in the upcoming years, allowing teachers to focus more on in-class activities and student interaction than grading.
~It changes the role of teachers and makes their job less challenging.
They will always be a role of a teacher in education, but it will change the aspect of their duties in intelligent computing systems. As discussed earlier, how AI can execute tasks like grading can help improve learning. Yet, who can adapt AI to many other facets of teaching as well? Which can develop AI systems to provide proficiency, serve as a place for students to ask their doubts and find information, or even potentially take the place of teachers for very basic course materials. However, AI will swap the role of a teacher to that of a facilitator.
</p>

<br></br>       
<h3>
        <b>Artificial Intelligence with Techokids</b>
      </h3> 
        
        
        <p>
        We here, as one of the leading online platforms, take their task very seriously. Techokids have everything that your child needs. It's an interactive way to help them learn these concepts instead of traditional lectures, which is more or less boring to children of this age group. It also facilitates creativity.
Interest should be established at an early age. It is very popular now among kids to learn Java. It will practice their logical skill and analytical thinking process. Language C++ is better for children because it is a basic language for programming.
  {/* them. Today, many parents want their kids to learn coding. It is one */}
          {/* of the best careers with the highest pays; also, demand for */}
          {/* programmers is very high and will grow in the following years. */}
          {/* Considering the data on the Bureau of Labor’s median salary, software */}
          {/* developers earn $103,560 every year. This will incline by 24 percent */}
          {/* every year till 2026. This fact makes it the fastest-growing */}
          {/* occupation than any other resulting in the creation of so many coding */}
          {/* apps like SoloLearn */} 
        </p>
        <br></br>
        <h3>
            <b>
            Conclusion
            </b>
        
        </h3>


<p>The basic understanding of AI and machine learning becomes more valuable daily in various business and professional areas. And yes, our platform Techokids provides everything that your kid needs. Today your child doesn't go to university to learn this complex and interesting technology. Even if you don't have any prior experience in engineering, you can learn artificial intelligence from home and start applying your knowledge in practice, creating simple machine learning solutions and making the first steps towards your new profession.</p>
      {/* <div className="text-center">
        <img className="animation" src={AnimationBlog} alt="animation"></img>
      </div> */}
   </div>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<br></br>

    <br></br>
    <Footer/>
  </>
  );
};

