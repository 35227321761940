import React, { useState } from "react";
import Logo from "./../../Assets/navbar/logo.png";
import ReactPaginate from "react-paginate";
import SearchIcon from "@material-ui/icons/Search";
import "./blog.scss";
import bloginfo from "./bloginfo";
//it should come from backend
import PrimaryBlogImg from "./../../Assets/Blogs/MainImage.png";
import { useHistory } from "react-router-dom";
import Footer from "./../../Components/Footer";
import Data from "./data.json";
import KunalBasantaniImg from "../../Assets/about_us/KunalBasantani.png";
import { Helmet } from "react-helmet";
// import { useHistory } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import FirstBlog from "./FirstBlog";
import SingleBlog from "./SingleBlog";

export const Blog = () => {
	const history = useHistory();
	const [Users, setUsers] = useState(bloginfo.slice(0, 50));
	const [pageNumber, setPageNumber] = useState(0);
	const [data, setData] = useState([]);
	const [categories, setCategories] = useState([]);
	const [search, setSearch] = useState("");
	const [searchClick, setSearchClick] = useState(false);
	// const [categoryData, setCategoryData] = useState('')


	const [firstBlog, setFirstBlog] = useState({});
	const [temp, setTemp] = useState([]);
	useEffect(() => {
		axios 
			.get("/blog/allBlog")
			.then((res) => {
				// let tempo = res.data;
				let body = res.data;
				// console.log(body, "beforeinit");

				// setTemp(res.data.push(body[body.length - 1]));
				setTemp(res.data);

				setFirstBlog(body[body.length - 1]);
				console.log(body, "init");
				// body.pop();
				setData(body.slice(0, body.length - 1));
			})
			.catch((err) => console.log(err));
		axios
			.get("/category/viewall")
			.then((res) => {
				// console.log(res.data);
				setCategories(res.data);
			})
			.catch((err) => console.log(err));
	}, []);
	const handleSearch = (e) => {
		if (e.key === "Enter" || e.type === "click") {
			setSearchClick(!searchClick);
			if (search === "") {
				let body = temp;
				setFirstBlog(body[body.length - 1]);
				body.pop();
				setData(body);

				// setData(temp);
			} else {
				axios
					.get(`/blog/search?word=${search}`)
					.then((res) => {
						// setMainData(res.data);
						let body = res.data;
						setFirstBlog(body[body.length - 1]);
						body.pop();
						setData(body);

						// setData(res.data);
					})
					.catch((err) => console.log(err));
			}
		}
	};
	// const handlebtnClicked = (name) => {
	// 	// console.log();
	// 	let data = temp;
	// 	console.log(data, "here");
	// 	let newData = temp.filter((item) => {
	// 		// console.log(name, item, "here");
	// 		console.log(item);
	// 		if (item.category !== undefined) {
	// 			if (item.category.name === name) {
	// 				console.log(item);
	// 				return item;
	// 			}
	// 		}
	// 		return null;
	// 	});

	// 	setFirstBlog(newData[newData.length - 1]);
	// 	// newData.pop();
	// 	setData(newData.slice(0, newData.length - 1));
	// };

	const usersPerPage = 9;
	const pageVisited = pageNumber * usersPerPage;
	const displayUsers = Users
		.slice(pageVisited, pageVisited + usersPerPage)
		.map((user) => {
			return <div
						className="col single-blog"
						style={{ cursor: "pointer" }}
						onClick={() => history.push(user.blogUrl)}
					>
						<img className="secondary-img" src={user.image} alt=""></img>
						<div className="secondary-heading h3 mt-3 mb-3">
							<b>{user.title}</b>
						</div>
						<div className="mb-5 h6 para">{user.body}</div>
						<div className="d-flex author-blog">
							<div>
								<img
									width="50"
									height="50"
									style={{ borderRadius: "50%", marginRight: 20 }}
									src={user.imageau}
									alt=""
								></img>
							</div>
							<div className="d-flex flex-column">
								<div>{user.name}</div>
								<div>
									{user.date} | {user.time} read
								</div>
							</div>
						</div>
					</div>
		})
	const pagecount = Math.ceil(Users.length / usersPerPage);
	const changepage = ({ selected }) => {
		setPageNumber(selected);
		window.scrollTo(0, 0);
	};

	const [btnsClass, setBtnsClass] = useState({
		blogsBtn: "topic-btn-clicked",
		roboticsBtn: "topic-btn",
		AIBtn: "topic-btn",
		pythonBtn: "topic-btn",
		scrachBtn: "topic-btn",
		appsBtn: "topic-btn",
	})
	// const [btnsClass, setBtnsClass] = useState("");
	// const [blogBtnClass, setBlogBtnClass] = useState("topic-btn");
	// const [roboticsBtnClass, setRoboticsBtnClass] = useState("topic-btn");
	// const [AIBtnClass, setAIBtnClass] = useState("topic-btn");
	// const [pythonBtnClass, setPythonBtnClass] = useState("topic-btn");
	// const [scrachBtnClass, setScrachBtnClass] = useState("topic-btn");
	// const [appsBtnClass, setAppsBtnClass] = useState("topic-btn");
	const handlebtnClicked = (btnName) => {
		setBtnsClass({
			blogsBtn: "topic-btn",
			roboticsBtn: "topic-btn",
			AIBtn: "topic-btn",
			pythonBtn: "topic-btn",
			scrachBtn: "topic-btn",
			appsBtn: "topic-btn",
			[`${btnName}Btn`]: "topic-btn-clicked",
		});
	};
	const dummyArray = [
		{
			imgLink: "",
			header: "",
			description: "",
			authorImg: "",
			authorName: "",
			authorDate: "",
		}
	]
	return (<div>
		<Helmet>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{ "@context":"http://schema.org/",
								
								"@type": "EducationalOrganization",
								"name": "TechoKids",
								"alternateName": "TechoKids",
								"url": "https://www.techokids.com/",
								"image": "https://www.techokids.com/static/media/logo.2f37d971.png",
								"sameAs": {
								"https://www.facebook.com/TechoKids/",
								"https://twitter.com/TechoKids/",
								"https://www.instagram.com/techokids/",
								"https://www.linkedin.com/company/techokids/",
								"https://www.techokids.com/",
								"https://www.youtube.com/channel/UCIQ3U-ea7I3oOA6a8dnvIcQ",
								"https://in.pinterest.com/techokids"
								} }`,
				}}
			/>

			<title>
				{" "}
				Best Live Online Coding Courses For Kids |Blog |techokids
			</title>
			<meta
				name="description"
				content="TechoKids Blogs are related to STEM Education including best live online coding courses for kids regarding study hacks, parenting tips, & coding for kids. "
			/>
			<link rel="canonical" href="https://www.techokids.com/blog" />
			<meta
				name="keywords"
				content=" coding for kids, python for kids, scratch coding for kids, scratch programming for kids, coding for kids online, Minecraft coding for kids, programing for kids, kids coding academy, best coding sites for kids, introduction to coding for kids, best online coding for kids"
			/>
		</Helmet>

		<div className="firstSection d-flex flex-column justify-content-center align-items-center">
			<div className="main-heading text-center d-flex flex-column justify-content-center align-items-center">
				<img
					style={{ cursor: "pointer" }}
					onClick={() => history.push("/")}
					className="blog_img m-2"
					src={Logo}
					alt="logo"
					width="1080"
					height="300"
				/>
				<div className="textBlog text-white">
					Global K12 platform for 21st Century Skills
				</div>
			</div>
			<div class="input-group mb-3 d-flex search-field align-items-center">
				<div class="search-icon input-group-prepend">
					<span class="input-group-text" id="basic-addon1">
						<SearchIcon style={{ fontSize: "280%" }} />
					</span>
				</div>
				<input
					type="text"
					class="form-control search-input"
					placeholder="Discover news, article, announcement and more"
					aria-label="Search..."
					aria-describedby="basic-addon1"
					onChange={(e) => setSearch(e.target.value)}
					onKeyPress={handleSearch}
				/>
			</div>
		</div>
		<div className="second-section d-flex flex-column align-items-center justify-content-center">
		<div className="row topic-btn-div mt-3 d-flex justify-content-around">
          <button
            className={`${btnsClass.blogsBtn}`}
            onClick={() => handlebtnClicked("blogs")}
          >
            <b>Blogs</b>
          </button>
          <button
            className={`${btnsClass.roboticsBtn}`}
            onClick={() => handlebtnClicked("robotics")}
          >
            <b>Robotics</b>
          </button>
          <button
            className={`${btnsClass.AIBtn}`}
            onClick={() => handlebtnClicked("AI")}
          >
            <b>AI ML</b>
          </button>
          <button
            className={`${btnsClass.pythonBtn}`}
            onClick={() => handlebtnClicked("python")}
          >
            <b>Python</b>
          </button>
          <button
            className={`${btnsClass.scrachBtn}`}
            onClick={() => handlebtnClicked("scrach")}
          >
            <b>Scrach</b>
          </button>
          <button
            className={`${btnsClass.appsBtn}`}
            onClick={() => handlebtnClicked("apps")}
          >
            <b>Apps</b>
          </button>
        </div>
		
</div>
			<div
				className="primary-blog d-flex justify-content-between"
				style={{ cursor: "pointer" }}
				onClick={() =>{
					history.push("/blog/5-reasons-why-coding-is-important-for-kids")
				}
				}
				style={{ cursor: "pointer" }}
			>
				<div className="primary-blog-img-div">
					<img
						alt="5 reasons why coding is necessary"
						className="primary-img w-100"
						src={PrimaryBlogImg}
						style={{ marginTop: 30 }}
					/>
				</div>
				<div className="primary-blog-content-div">
					<div
						className=" primary-blog-heading mb-3"
						style={{ marginTop: 25 }}
					>
						{Data.map((post) => {
							return <super>{post.title}</super>;
						})}
					</div>
					<br></br>
					<div className="mb-4 h5 main-para">
						Being in the 21st century and amidst an unexpected lockdown,
						parents are finding it difficult to engage their kids in fun
						activities. If you are a parent..
					</div>
					<div className="d-flex author-blog">
						<div>
							<img
								alt="kunal basantani"
								width="50"
								height="50"
								src={KunalBasantaniImg}
							></img>
						</div>
						<div className="d-flex flex-column" style={{ paddingLeft: 10 }}>
							<div>Kunal Basantani</div>
							<div>10 october 2020 | 5 min read</div>
						</div>
					</div>
				</div>
			</div> 

			<div className="all-blogs w-95" style={{ display : "flex", flexWrap : "wrap",margin : "0rem 3rem 0rem 3rem"}}>{displayUsers}</div>
			<ReactPaginate
				previousLabel={"Previous"}
				nextLabel={"next"}
				pageCount={pagecount}
				onPageChange={changepage}
				containerClassName={"paginationbuttons"}
				previousLinkClassName={"previousbuttons"}
				nextLinkClassName={"nextbuttons"}
				disabledClassName={"paginationdisabled"}
				activeClassName={"pagiationactive"}
			/>
		<Footer />
	</div>)
};
